<template>
<div>

<section class="section">
	<div class="container">
		<nav class="level">
			<!-- Left side -->
			<div class="level-left">
				<div class="level-item">
					<h1 class="title">
						Billing for {{ this.targetMonthKey }}
						<p class="subtitle" v-show="!chargeData && isLoading">
							--
						</p>
						<p class="subtitle" v-show="!chargeData && !isLoading">
							Automatic billing not yet scheduled.
						</p>
						<p class="subtitle" v-if="chargeData" v-show="!chargeData.charged && autoChargeInFuture(chargeData.chargeDate)">
							<span class="tag is-info">Pending</span>
							Automatic billing set for {{ monthDateYearformat(chargeData.chargeDate) }}
						</p>
						<p class="subtitle" v-if="chargeData" v-show="!chargeData.charged && !autoChargeInFuture(chargeData.chargeDate)">
							<span class="tag is-danger">Note</span>
							Should have billed on {{ monthDateYearformat(chargeData.chargeDate) }} but did not.
						</p>
						<p class="subtitle" v-if="chargeData" v-show="chargeData.charged">
							<span class="tag is-success">Done</span>
							Billed on {{ monthDateYearformat(chargeData.chargeDate) }}
						</p>
					</h1>
				</div>
			</div>

			<!-- Right side -->
			<div class="level-right">
				<b-datepicker
					placeholder="Select a date..."
					icon="calendar-alt"
					v-model="targetMonth"
					:date-formatter="monthYearFormat"
					position="is-bottom-left"
					editable>
				</b-datepicker>
				
				<button class="button is-dark is-outlined" style="margin-left: 10px"
						:disabled="noDataAvailable || !mutable"
						@click="handleRecalculate">
					<b-tooltip label="Recalculate Tuition">
						Recalculate
					</b-tooltip>
				</button>

				<b-tooltip label="Reconnect Quickbooks">
					<button @click='handleQuickbooks' class="button is-outlined" style="margin-left: 10px">
						<img src="../../assets/quickbooks_icon.png" style="height: 25px">
					</button>
				</b-tooltip>
			</div>
		</nav>

		<div v-if="tuitions.length > 0">
			<article v-if="expectedInstructorCost + expectedRevenue > 0" class="message" :class="{'is-warning': expectedRevenue < expectedInstructorCost, 'is-info': expectedRevenue >= expectedInstructorCost}">
					<div class="message-body">
						<strong>{{ displayAsPrice(expectedRevenueLocal) }}</strong> in gross revenue expected for <strong>{{ displayAsPrice(expectedInstructorCost) }}</strong> in instructor wages<br>
						<u>{{ expectedGrossMargin }}</u> expected gross margin.
					</div>
			</article>

			<article class="message" :class="{'is-warning': tuitionsCount < activeDancersCount, 'is-info': tuitionsCount === activeDancersCount}">
					<div class="message-body">
						<strong>{{ tuitionsCount }} of {{ activeDancersCount }} dancers will be billed.</strong>
						<div v-if="activeDancersNotBilled.length > 0">
							{{ activeDancersNotBilledAsNames }} will not be billed.
						</div>
					</div>
			</article>

			<table class="table is-striped is-bordered is-narrow is-hoverable is-fullwidth" style="min-width: 1150px">
			<thead>
				<tr>
					<th v-if="mutable">
						<b-checkbox v-model="selectAll" @input="handleSelectAll" />
					</th>
					<th @click="handleSort(sortByFirstName)">First Name</th>
					<th @click="handleSort(obj => obj.dancer.lastName)">Last Name</th>
					<th @click="handleSort(obj => obj.dancer.email)">Email</th>
					<th @click="handleSort(obj => obj.tuition.total)">Tuition</th>
					<th>Note</th>
					<th v-if="mutable">Status</th>
				</tr>
			</thead>
			<tbody>
			<template v-for="row in tuitions">
				<tr @click="handleShowDetails(row)" :key="row.dancer.uid" 
						:class="markRow(row)">
					<td v-if="mutable" style="width: 30px">
						<div class="field">
							<b-checkbox v-model="selected[row.dancer.uid]"
								:disabled="row.tuition.charged && row.tuition.charged.success" />
						</div>
					</td>
					<td>
						{{ row.dancer.firstName }}	
					</td>
					<td>
						{{ row.dancer.lastName }}	
					</td>
					<td>
						{{ row.dancer.email }}	
					</td>
					<td>
						<div class="is-clearfix" v-if="!row.tuition.override">
							<div class="has-text-grey strike is-pulled-left" v-if="row.tuition.total !== row.tuition.normalTotal">
								{{ displayAsPrice(row.tuition.normalTotal) }}
							</div>
							<div class="is-pulled-right">
								{{ displayAsPrice(row.tuition.total) }}
							</div>
						</div>
						<div class="is-clearfix" v-else>
							<div class="has-text-grey strike is-pulled-left" v-if="row.tuition.override">
								{{ displayAsPrice(row.tuition.total) }}
							</div>
							<div class="is-pulled-right">
								{{ displayAsPrice(row.tuition.override.total) }}
							</div>
						</div>
					</td>
					<td class="has-text-centered">
						<div>
							{{ generateNote(row) }}
						</div>
					</td>
					<td v-if="mutable" class="has-text-centered">
						<div v-if="row.tuition.charged">
							<span v-show="row.tuition.charged.success" class="tag is-success">Charged</span>
							<span v-show="!row.tuition.charged.success" class="tag is-danger">Failed</span>
						</div>
						<div v-else>
							<b-tooltip label="Missing Stripe" type="is-danger" position="is-left">
								<b-icon v-if="row.tuition.missingStripeId"
									pack="fab"
									type="is-danger"
									icon="cc-stripe">
								</b-icon>
							</b-tooltip>
							<b-tooltip :label="'Last charged: ' + monthDateYearformat(row.tuition.lastCharge)" 
									type="is-warning"
									position="is-left">
								<b-icon v-if="!chargedRecently(row)"
									pack="far"
									type="is-warning"
									icon="credit-card">
								</b-icon>
							</b-tooltip>
							<b-icon v-if="chargedRecently(row) && !row.tuition.missingStripeId"
								pack="fas"
								type="is-success"
								icon="check-circle">
							</b-icon>
						</div>
					</td>
				</tr>
				<tr v-if="mutable" v-show="showDetails[row.dancer.uid]" :key="row.dancer.uid + '-detail'">
					<td colspan="7">
						<div class="box">
							<a @click="handleShowDetails(row)" class="delete is-pulled-right"></a>
							<div class="columns is-multiline">
								<div class="column is-full" v-if="row.tuition.charged">
									<div v-if="row.tuition.charged.success">
										<article class="message is-success">
											<div class="message-body">
												Successfully charged on {{ defaultFormatDate(row.tuition.charged.date) }}
											</div>
										</article>
									</div>
									<div v-if="!row.tuition.charged.success">
										<article class="message is-danger">
											<div class="message-body">
												{{ row.tuition.charged.error.message }}
											</div>
										</article>
									</div>
								</div>
								<div class="column is-full" v-if="row.tuition.override">
									<article class="message is-info">
										<div class="message-body">
											Dancer's tuition was manually overridden to {{ displayAsPrice(row.tuition.override.total ) }}<span v-if="row.tuition.override.total <= 0"> and will <strong>not be charged this month</strong></span>.<br>
											<a v-if="!row.tuition.charged || (row.tuition.charged && !row.tuition.charged.success)" @click="handleCancelTuitionOverride(row)">Click here to cancel</a>
										</div>
									</article>
								</div>
								<template v-if="!row.tuition.charged || (row.tuition.charged && !row.tuition.charged.success)">
									<div class="column is-half">
										<div class="columns">
											<div class="column is-1 has-text-centered">
												<b-icon v-if="!row.tuition.missingStripeId" v-show="!row.stripe.id"
													pack="fas"
													icon="spinner"
													custom-class="fa-spin">
												</b-icon>
											</div>
											<div class="column">
												<horizontal-input v-model="row.stripe.id"
													:placeholder="row.tuition.missingStripeId ? 'missing...':''"
													label="StripeID"
													type="text" />
											</div>
											<div class="column">
												<button @click="handleUpateStripe(row)" 
														class="button is-primary is-outlined"
														:disabled="row.stripe.id === row.stripe.originalId">
													Update
												</button>
											</div>
										</div>
									</div>
									<div class="column">
										<div class="field has-addons has-addons-centered">
											<div class="control has-icons-left">
												<input class="input" type="text" v-model="row.overrideTotal" placeholder="new tuition...">
												<span class="icon is-small is-left">
													<i class="fas fa-dollar-sign"></i>
												</span>
											</div>
											<div class="control">
												<button @click="handleChangeTuition(row)" :disabled="!row.overrideTotal" class="button is-info is-outlined">
													<span>Tuition</span>
													<span class="icon is-small">
														<i class="fas fa-pen"></i>
													</span>
												</button>
											</div>
										</div>
									</div>
									<div class="column">
										<div class="buttons">
											<button @click="handleEdit(row.dancer)" class="button">
												View Dancer
											</button>
											<button @click="handleCharge(row)" 
												class="button is-success is-outlined"
												:disabled="row.tuition.override && row.tuition.override.total <= 0">
												Charge
											</button>
										</div>
									</div>
								</template>
							</div>
						</div>
					</td>
				</tr>
			</template>
			</tbody>
			</table>

			<div class="is-clearfix">
				<button class="button is-primary is-medium is-pulled-right" style="margin-left: 10px;"
						v-if="mutable"
						:disabled="!hasSelectedDancers || (chargeData && chargeData.charged)"
						@click="handleBillNow">
					Bill Now
				</button>
			</div>

		</div>
		<section class="section" v-if="noDataAvailable">
			<div class="content has-text-grey has-text-centered">
				<p>Data for {{ this.targetMonthKey }} has not been generated.</p>
				<button @click="handleGenerate" class="button is-info is-outlined" :disabled="isLoading">
					Generate Now
				</button>
			</div>
		</section>
	</div>
	<b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
</section>
<edit-dancer-modal ref="editDancerModal" />
<confirm-charge-modal ref="confirmChargeModal" />
</div>
</template>

<script>

import loader from '@/dataLoader'

import moment from 'moment'
import toMoment from 'studio-shared/utils/toMoment'

import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import keys from 'lodash/keys'
import omitBy from 'lodash/omitBy'
import forEach from 'lodash/forEach'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'

import Sortable from '@/mixins/Sortable'
import FormatDate from '@/mixins/FormatDate'

import { defaultPriceFormat } from '@/utils/priceFormat'

import EditDancerModal from '../dancers/components/EditDancerModal.vue'
import HorizontalInput from '@/components/inputs/HorizontalInput.vue'

import ConfirmChargeModal from './ConfirmChargeModal.vue'

import quickbooksConfig from './quickbooksConfig'

import { 
	functions,
	firestore as db,
	collections
} from '@/firebase'

const dateKeyFormat = (date) => date.format('MMM YYYY')

const defaultTargetMonth = () => {
	const now = moment()
	const billingDate = moment().startOf('month').add(6, 'days')

	if (now.isAfter(billingDate))
	{
		return moment().startOf('month').add(1, 'month').toDate()
	}

	return now.toDate()
}

export default {
	mixins: [
		Sortable,
		FormatDate
	],
	data: function () {
		return {
			targetMonth: defaultTargetMonth(),
			noDataAvailable: false,
			isLoading: true,

			chargeData: null,

			tuitions: [],
			tuitionsLooksup: {},
			activeDancersNotBilled: [],

			selected: {},
			selectAll: false,

			showDetails: {}
		}
	},
	mounted: function () {
		if (this.tuitionsForMonth.length > 0)
		{
			this.updateTuitions()
			return
		}

		if (this.$store.getters.dancers.length > 0)
		{
			loader.fetchAndListenForBilling(this.targetMonthKey).then(this.billingDataLoaded)
			return
		}

		loader.once('dancers-loaded').then(() => {
			loader.fetchAndListenForBilling(this.targetMonthKey).then(this.billingDataLoaded)
		})
	},
	watch: {
		tuitionsForMonth: function () {
			this.updateTuitions()
		},
		targetMonth: function () {
			if (this.tuitionsForMonth.length > 0)
			{
				this.updateTuitions()
				return
			}

			this.isLoading = true
			loader.fetchAndListenForBilling(this.targetMonthKey).then(this.billingDataLoaded)
		}
	},
	computed: {
		mutable: function () {
			if (!this.chargeData)
			{
				return true
			}

			return !this.chargeData.charged
		},
		targetMonthKey: function () {
			return dateKeyFormat(moment(this.targetMonth))
		},

		tuitionsForMonth: function () {
			const list = this.$store.getters['billing/tuitionForKey'](this.targetMonthKey)

			if (!list)
			{
				return []
			}

			return list
		},

		hasSelectedDancers: function () {
			return find(this.selected, s => s)
		},

		tuitionsCount: function () {
			const count = reduce(this.tuitions, (sum, item) => {
				var i = 0
				const tuition = item.tuition
				if ((tuition.total > 0 && !tuition.override) || (tuition.override && tuition.override.total > 0))
				{
					i = 1
				}

				return sum + i
			}, 0)

			return count
		},
		activeDancers: function () {
			return filter(this.$store.getters.dancers, obj => obj.status === 'Active')
		},
		activeDancersCount: function () {
			return this.activeDancers.length
		},

		activeDancersNotBilledAsNames: function () {
			const dancers = map(this.activeDancersNotBilled, item => item.firstName + ' ' + item.lastName)
			if (dancers.length === 1)
			{
				return dancers[0]
			}

			if (dancers.length === 2)
			{
				return dancers.join(' and ')
			}

			const last = dancers.pop()
			return dancers.join(', ') + ', and ' + last
		},

		expectedRevenueLocal: function () {
			// NOTE: this exists because the server-side calculation is only
			// run when a recalculate is done
			// also not sure if that is the problem so going to use this local calc
			// to see if it is consistently more accurate
			const total = reduce(this.tuitions, (sum, item) => {
				const tuition = item.tuition
				let amount = tuition.total
				if (tuition.override)
				{
					amount = tuition.override.total
				}

				if (!amount)
				{
					amount = 0
				}

				return sum + amount
			}, 0)

			return total
		},

		expectedRevenue: function () {
			if (!this.chargeData)
			{
				return 0
			}

			const rev = this.chargeData.totalRevenue
			if (!rev)
			{
				return 0
			}

			return rev
		},
		expectedInstructorCost: function () {
			if (!this.chargeData)
			{
				return 0
			}
			
			const cost = this.chargeData.totalInstructorCost
			if (!cost)
			{
				return 0
			}

			return cost
		},
		expectedGrossMargin: function () {
			if (this.expectedRevenue <= 0)
			{
				return '0%'
			}

			const per = (this.expectedRevenue - this.expectedInstructorCost) / this.expectedRevenue
			return `${(per * 100).toFixed(2)}%`
		}
	},
	methods: {
		handleGenerate: function (evt) {
			evt.preventDefault()

			this.handleRecalculate(evt)
		},
		handleRecalculate: function (evt) {
			evt.preventDefault()

			this.isLoading = true

			const calculateTuition = functions.httpsCallable('calculateTuition')
			const target = moment(this.targetMonth).format('YYYY-MM-DD')
			calculateTuition({
				targetDate: target
			})
			.then(() => {
				this.isLoading = false
			})
		},
		handleBillNow: function (evt) {
			evt.preventDefault()

			const uids = keys(omitBy(this.selected, s => !s))

			const message = map(uids, uid => {
				const item = this.tuitionsLooksup[uid]
				const dancer = item.dancer
				const amount = this.determineTuitionAmount(item.tuition)

				return dancer.firstName + ' ' + dancer.lastName + ' (' + this.displayAsPrice(amount) + ')'
			})
			.join(', ')

			const count = uids.length

			const plural = () => {
				return (count > 1 ? 's' : '')
			}

			const config = {
				title: 'Charge ' + count + ' dancer' + plural() + '?',
				message: 'This will charge ' + message + ' to card' + plural() + ' on file.',
				confirm: 'Charge All'
			}

			this.chargeWithModal(uids, config).then(() => {
				this.selected = {}
				this.selectAll = false
			})
		},
		handleCharge: function (item) {
			const dancer = item.dancer
			const amount = this.displayAsPrice(this.determineTuitionAmount(item.tuition))

			const config = {
				title: 'Charge ' + dancer.firstName + ' ' + dancer.lastName + ' ' + amount + '?',
				message: 'This will charge ' + amount + ' to the credit card on file.',
				confirm: 'Charge'
			}

			this.chargeWithModal([item.dancer.uid], config)
		},
		determineTuitionAmount: function (tuition) {
			return tuition.override ? tuition.override.total : tuition.total
		},
		chargeWithModal: function (uids, config) {
			return this.$refs.confirmChargeModal.show(config).then(() => {
				this.$refs.confirmChargeModal.close()
				
				this.isLoading = true

				// charge dancers
				const chargeTuitions = functions.httpsCallable('chargeTuitions')
				const target = moment(this.targetMonth).format('YYYY-MM-DD')

				chargeTuitions({
					targetDate: target,
					dancerUids: uids
				})
				.then(() => {
					this.isLoading = false
				})
			})
			.catch(e => {
				if (!e)
				{
					return
				}

				console.error(e)
			})
		},
		handleChangeTuition: function (item) {
			this.isLoading = true

			const overrideTotal = parseFloat(item.overrideTotal)

			const ref = db.collection(collections.Billing).doc(this.targetMonthKey).collection('dancers').doc(item.dancer.uid)
			
			db.runTransaction(transaction => {
				return transaction.get(ref).then(snap => {
					if (!snap.exists)
					{
						return Promise.resolve()
					}

					transaction.update(ref, {
						override: {
							total: overrideTotal
						}
					})
				})
			})
			.then(() => {
				this.isLoading = false
			})
			.catch(err => {
				console.error(err)
				this.isLoading = false
			})
		},
		handleCancelTuitionOverride: function (item) {
			this.isLoading = true

			const ref = db.collection(collections.Billing).doc(this.targetMonthKey).collection('dancers').doc(item.dancer.uid)
			
			db.runTransaction(transaction => {
				return transaction.get(ref).then(snap => {
					if (!snap.exists)
					{
						return Promise.resolve()
					}

					const d = snap.data()

					delete d.override

					transaction.set(ref, d)
				})
			})
			.then(() => {
				this.isLoading = false
			})
			.catch(err => {
				console.error(err)
				this.isLoading = false
			})
		},

		handleSelectAll: function () {
			forEach(this.tuitions, item => {
				if (item.tuition.charged && item.tuition.charged.success)
				{
					return
				}

				if (item.tuition.missingStripeId)
				{
					return
				}

				this.$set(this.selected, item.dancer.uid, this.selectAll)
			})
		},
		handleShowDetails: function (item) {
			const uid = item.dancer.uid

			this.$set(this.showDetails, uid, !this.showDetails[uid])

			db.collection(collections.StripeLookup).doc(uid).get().then(snap => {
				if (!snap.exists)
				{
					return
				}

				const d = snap.data()
				item.stripe = {
					originalId: d.stripeCustomerId,
					id: d.stripeCustomerId
				}
			})
		},
		handleEdit: function (data) {
			this.$refs.editDancerModal.show(data)
		},
		handleUpateStripe: function (data) {
			const newStripeId = data.stripe.id

			this.isLoading = true

			db.collection(collections.StripeLookup).doc(data.dancer.uid).update({
				stripeCustomerId: newStripeId
			})
			.then(() => {
				this.isLoading = false

				data.stripe.originalId = newStripeId
			})
		},

		autoChargeInFuture: function (chargeDate) {
			const now = moment()

			return now.isSameOrBefore(chargeDate, 'day')
		},

		updateTuitions: function () {
			this.tuitionsLooksup = {}
			this.tuitions = sortBy(map(this.tuitionsForMonth, tuition => {

				const d = {
					dancer: this.$store.getters.dancer(tuition.uid),
					tuition: tuition,
					stripe: {}
				}

				this.tuitionsLooksup[tuition.uid] = d

				return d

			}), this.sortByFirstName)

			this.determineActiveDancersNotBilled()

			this.retrieveChargeData().then(() => {
				this.isLoading = false
				this.noDataAvailable = this.tuitions.length === 0
			})
		},
		determineActiveDancersNotBilled: function () {
			const missing = []

			forEach (this.activeDancers, dancer => {
				const item = this.tuitionsLooksup[dancer.uid]
				if (!item)
				{
					missing.push(dancer)
					return
				}

				const tuition = item.tuition

				if ((tuition.total > 0 && !tuition.override) || (tuition.override && tuition.override.total > 0))
				{
					return
				}

				missing.push(dancer)
			})

			this.activeDancersNotBilled = sortBy(missing, o => o.firstName)
		},

		billingDataLoaded: function () {
			this.noDataAvailable = this.tuitionsForMonth.length === 0

			this.retrieveChargeData().then(() => this.isLoading = false)
		},
		retrieveChargeData: function () {
			return db.collection(collections.Billing).doc(this.targetMonthKey).get().then(snap => {
				if (!snap.exists)
				{
					return null
				}

				const d = snap.data()
				d.chargeDate = toMoment(d.chargeDate)

				return d
			})
			.then(data => {
				this.chargeData = data
			})
		},

		chargedRecently: function (data) {
			const tuition = data.tuition

			const lastCharge = tuition.lastCharge
			const duration = moment.duration(moment().diff(lastCharge))
			if (duration.asMonths() <= 1)
			{
				return true
			}

			return false			
		},
		generateNote: function (data) {
			const classes = keys(data.tuition.breakdown)
			var totalMissed = 0
			var totalScheduled = 0

			for (var i = 0; i < classes.length; ++i)
			{
				const k = classes[i]
				totalMissed += data.tuition.breakdown[k].missedDates.length
				totalScheduled += data.tuition.breakdown[k].scheduledDates.length
			}

			return totalScheduled + ' scheduled / ' + totalMissed + ' canceled'
		},

		markRow: function (row) {
			const failedCharge = row.tuition.charged && !row.tuition.charged.success
			const charged = row.tuition.charged && row.tuition.charged.success
			return {
				clickable : this.mutable, 
				'faded-info': row.tuition.override && !failedCharge && !charged,
				'faded-danger': failedCharge, 
				'faded-success': charged
			}
		},

		getSortableList: function () {
			return this.tuitions
		},
		setSortableList: function (list) {
			this.tuitions = list
		},

		displayAsPrice: defaultPriceFormat,
		sortByFirstName: obj => obj.dancer.firstName,

		handleQuickbooks: function () {
			window.open(quickbooksConfig.authURL, '_blank')
		}
	},
	components: {
		EditDancerModal,
		HorizontalInput,

		ConfirmChargeModal
	}
}
</script>

<style scoped>
td 
{
	vertical-align: middle;
}

.table th {
	vertical-align: middle;
}

tr.clickable:hover {
	cursor: pointer;
}

.strike {
	text-decoration: line-through;
}

.faded-danger {
	background-color: hsla(348, 100%, 61%, 0.308)
}

.faded-danger:hover {
	background-color: hsla(348, 100%, 61%, 0.4) !important
}

.faded-success {
	background-color: hsla(106, 90%, 62%, 0.288)
}

.faded-success:hover {
	background-color: hsla(106, 90%, 62%, 0.4) !important
}

.faded-info {
	background-color: hsla(199, 90%, 62%, 0.288)
}

.faded-info:hover {
	background-color: hsla(194, 90%, 62%, 0.4) !important
}
</style>