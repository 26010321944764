var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        { staticClass: "has-addons" },
        [
          _c("b-input", {
            attrs: {
              placeholder: "filter...",
              type: "search",
              "icon-pack": "fas",
              icon: "search"
            },
            on: { input: _vm.handleFilterChange },
            model: {
              value: _vm.filterPhrase,
              callback: function($$v) {
                _vm.filterPhrase = $$v
              },
              expression: "filterPhrase"
            }
          })
        ],
        1
      ),
      _c("br"),
      _vm.dancers.length > 0
        ? _c(
            "table",
            {
              staticClass:
                "table is-striped is-bordered is-narrow is-hoverable is-fullwidth"
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c(
                    "th",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleSort("firstName")
                        }
                      }
                    },
                    [_vm._v("First Name")]
                  ),
                  _c(
                    "th",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleSort("lastName")
                        }
                      }
                    },
                    [_vm._v("Last Name")]
                  ),
                  _c(
                    "th",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleSort("email")
                        }
                      }
                    },
                    [_vm._v("Email")]
                  ),
                  _c("th", [_vm._v("Status")]),
                  _c("th", [_vm._v("Date of First Class")]),
                  _vm.showClasses ? _c("th", [_vm._v("Classes")]) : _vm._e()
                ])
              ]),
              _c(
                "tbody",
                _vm._l(_vm.dancers, function(row) {
                  return _c("tr", { key: row.uid }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.$emit("schedule", row)
                            }
                          }
                        },
                        [_vm._m(0, true)]
                      )
                    ]),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.firstName) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.lastName) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.email) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.status) + " ")]
                    ),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.monthDateYearNumber(row.dateOfFirstClass)
                          ) +
                          " "
                      )
                    ]),
                    _vm.showClasses && _vm.refreshClasses
                      ? _c("td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "field is-grouped is-grouped-multiline"
                            },
                            _vm._l(_vm.classesForDancer(row), function(
                              item,
                              idx
                            ) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "control" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: "Click to view class"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tags has-addons",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleEditClass(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", { staticClass: "tag" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.studioNameFromUid(
                                                  item.studio
                                                )
                                              )
                                            )
                                          ]),
                                          _c("span", { staticClass: "tag" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatClassNoStudio(item)
                                              )
                                            )
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "tag is-info" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.teacherNameFromUid(
                                                    item.instructor
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          item.event
                                            ? _c(
                                                "span",
                                                { staticClass: "tag is-dark" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.monthDayFormat(
                                                        item.event.effectiveDate
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          item.event
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "tag",
                                                  class: _vm.iconTypeForAction(
                                                    item.event.action
                                                  )
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      pack: "fas",
                                                      icon: _vm.iconForAction(
                                                        item.event.action
                                                      ),
                                                      size: "is-small"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm.dancers.length === 0
        ? _c("section", { staticClass: "section" }, [
            _c(
              "div",
              { staticClass: "content has-text-grey has-text-centered" },
              [
                _c("b-loading", {
                  attrs: {
                    "is-full-page": false,
                    active: _vm.isLoading,
                    "can-cancel": false
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.isLoading = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("edit-class-modal", {
        ref: "editClassModal",
        on: { done: _vm.handleClassEdited }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "far fa-calendar fa-lg" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }