



















































































































import { Component, Vue } from 'vue-property-decorator';
import { auth } from '../firebase'

@Component
export default class Navigation extends Vue
{
	private isLoggedIn = false
	private expandMenu = {
		dancers: false
	}
	
	mounted()
	{
		auth.onAuthStateChanged(user => {
			if (!user)
			{
				// logged out
				this.isLoggedIn = false
				if (this.$route.path !== '/')
				{
					this.$router.push('/')
				}
				return Promise.resolve()
			}

			user.getIdTokenResult().then(result => {
				if (result.claims.admin)
				{
					this.isLoggedIn = true

					if (this.$router.currentRoute.path === '/')
					{
						this.$router.push('/dashboard')
					}

					return Promise.resolve()
				}

				auth.signOut()
			})
			
		}, err => {
			console.error(err)
		})
	}

	private handleLogout()
	{
		auth.signOut()
	}
}
