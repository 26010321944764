var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Update Teacher: " + _vm._s(_vm.teacher.name))
          ]),
          _c(
            "b-notification",
            {
              attrs: {
                active: _vm.notificationType !== "",
                type: _vm.notificationType,
                closable: false,
                "auto-close": true
              }
            },
            [_vm._v(" " + _vm._s(_vm.notificationMessage) + " ")]
          ),
          _c(
            "form",
            { on: { submit: _vm.handleUpdate } },
            [
              _c("horizontal-input", {
                attrs: {
                  label: "Name",
                  placeholder: "instructor name...",
                  "is-narrow": true
                },
                model: {
                  value: _vm.teacher.name,
                  callback: function($$v) {
                    _vm.$set(_vm.teacher, "name", $$v)
                  },
                  expression: "teacher.name"
                }
              }),
              _c("div", { staticClass: "field is-horizontal" }, [
                _c("div", { staticClass: "field-label" }),
                _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field is-narrow is-grouped is-grouped-right"
                    },
                    [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            class: _vm.state === "working" ? "is-loading" : "",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(" Update ")]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          ),
          _c("hr"),
          _c("h3", { staticClass: "is-size-4" }, [_vm._v(" Teacher Login ")]),
          _c("br"),
          _c("form", { on: { submit: _vm.handleCreateLogin } }, [
            _c("div", { staticClass: "field is-horizontal" }, [
              _vm._m(0),
              _c("div", { staticClass: "field-body" }, [
                _c(
                  "div",
                  {
                    staticClass: "field is-narrow is-grouped is-grouped-right"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "control has-icons-left" },
                      [
                        _c("ValidationProvider", {
                          attrs: { rules: "required|email" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.email,
                                        expression: "form.email"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      name: "email",
                                      type: "email",
                                      disabled: _vm.teacher.login
                                    },
                                    domProps: { value: _vm.form.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("span", [_vm._v(_vm._s(errors[0]))])
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._m(1)
                      ],
                      1
                    ),
                    _vm.teacher.login
                      ? _c("div", { staticClass: "control" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.$copyText(_vm.teacher.login.email)
                                }
                              }
                            },
                            [_vm._m(2)]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "field is-horizontal" }, [
              _vm._m(3),
              _c("div", { staticClass: "field-body" }, [
                _c(
                  "div",
                  {
                    staticClass: "field is-narrow is-grouped is-grouped-right"
                  },
                  [
                    !_vm.teacher.login
                      ? _c(
                          "div",
                          { staticClass: "control has-icons-left" },
                          [
                            _c("ValidationProvider", {
                              attrs: { rules: "required|min:6" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.password,
                                              expression: "form.password"
                                            }
                                          ],
                                          staticClass: "input",
                                          attrs: {
                                            name: "password",
                                            type: "password"
                                          },
                                          domProps: {
                                            value: _vm.form.password
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.form,
                                                "password",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                        _c(
                                          "span",
                                          { staticClass: "icon is-left" },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-lg fa-key"
                                            })
                                          ]
                                        ),
                                        errors.length > 0
                                          ? _c(
                                              "p",
                                              { staticClass: "help is-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1764825845
                              )
                            })
                          ],
                          1
                        )
                      : _c("div", { staticClass: "has-text-centered" }, [
                          _vm._v(" Manage from Firebase Console ")
                        ])
                  ]
                )
              ])
            ]),
            !_vm.teacher.login
              ? _c("div", { staticClass: "field is-horizontal" }, [
                  _c("div", { staticClass: "field-label" }),
                  _c("div", { staticClass: "field-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "field is-narrow is-grouped is-grouped-right"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-primary has-text-dark is-pulled-right",
                            class: {
                              "is-loading": _vm.state === "creating-login"
                            }
                          },
                          [_vm._v(" Create Login ")]
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Email")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-lg fa-envelope" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-copy" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Password")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }