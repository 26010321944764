var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        { staticClass: "section" },
        [
          _c("div", { staticClass: "container" }, [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("div", { staticClass: "level-item" }, [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(" Billing for " + _vm._s(this.targetMonthKey) + " "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.chargeData && _vm.isLoading,
                            expression: "!chargeData && isLoading"
                          }
                        ],
                        staticClass: "subtitle"
                      },
                      [_vm._v(" -- ")]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.chargeData && !_vm.isLoading,
                            expression: "!chargeData && !isLoading"
                          }
                        ],
                        staticClass: "subtitle"
                      },
                      [_vm._v(" Automatic billing not yet scheduled. ")]
                    ),
                    _vm.chargeData
                      ? _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.chargeData.charged &&
                                  _vm.autoChargeInFuture(
                                    _vm.chargeData.chargeDate
                                  ),
                                expression:
                                  "!chargeData.charged && autoChargeInFuture(chargeData.chargeDate)"
                              }
                            ],
                            staticClass: "subtitle"
                          },
                          [
                            _c("span", { staticClass: "tag is-info" }, [
                              _vm._v("Pending")
                            ]),
                            _vm._v(
                              " Automatic billing set for " +
                                _vm._s(
                                  _vm.monthDateYearformat(
                                    _vm.chargeData.chargeDate
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.chargeData
                      ? _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.chargeData.charged &&
                                  !_vm.autoChargeInFuture(
                                    _vm.chargeData.chargeDate
                                  ),
                                expression:
                                  "!chargeData.charged && !autoChargeInFuture(chargeData.chargeDate)"
                              }
                            ],
                            staticClass: "subtitle"
                          },
                          [
                            _c("span", { staticClass: "tag is-danger" }, [
                              _vm._v("Note")
                            ]),
                            _vm._v(
                              " Should have billed on " +
                                _vm._s(
                                  _vm.monthDateYearformat(
                                    _vm.chargeData.chargeDate
                                  )
                                ) +
                                " but did not. "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.chargeData
                      ? _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.chargeData.charged,
                                expression: "chargeData.charged"
                              }
                            ],
                            staticClass: "subtitle"
                          },
                          [
                            _c("span", { staticClass: "tag is-success" }, [
                              _vm._v("Done")
                            ]),
                            _vm._v(
                              " Billed on " +
                                _vm._s(
                                  _vm.monthDateYearformat(
                                    _vm.chargeData.chargeDate
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "level-right" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Select a date...",
                      icon: "calendar-alt",
                      "date-formatter": _vm.monthYearFormat,
                      position: "is-bottom-left",
                      editable: ""
                    },
                    model: {
                      value: _vm.targetMonth,
                      callback: function($$v) {
                        _vm.targetMonth = $$v
                      },
                      expression: "targetMonth"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "button is-dark is-outlined",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { disabled: _vm.noDataAvailable || !_vm.mutable },
                      on: { click: _vm.handleRecalculate }
                    },
                    [
                      _c(
                        "b-tooltip",
                        { attrs: { label: "Recalculate Tuition" } },
                        [_vm._v(" Recalculate ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    { attrs: { label: "Reconnect Quickbooks" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-outlined",
                          staticStyle: { "margin-left": "10px" },
                          on: { click: _vm.handleQuickbooks }
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "25px" },
                            attrs: {
                              src: require("../../assets/quickbooks_icon.png")
                            }
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm.tuitions.length > 0
              ? _c("div", [
                  _vm.expectedInstructorCost + _vm.expectedRevenue > 0
                    ? _c(
                        "article",
                        {
                          staticClass: "message",
                          class: {
                            "is-warning":
                              _vm.expectedRevenue < _vm.expectedInstructorCost,
                            "is-info":
                              _vm.expectedRevenue >= _vm.expectedInstructorCost
                          }
                        },
                        [
                          _c("div", { staticClass: "message-body" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.displayAsPrice(_vm.expectedRevenueLocal)
                                )
                              )
                            ]),
                            _vm._v(" in gross revenue expected for "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.displayAsPrice(_vm.expectedInstructorCost)
                                )
                              )
                            ]),
                            _vm._v(" in instructor wages"),
                            _c("br"),
                            _c("u", [_vm._v(_vm._s(_vm.expectedGrossMargin))]),
                            _vm._v(" expected gross margin. ")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "article",
                    {
                      staticClass: "message",
                      class: {
                        "is-warning":
                          _vm.tuitionsCount < _vm.activeDancersCount,
                        "is-info": _vm.tuitionsCount === _vm.activeDancersCount
                      }
                    },
                    [
                      _c("div", { staticClass: "message-body" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.tuitionsCount) +
                              " of " +
                              _vm._s(_vm.activeDancersCount) +
                              " dancers will be billed."
                          )
                        ]),
                        _vm.activeDancersNotBilled.length > 0
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.activeDancersNotBilledAsNames) +
                                  " will not be billed. "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "table",
                    {
                      staticClass:
                        "table is-striped is-bordered is-narrow is-hoverable is-fullwidth",
                      staticStyle: { "min-width": "1150px" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _vm.mutable
                            ? _c(
                                "th",
                                [
                                  _c("b-checkbox", {
                                    on: { input: _vm.handleSelectAll },
                                    model: {
                                      value: _vm.selectAll,
                                      callback: function($$v) {
                                        _vm.selectAll = $$v
                                      },
                                      expression: "selectAll"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.handleSort(_vm.sortByFirstName)
                                }
                              }
                            },
                            [_vm._v("First Name")]
                          ),
                          _c(
                            "th",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.handleSort(function(obj) {
                                    return obj.dancer.lastName
                                  })
                                }
                              }
                            },
                            [_vm._v("Last Name")]
                          ),
                          _c(
                            "th",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.handleSort(function(obj) {
                                    return obj.dancer.email
                                  })
                                }
                              }
                            },
                            [_vm._v("Email")]
                          ),
                          _c(
                            "th",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.handleSort(function(obj) {
                                    return obj.tuition.total
                                  })
                                }
                              }
                            },
                            [_vm._v("Tuition")]
                          ),
                          _c("th", [_vm._v("Note")]),
                          _vm.mutable ? _c("th", [_vm._v("Status")]) : _vm._e()
                        ])
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.tuitions, function(row) {
                            return [
                              _c(
                                "tr",
                                {
                                  key: row.dancer.uid,
                                  class: _vm.markRow(row),
                                  on: {
                                    click: function($event) {
                                      return _vm.handleShowDetails(row)
                                    }
                                  }
                                },
                                [
                                  _vm.mutable
                                    ? _c(
                                        "td",
                                        { staticStyle: { width: "30px" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "field" },
                                            [
                                              _c("b-checkbox", {
                                                attrs: {
                                                  disabled:
                                                    row.tuition.charged &&
                                                    row.tuition.charged.success
                                                },
                                                model: {
                                                  value:
                                                    _vm.selected[
                                                      row.dancer.uid
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selected,
                                                      row.dancer.uid,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selected[row.dancer.uid]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(row.dancer.firstName) + " "
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(row.dancer.lastName) + " "
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(row.dancer.email) + " ")
                                  ]),
                                  _c("td", [
                                    !row.tuition.override
                                      ? _c(
                                          "div",
                                          { staticClass: "is-clearfix" },
                                          [
                                            row.tuition.total !==
                                            row.tuition.normalTotal
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "has-text-grey strike is-pulled-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.displayAsPrice(
                                                            row.tuition
                                                              .normalTotal
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "is-pulled-right"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.displayAsPrice(
                                                        row.tuition.total
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "is-clearfix" },
                                          [
                                            row.tuition.override
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "has-text-grey strike is-pulled-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.displayAsPrice(
                                                            row.tuition.total
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "is-pulled-right"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.displayAsPrice(
                                                        row.tuition.override
                                                          .total
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "has-text-centered" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.generateNote(row)) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm.mutable
                                    ? _c(
                                        "td",
                                        { staticClass: "has-text-centered" },
                                        [
                                          row.tuition.charged
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          row.tuition.charged
                                                            .success,
                                                        expression:
                                                          "row.tuition.charged.success"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "tag is-success"
                                                  },
                                                  [_vm._v("Charged")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !row.tuition
                                                          .charged.success,
                                                        expression:
                                                          "!row.tuition.charged.success"
                                                      }
                                                    ],
                                                    staticClass: "tag is-danger"
                                                  },
                                                  [_vm._v("Failed")]
                                                )
                                              ])
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "b-tooltip",
                                                    {
                                                      attrs: {
                                                        label: "Missing Stripe",
                                                        type: "is-danger",
                                                        position: "is-left"
                                                      }
                                                    },
                                                    [
                                                      row.tuition
                                                        .missingStripeId
                                                        ? _c("b-icon", {
                                                            attrs: {
                                                              pack: "fab",
                                                              type: "is-danger",
                                                              icon: "cc-stripe"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-tooltip",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Last charged: " +
                                                          _vm.monthDateYearformat(
                                                            row.tuition
                                                              .lastCharge
                                                          ),
                                                        type: "is-warning",
                                                        position: "is-left"
                                                      }
                                                    },
                                                    [
                                                      !_vm.chargedRecently(row)
                                                        ? _c("b-icon", {
                                                            attrs: {
                                                              pack: "far",
                                                              type:
                                                                "is-warning",
                                                              icon:
                                                                "credit-card"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm.chargedRecently(row) &&
                                                  !row.tuition.missingStripeId
                                                    ? _c("b-icon", {
                                                        attrs: {
                                                          pack: "fas",
                                                          type: "is-success",
                                                          icon: "check-circle"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm.mutable
                                ? _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.showDetails[row.dancer.uid],
                                          expression:
                                            "showDetails[row.dancer.uid]"
                                        }
                                      ],
                                      key: row.dancer.uid + "-detail"
                                    },
                                    [
                                      _c("td", { attrs: { colspan: "7" } }, [
                                        _c("div", { staticClass: "box" }, [
                                          _c("a", {
                                            staticClass:
                                              "delete is-pulled-right",
                                            on: {
                                              click: function($event) {
                                                return _vm.handleShowDetails(
                                                  row
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "columns is-multiline"
                                            },
                                            [
                                              row.tuition.charged
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "column is-full"
                                                    },
                                                    [
                                                      row.tuition.charged
                                                        .success
                                                        ? _c("div", [
                                                            _c(
                                                              "article",
                                                              {
                                                                staticClass:
                                                                  "message is-success"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "message-body"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Successfully charged on " +
                                                                        _vm._s(
                                                                          _vm.defaultFormatDate(
                                                                            row
                                                                              .tuition
                                                                              .charged
                                                                              .date
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      !row.tuition.charged
                                                        .success
                                                        ? _c("div", [
                                                            _c(
                                                              "article",
                                                              {
                                                                staticClass:
                                                                  "message is-danger"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "message-body"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          row
                                                                            .tuition
                                                                            .charged
                                                                            .error
                                                                            .message
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e(),
                                              row.tuition.override
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "column is-full"
                                                    },
                                                    [
                                                      _c(
                                                        "article",
                                                        {
                                                          staticClass:
                                                            "message is-info"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "message-body"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Dancer's tuition was manually overridden to " +
                                                                  _vm._s(
                                                                    _vm.displayAsPrice(
                                                                      row
                                                                        .tuition
                                                                        .override
                                                                        .total
                                                                    )
                                                                  )
                                                              ),
                                                              row.tuition
                                                                .override
                                                                .total <= 0
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " and will "
                                                                    ),
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "not be charged this month"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v("."),
                                                              _c("br"),
                                                              !row.tuition
                                                                .charged ||
                                                              (row.tuition
                                                                .charged &&
                                                                !row.tuition
                                                                  .charged
                                                                  .success)
                                                                ? _c(
                                                                    "a",
                                                                    {
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleCancelTuitionOverride(
                                                                            row
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Click here to cancel"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !row.tuition.charged ||
                                              (row.tuition.charged &&
                                                !row.tuition.charged.success)
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "column is-half"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "columns"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "column is-1 has-text-centered"
                                                              },
                                                              [
                                                                !row.tuition
                                                                  .missingStripeId
                                                                  ? _c(
                                                                      "b-icon",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value: !row
                                                                              .stripe
                                                                              .id,
                                                                            expression:
                                                                              "!row.stripe.id"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          pack:
                                                                            "fas",
                                                                          icon:
                                                                            "spinner",
                                                                          "custom-class":
                                                                            "fa-spin"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "column"
                                                              },
                                                              [
                                                                _c(
                                                                  "horizontal-input",
                                                                  {
                                                                    attrs: {
                                                                      placeholder: row
                                                                        .tuition
                                                                        .missingStripeId
                                                                        ? "missing..."
                                                                        : "",
                                                                      label:
                                                                        "StripeID",
                                                                      type:
                                                                        "text"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        row
                                                                          .stripe
                                                                          .id,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          row.stripe,
                                                                          "id",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "row.stripe.id"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "column"
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "button is-primary is-outlined",
                                                                    attrs: {
                                                                      disabled:
                                                                        row
                                                                          .stripe
                                                                          .id ===
                                                                        row
                                                                          .stripe
                                                                          .originalId
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleUpateStripe(
                                                                          row
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Update "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "column" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "field has-addons has-addons-centered"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "control has-icons-left"
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        row.overrideTotal,
                                                                      expression:
                                                                        "row.overrideTotal"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "input",
                                                                  attrs: {
                                                                    type:
                                                                      "text",
                                                                    placeholder:
                                                                      "new tuition..."
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      row.overrideTotal
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        row,
                                                                        "overrideTotal",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    }
                                                                  }
                                                                }),
                                                                _vm._m(0, true)
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "control"
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "button is-info is-outlined",
                                                                    attrs: {
                                                                      disabled: !row.overrideTotal
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleChangeTuition(
                                                                          row
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Tuition"
                                                                      )
                                                                    ]),
                                                                    _vm._m(
                                                                      1,
                                                                      true
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "column" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "buttons"
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "button",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleEdit(
                                                                      row.dancer
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " View Dancer "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "button is-success is-outlined",
                                                                attrs: {
                                                                  disabled:
                                                                    row.tuition
                                                                      .override &&
                                                                    row.tuition
                                                                      .override
                                                                      .total <=
                                                                      0
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleCharge(
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Charge "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  ),
                  _c("div", { staticClass: "is-clearfix" }, [
                    _vm.mutable
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "button is-primary is-medium is-pulled-right",
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              disabled:
                                !_vm.hasSelectedDancers ||
                                (_vm.chargeData && _vm.chargeData.charged)
                            },
                            on: { click: _vm.handleBillNow }
                          },
                          [_vm._v(" Bill Now ")]
                        )
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm.noDataAvailable
              ? _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _c("p", [
                        _vm._v(
                          "Data for " +
                            _vm._s(this.targetMonthKey) +
                            " has not been generated."
                        )
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "button is-info is-outlined",
                          attrs: { disabled: _vm.isLoading },
                          on: { click: _vm.handleGenerate }
                        },
                        [_vm._v(" Generate Now ")]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        ],
        1
      ),
      _c("edit-dancer-modal", { ref: "editDancerModal" }),
      _c("confirm-charge-modal", { ref: "confirmChargeModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-pen" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }