import { Component, Vue } from 'vue-property-decorator'

import {
	studioNameFromUid,
	classTypeFromUid,
	classLevelFromUid,
	teacherNameFromUid,
	dancerNameFromUid
} from '@/utils/nameFromUid'

@Component
export default class NameFromUid  extends Vue
{
	protected studioNameFromUid(uid: string)
	{
		return studioNameFromUid(uid)
	}

	protected classTypeFromUid(uid: string, returnObject = false)
	{
		return classTypeFromUid(uid, returnObject)
	}

	protected classLevelFromUid(uid: string)
	{
		return classLevelFromUid(uid)
	}

	protected teacherNameFromUid(uid: string)
	{
		return teacherNameFromUid(uid)
	}

	protected dancerNameFromUid(uid: string)
	{
		return dancerNameFromUid(uid)
	}	
}
