var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _vm.freeClasses.length > 0
          ? _c(
              "button",
              {
                staticClass: "button is-dark is-outlined",
                on: { click: _vm.handleAdd }
              },
              [_vm._m(0), _c("span", [_vm._v(" Free Class ")])]
            )
          : _vm._e()
      ]),
      _c("br"),
      _vm.freeClasses.length > 0
        ? _c(
            "div",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.freeClasses, function(item) {
              return _c("div", { key: item.uid, staticClass: "column is-12" }, [
                _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    { staticClass: "columns", class: _vm.determineStyle(item) },
                    [
                      _c("div", { staticClass: "column is-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.monthDateYearformat(item.classDate)) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _vm._v(
                          " " + _vm._s(_vm.makeupFormat(item.classData)) + " "
                        )
                      ]),
                      _c("div", { staticClass: "column is-3" }, [
                        _c("p", { staticClass: "buttons is-pulled-right" }, [
                          !_vm.datePassed(item)
                            ? _c(
                                "button",
                                {
                                  staticClass: "button is-danger",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: "Delete",
                                        type: "is-danger"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon is-small" },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-times"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.datePassed(item)
                            ? _c(
                                "button",
                                {
                                  staticClass: "button is-warning",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleArchive(item)
                                    }
                                  }
                                },
                                [
                                  _vm._m(1, true),
                                  _c("span", [_vm._v(" Archive ")])
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.freeClasses.length === 0
        ? _c("section", { staticClass: "section" }, [
            _c(
              "div",
              { staticClass: "content has-text-grey has-text-centered" },
              [
                _c("p", [_vm._v(" There are no free classes. ")]),
                _c("br"),
                _c(
                  "button",
                  { staticClass: "button", on: { click: _vm.handleAdd } },
                  [_vm._m(2), _c("span", [_vm._v(" Add One ")])]
                )
              ]
            )
          ])
        : _vm._e(),
      _c("b-loading", {
        attrs: {
          "is-full-page": false,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-archive" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }