var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "box", staticStyle: { "min-height": "600px" } },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(" Add Special Makeup Class "),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(
                " Schedule a special makeup and apply to classes automatically. "
              )
            ])
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.makeup.credits) +
                " of " +
                _vm._s(this.maxCredits) +
                " credits used "
            )
          ]),
          _c("br"),
          _c("form", { on: { submit: _vm.handleSubmit } }, [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Description")]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.makeup.description,
                      callback: function($$v) {
                        _vm.$set(_vm.makeup, "description", $$v)
                      },
                      expression: "makeup.description"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Date of Makeup Class")
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Select a date...",
                      icon: "calendar-alt",
                      "date-formatter": _vm.defaultFormatDate
                    },
                    model: {
                      value: _vm.makeup.makeupDate,
                      callback: function($$v) {
                        _vm.$set(_vm.makeup, "makeupDate", $$v)
                      },
                      expression: "makeup.makeupDate"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Credits")]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { placeholder: "Select credits" },
                      model: {
                        value: _vm.makeup.credits,
                        callback: function($$v) {
                          _vm.$set(_vm.makeup, "credits", $$v)
                        },
                        expression: "makeup.credits"
                      }
                    },
                    _vm._l(_vm.template.credits, function(option) {
                      return _c(
                        "option",
                        { key: option, domProps: { value: option } },
                        [_vm._v(" " + _vm._s(option) + " ")]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    class: { "is-loading": _vm.isLoading },
                    attrs: { disabled: !_vm.makeup.description }
                  },
                  [_vm._v(" Add ")]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }