var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { placeholder: "Select a period" },
                      model: {
                        value: _vm.period.selected,
                        callback: function($$v) {
                          _vm.$set(_vm.period, "selected", $$v)
                        },
                        expression: "period.selected"
                      }
                    },
                    _vm._l(_vm.period.options, function(option) {
                      return _c(
                        "option",
                        {
                          key: option.value,
                          domProps: { value: option.value }
                        },
                        [_vm._v(" " + _vm._s(option.name) + " ")]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Select a date...",
                      icon: "calendar-alt",
                      "date-formatter": _vm.monthDateYearShortFormat,
                      position: "is-bottom-left",
                      editable: ""
                    },
                    model: {
                      value: _vm.startingDate,
                      callback: function($$v) {
                        _vm.startingDate = $$v
                      },
                      expression: "startingDate"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "level-right" })
          ]),
          _c("hr"),
          _c(
            "div",
            { staticClass: "columns", staticStyle: { "overflow-x": "auto" } },
            [
              _vm._l(_vm.periodDates, function(date) {
                return [
                  _c(
                    "div",
                    {
                      key: date.toDate().toString(),
                      staticClass: "column",
                      class: _vm.columnSizes
                    },
                    [
                      _c("h1", { staticClass: "title is-5" }, [
                        _vm._v(_vm._s(date.format("dddd, MMM Do YYYY")))
                      ]),
                      _vm._l(
                        _vm.appointmentsForPeriodDates[
                          _vm.monthDateYearShortFormat(date)
                        ],
                        function(appt) {
                          return _c(
                            "div",
                            {
                              key: appt.classData.uid,
                              staticClass: "box clickable",
                              class: {
                                "selected-background": _vm.isSelectedAppointment(
                                  appt
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleSelectAppointment(appt)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.classTitle(appt.classData))
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  staticClass: "tag",
                                  class: _vm.colorForStudio(
                                    appt.classData.studio.name
                                  )
                                },
                                [
                                  _vm._v(
                                    " at " +
                                      _vm._s(appt.classData.studio.name) +
                                      " "
                                  )
                                ]
                              ),
                              _vm._v(" with "),
                              _c("u", [
                                _vm._v(_vm._s(appt.classData.instructor.name))
                              ]),
                              _c("br"),
                              _vm._v(
                                " Expecting " +
                                  _vm._s(appt.expectedDancers) +
                                  " " +
                                  _vm._s(
                                    _vm.pluralize(
                                      "dancer",
                                      appt.expectedDancers
                                    )
                                  ) +
                                  " "
                              ),
                              appt.expectedMakeups > 0
                                ? _c("span", [
                                    _c("i", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(appt.expectedMakeups) +
                                          " " +
                                          _vm._s(
                                            _vm.pluralize(
                                              "makeup",
                                              appt.expectedMakeups
                                            )
                                          ) +
                                          ")"
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          )
                        }
                      )
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ])
      ]),
      _c(
        "side-panel",
        {
          attrs: {
            isOpen: _vm.showAppointmentInfo && _vm.selectedAppointment !== null
          },
          on: {
            closeMenu: _vm.handleCloseAppointmentInfo,
            openMenu: _vm.handleOpenAppointmentInfo
          }
        },
        [
          _vm.selectedAppointment
            ? _c("div", { staticStyle: { padding: "10px", width: "400px" } }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-outlined",
                    on: {
                      click: function($event) {
                        _vm.showAppointmentInfo = false
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fas fa-chevron-left" })
                    ]),
                    _c("span", [_vm._v("Back")])
                  ]
                ),
                _c("br"),
                _c("br"),
                _c(
                  "div",
                  {
                    staticClass: "box clickable",
                    on: {
                      click: function($event) {
                        return _vm.handleEditClass(
                          _vm.selectedAppointment.classData
                        )
                      }
                    }
                  },
                  [
                    _c("h2", { staticClass: "title is-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.classTitle(_vm.selectedAppointment.classData)
                          ) +
                          " "
                      ),
                      _c("p", { staticClass: "subtitle" }, [
                        _vm._v(
                          " with " +
                            _vm._s(
                              _vm.selectedAppointment.classData.instructor.name
                            ) +
                            " on " +
                            _vm._s(
                              _vm.selectedAppointment.classData.dayOfWeek
                            ) +
                            "s "
                        )
                      ])
                    ]),
                    _vm._v(
                      " at " +
                        _vm._s(_vm.selectedAppointment.classData.studio.name) +
                        " "
                    )
                  ]
                ),
                _c("div", { staticClass: "box" }, [
                  _vm.selectedAppointment.dancersScheduled.length > 0
                    ? _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("strong", [
                          _vm._v(
                            "Attending (" +
                              _vm._s(_vm.selectedAppointment.expectedDancers) +
                              ")"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.selectedAppointment.dancersScheduled.length > 0
                    ? _c(
                        "table",
                        { staticClass: "table is-fullwidth is-hoverable" },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.dancersForSelectedAppointment, function(
                              dancer
                            ) {
                              return _c("tr", { key: dancer.uid }, [
                                _c(
                                  "td",
                                  {
                                    staticClass: "clickable",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEditDancer(dancer)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(dancer.firstName) +
                                        " " +
                                        _vm._s(dancer.lastName) +
                                        " "
                                    ),
                                    _vm.dancerIsMakeup(dancer)
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-dark" },
                                          [_vm._v("Makeup")]
                                        )
                                      : _vm.dancerIsFreeClass(dancer)
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-info" },
                                          [_vm._v("Free")]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { width: "20px" } },
                                  [
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          label: _vm.dancerIsFreeClass(dancer)
                                            ? "Remove"
                                            : "Mark Missed"
                                        }
                                      },
                                      [
                                        _c("a", {
                                          staticClass: "delete is-medium",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDancerMissSelectedAppointment(
                                                dancer
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "content has-text-grey has-text-centered"
                        },
                        [_vm._v(" No dancers for this class. ")]
                      )
                ]),
                _vm.selectedAppointment.dancersMissing.length > 0
                  ? _c("div", { staticClass: "box" }, [
                      _c("strong", [_vm._v("Missing")]),
                      _c(
                        "table",
                        { staticClass: "table is-fullwidth is-hoverable" },
                        [
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.missingDancersForSelectedAppointment,
                              function(dancer) {
                                return _c("tr", { key: dancer.uid }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditDancer(dancer)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(dancer.firstName) +
                                          " " +
                                          _vm._s(dancer.lastName) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              }
                            ),
                            0
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      ),
      _c("b-loading", {
        attrs: {
          "is-full-page": true,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      }),
      _c("edit-dancer-modal", {
        ref: "editDancerModal",
        staticStyle: { "z-index": "1000" }
      }),
      _c("edit-class-modal", {
        ref: "editClassModal",
        staticStyle: { "z-index": "1000" }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-item" }, [
      _c("h1", { staticClass: "title" }, [_vm._v(" Schedule ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }