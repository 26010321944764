var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "field is-horizontal" }, [
      _c("div", { staticClass: "field-label is-normal" }, [
        _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
      ]),
      _c(
        "div",
        { staticClass: "field-body" },
        [
          _c("b-field", [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column", class: _vm.colClasses }, [
                _c(
                  "table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.classes.length > 0,
                        expression: "classes.length > 0"
                      }
                    ],
                    staticClass:
                      "table is-striped is-bordered is-narrow is-hoverable is-fullwidth"
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        !_vm.readOnly ? _c("th") : _vm._e(),
                        _c("th", [_vm._v("Day")]),
                        _c("th", [_vm._v("Time")]),
                        _c("th", [_vm._v("Studio")]),
                        _c("th", [_vm._v("Type")]),
                        _c("th", [_vm._v("Level")]),
                        _c("th", [_vm._v("Instructor")]),
                        _c("th", [_vm._v("Status")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.selectedClasses, function(row) {
                        return _c("tr", { key: row.uid }, [
                          !_vm.readOnly
                            ? _c("td", [
                                _c("a", {
                                  staticClass: "delete is-medium",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleRemoveClass(row)
                                    }
                                  }
                                })
                              ])
                            : _vm._e(),
                          _c("td", [_vm._v(" " + _vm._s(row.dayOfWeek) + " ")]),
                          _c("td", [_vm._v(" " + _vm._s(row.startTime) + " ")]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.studioNameFromUid(row.studio)) +
                                " "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.classTypeFromUid(row.classType)) +
                                " "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.classLevelFromUid(row.classLevel)) +
                                " "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.teacherNameFromUid(row.instructor)) +
                                " "
                            )
                          ]),
                          _c("td", [_vm._v(" " + _vm._s(row.status) + " ")])
                        ])
                      }),
                      0
                    )
                  ]
                ),
                _vm.placeholder
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.classes.length === 0,
                            expression: "classes.length === 0"
                          }
                        ],
                        staticClass: "content has-text-grey"
                      },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.placeholder))]),
                        _c("b-loading", {
                          attrs: {
                            "is-full-page": false,
                            active: _vm.isLoading,
                            "can-cancel": false
                          },
                          on: {
                            "update:active": function($event) {
                              _vm.isLoading = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }