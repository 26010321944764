<template>
<b-modal :active.sync="active" @close="handleCancel">
	<div class="modal-card">
		<header class="modal-card-head">
			<h3 class="is-size-3">{{ config.title }}</h3>
		</header>
		<section class="modal-card-body">
			{{ config.message }}
		</section>
		<footer class="modal-card-foot">
			<div class="is-clear-fix" style="width: 100%">
				<div class="is-pulled-left">
					<button class="button is-normal is-primary" @click="handleConfirm">
						{{ config.confirm }}
					</button>
				</div>
				<div class="is-pulled-right">
					<button class="button is-normal" @click="handleCancel">
						{{ config.cancel }}
					</button>
				</div>
			</div>
		</footer>
	</div>
</b-modal>
</template>

<script>
import merge from 'lodash/merge'
import forEach from 'lodash/forEach'

var promises = []

export default {
	props: {
	},
	data: function () {
		return {
			active: false,

			config: {
				title: 'Modal Title',
				message: 'Modal Message',
				confirm: 'Okay',
				cancel: 'Cancel'
			}
		}
	},
	mounted: function () {
		
	},
	watch: {
	},
	methods: {
		show: function (config) {
			this.active = true

			merge(this.config, config)

			return new Promise((resolve, reject) => {
				promises.push({
					resolve: resolve,
					reject: reject
				})
			})
		},
		close: function () {
			this.active = false
		},

		handleConfirm: function () {
			this.active = false

			this.$emit('confirm')

			forEach(promises, p => {
				p.resolve()
			})

			promises = []
		},
		handleCancel: function () {
			this.active = false
			
			this.$emit('cancel')

			forEach(promises, p => {
				p.reject()
			})

			promises = []
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
