var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("h1", { staticClass: "title" }, [_vm._v("Archived Dancers ")]),
            _c("dancers-list", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allDancers.length > 0,
                  expression: "allDancers.length > 0"
                }
              ],
              attrs: { allDancers: _vm.allDancers, showClasses: false },
              on: { edit: _vm.handleEdit }
            }),
            !_vm.isLoading && _vm.allDancers.length === 0
              ? _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _c("p", [_vm._v(" There are no archived dancers. ")]),
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/dancers")
                            }
                          }
                        },
                        [_vm._v(" Back to Dancers ")]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("edit-dancer-modal", {
        ref: "editDancerModal",
        on: { restored: _vm.handleRestored }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }