var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      { on: { submit: _vm.handleSubmit } },
      [
        _c("horizontal-input", {
          attrs: {
            label: "Name",
            placeholder: "studio name...",
            "is-narrow": true
          },
          model: {
            value: _vm.studio.name,
            callback: function($$v) {
              _vm.$set(_vm.studio, "name", $$v)
            },
            expression: "studio.name"
          }
        }),
        _c("horizontal-input", {
          attrs: {
            label: "Street",
            placeholder: "eg: 1111 N Broadway",
            "is-narrow": true
          },
          model: {
            value: _vm.studio.address.street,
            callback: function($$v) {
              _vm.$set(_vm.studio.address, "street", $$v)
            },
            expression: "studio.address.street"
          }
        }),
        _c("horizontal-input", {
          attrs: { label: "Suite", placeholder: "eg: #130", "is-narrow": true },
          model: {
            value: _vm.studio.address.suite,
            callback: function($$v) {
              _vm.$set(_vm.studio.address, "suite", $$v)
            },
            expression: "studio.address.suite"
          }
        }),
        _c("horizontal-input", {
          attrs: {
            label: "City",
            placeholder: "eg: Denver",
            "is-narrow": true
          },
          model: {
            value: _vm.studio.address.city,
            callback: function($$v) {
              _vm.$set(_vm.studio.address, "city", $$v)
            },
            expression: "studio.address.city"
          }
        }),
        _c("horizontal-input", {
          attrs: {
            label: "State",
            placeholder: "eg: Colorado",
            "is-narrow": true
          },
          model: {
            value: _vm.studio.address.state,
            callback: function($$v) {
              _vm.$set(_vm.studio.address, "state", $$v)
            },
            expression: "studio.address.state"
          }
        }),
        _c("horizontal-input", {
          attrs: { label: "Zip", placeholder: "eg: 80203", "is-narrow": true },
          model: {
            value: _vm.studio.address.zip,
            callback: function($$v) {
              _vm.$set(_vm.studio.address, "zip", $$v)
            },
            expression: "studio.address.zip"
          }
        }),
        _c("horizontal-input", {
          attrs: {
            label: "Note",
            placeholder: "extra directions to help dancer find location",
            "is-narrow": false
          },
          model: {
            value: _vm.studio.address.note,
            callback: function($$v) {
              _vm.$set(_vm.studio.address, "note", $$v)
            },
            expression: "studio.address.note"
          }
        }),
        _c("horizontal-input", {
          attrs: {
            label: "Link",
            placeholder: "directions link via Google",
            "is-narrow": false
          },
          model: {
            value: _vm.studio.directionsLink,
            callback: function($$v) {
              _vm.$set(_vm.studio, "directionsLink", $$v)
            },
            expression: "studio.directionsLink"
          }
        }),
        _vm._l(_vm.studio.extraInfo, function(item, idx) {
          return [
            _c(
              "div",
              { key: "name_" + idx, staticClass: "field is-horizontal" },
              [
                _c("div", { staticClass: "field-label" }, [
                  idx === 0
                    ? _c("label", { staticClass: "label" }, [
                        _vm._v("Extra Info")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("div", { staticClass: "control is-expanded" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.name,
                            expression: "item.name"
                          }
                        ],
                        staticClass: "input",
                        attrs: { type: "text", placeholder: "eg: Parking" },
                        domProps: { value: item.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    idx > 0
                      ? _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-outlined",
                              on: {
                                click: function($event) {
                                  return _vm.handleMoveUp($event, idx)
                                }
                              }
                            },
                            [_vm._m(0, true)]
                          )
                        ])
                      : _vm._e(),
                    idx < _vm.studio.extraInfo.length - 1
                      ? _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-outlined",
                              on: {
                                click: function($event) {
                                  return _vm.handleMoveDown($event, idx)
                                }
                              }
                            },
                            [_vm._m(1, true)]
                          )
                        ])
                      : _vm._e(),
                    idx > 0
                      ? _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-danger is-outlined",
                              on: {
                                click: function($event) {
                                  return _vm.handleRemoveExtraInfo($event, idx)
                                }
                              }
                            },
                            [_vm._m(2, true)]
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { key: "message_" + idx, staticClass: "field is-horizontal" },
              [
                _c("div", { staticClass: "field-label" }),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.message,
                          expression: "item.message"
                        }
                      ],
                      staticClass: "textarea",
                      attrs: { placeholder: "extra information..." },
                      domProps: { value: item.message },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "message", $event.target.value)
                        }
                      }
                    })
                  ])
                ])
              ]
            )
          ]
        }),
        _c("div", { staticClass: "field is-horizontal" }, [
          _c("div", { staticClass: "field-label" }),
          _c("div", { staticClass: "field-body" }, [
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c(
                "button",
                {
                  staticClass: "button is-info",
                  on: { click: _vm.handleAddExtraInfo }
                },
                [_vm._m(3)]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "field is-horizontal" }, [
          _c("div", { staticClass: "field-label" }),
          _c("div", { staticClass: "field-body" }, [
            _c(
              "div",
              { staticClass: "field is-narrow is-grouped is-grouped-right" },
              [
                _c("div", { staticClass: "control" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      class: _vm.state === "working" ? "is-loading" : "",
                      attrs: { type: "submit" }
                    },
                    [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
                  )
                ])
              ]
            )
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-chevron-up" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-chevron-down" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-minus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }