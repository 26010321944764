var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "box", staticStyle: { "min-height": "600px" } },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v(" Schedule Makeup Class "),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(" Schedule a makeup and apply to classes automatically. ")
            ])
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.makeup.credits) +
                " of " +
                _vm._s(this.maxCredits) +
                " credits used "
            )
          ]),
          _c("br"),
          _c("form", { on: { submit: _vm.handleSubmit } }, [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Makeup Class")]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _vm.makeup.makeupClass
                    ? _c("div", { staticClass: "tags has-addons" }, [
                        _c("span", { staticClass: "tag is-medium" }, [
                          _vm._v(
                            _vm._s(_vm.formatClass(_vm.makeup.makeupClass))
                          )
                        ]),
                        _c("span", { staticClass: "tag is-medium is-info" }, [
                          _vm._v(
                            _vm._s(
                              _vm.teacherNameFromUid(
                                _vm.makeup.makeupClass.instructor
                              )
                            )
                          )
                        ]),
                        _c("a", {
                          staticClass: "tag is-medium is-delete",
                          on: { click: _vm.removeSelectedClass }
                        })
                      ])
                    : _vm._e(),
                  _vm.potentialClasses.length > 0 && !_vm.makeup.makeupClass
                    ? _c("class-autocomplete", {
                        attrs: {
                          useClasses: _vm.potentialClasses,
                          excludeFull: false,
                          forMakeup: true,
                          denoteNotRecommended: true
                        },
                        on: { classSelected: _vm.handleClassSelected }
                      })
                    : _vm._e(),
                  _vm.potentialClasses.length === 0 && !_vm.makeup.makeupClass
                    ? _c("div", { staticClass: "content has-text-grey" }, [
                        _vm._v(" No suitable makeups were found. ")
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Date of Makeup Class")
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("class-appointment-selector", {
                    ref: "makeupClassSelector",
                    attrs: {
                      forMakeups: true,
                      dancer: _vm.currentDancer,
                      selectableClasses: _vm.selectableClasses
                    },
                    on: {
                      selected: _vm.handleSelectedAppointment,
                      removed: _vm.removeSelectedAppointment,
                      loadMore: _vm.handleLoadMore
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    class: { "is-loading": _vm.isLoading },
                    attrs: { disabled: !_vm.readyToSchedule }
                  },
                  [_vm._v(" Schedule ")]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }