var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("h1", { staticClass: "title" }, [
              _vm._v("Dancers "),
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/dancers/add")
                    }
                  }
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "plus-circle", size: "is-large" }
                  })
                ],
                1
              )
            ]),
            _c("dancers-list", {
              attrs: { allDancers: _vm.allDancers },
              on: { edit: _vm.handleEdit, schedule: _vm.handleSchedule }
            })
          ],
          1
        )
      ]),
      _c("edit-dancer-modal", {
        ref: "editDancerModal",
        staticStyle: { "z-index": "1000" }
      }),
      _c(
        "side-panel",
        { attrs: { isOpen: _vm.selectedDancer !== null, width: 500 } },
        [
          _vm.selectedDancer
            ? _c("div", { staticStyle: { padding: "10px", width: "100%" } }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-outlined",
                    on: {
                      click: function($event) {
                        _vm.scheduleData.dancer = null
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "icon" }, [
                      _c("i", { staticClass: "fas fa-chevron-left" })
                    ]),
                    _c("span", [_vm._v("Back")])
                  ]
                ),
                _c("br"),
                _c("br"),
                _c("div", { staticClass: "box" }, [
                  _c("h2", { staticClass: "title is-5" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.selectedDancer.firstName) +
                        " " +
                        _vm._s(_vm.selectedDancer.lastName) +
                        " "
                    )
                  ]),
                  _vm._v(" " + _vm._s(_vm.selectedDancer.email) + " ")
                ]),
                _c("article", { staticClass: "message" }, [
                  _c("div", { staticClass: "message-header" }, [
                    _c("p", [_vm._v("Upcoming")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "message-body" },
                    [
                      _vm.upcomingAppointmentsForSelectedDancer.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered"
                            },
                            [_vm._v(" Dancer has no upcoming classes. ")]
                          )
                        : _vm._l(
                            _vm.upcomingAppointmentsForSelectedDancer,
                            function(appt) {
                              return _c(
                                "div",
                                { key: appt.id, staticClass: "box" },
                                [
                                  _c("div", { staticClass: "columns" }, [
                                    _c("div", { staticClass: "column" }, [
                                      _c(
                                        "p",
                                        { staticClass: "title is-size-5" },
                                        [
                                          _vm.isMissing(appt)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "tag is-warning"
                                                },
                                                [_vm._v("Missing")]
                                              )
                                            : _vm.isMakeup(appt)
                                            ? _c(
                                                "span",
                                                { staticClass: "tag is-dark " },
                                                [_vm._v("Makeup")]
                                              )
                                            : _vm.isFreeClass(appt)
                                            ? _c(
                                                "span",
                                                { staticClass: "tag is-info" },
                                                [_vm._v("Free")]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.titleForClass(
                                                  appt.classData
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "subtitle is-size-6" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.defaultFormatDate(appt.date)
                                              ) +
                                              " @ " +
                                              _vm._s(appt.classData.startTime)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " at " +
                                              _vm._s(
                                                appt.classData.studio.name
                                              ) +
                                              " with " +
                                              _vm._s(
                                                appt.classData.instructor.name
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "column is-1" }, [
                                      _c(
                                        "div",
                                        { staticClass: "buttons is-centered" },
                                        [
                                          _vm.isFreeClass(appt)
                                            ? _c("a", {
                                                staticClass: "delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleDeleteFreeClass(
                                                      appt
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.isMissing(appt) &&
                                          !_vm.isFreeClass(appt)
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button is-small is-danger is-outlined",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleMissed(
                                                        appt
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-tooltip",
                                                    {
                                                      attrs: {
                                                        label: "Mark Missed"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "icon" },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "far fa-calendar-minus"
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            }
                          )
                    ],
                    2
                  )
                ]),
                _c("article", { staticClass: "message" }, [
                  _c("div", { staticClass: "message-header" }, [
                    _c("p", [_vm._v("Past")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "message-body" },
                    [
                      _vm.pastAppointmentsForSelectedDancer.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered"
                            },
                            [_vm._v(" Dancer has no past classes. ")]
                          )
                        : _vm._l(
                            _vm.pastAppointmentsForSelectedDancer,
                            function(appt) {
                              return _c(
                                "div",
                                { key: appt.id, staticClass: "box" },
                                [
                                  _c("p", { staticClass: "title is-size-5" }, [
                                    _vm.isMissing(appt)
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-warning" },
                                          [_vm._v("Missed")]
                                        )
                                      : _vm.isMakeup(appt)
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-dark" },
                                          [_vm._v("Makeup")]
                                        )
                                      : _vm.isFreeClass(appt)
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-info" },
                                          [_vm._v("Free")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.titleForClass(appt.classData)
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "subtitle is-size-6" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.defaultFormatDate(appt.date)
                                          ) +
                                          " @ " +
                                          _vm._s(appt.classData.startTime) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    " at " +
                                      _vm._s(appt.classData.studio.name) +
                                      " with " +
                                      _vm._s(appt.classData.instructor.name) +
                                      " "
                                  )
                                ]
                              )
                            }
                          )
                    ],
                    2
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _c("b-loading", {
        attrs: {
          "is-full-page": true,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }