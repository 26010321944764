<template>
<b-modal :active.sync="active">
	<div class="box">
		<h1 class="title">Create New Class Level</h1>
		<form @submit="handleSubmit">
			<horizontal-input v-model="classLevel.name" 
				label="Name" 
				placeholder="eg: Intro / 1"
				:is-normal="true" />

			<horizontal-select v-model.number="classLevel.levelType" 
				label="Level Type" 
				:options="template.levelTypes" />

			<horizontal-select v-model.number="classLevel.value" 
				label="Value" 
				:options="template.values" />

			<div class="field is-horizontal">
				<div class="field-label">
					<!-- Left empty for spacing -->
				</div>
				<div class="field-body">
					<div class="field is-grouped is-grouped-right">
						<div class="control">
							<button type="submit" class="button is-primary" :class="state === 'working' ? 'is-loading' : ''">
								Add Class Level
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</b-modal>
</template>

<script>
import HorizontalInput from '../inputs/HorizontalInput.vue'
import HorizontalSelect from '../inputs/HorizontalSelect.vue'

import {
	firestore as db,
	collections
} from '../../firebase'

import range from 'lodash/range'
import nth from 'lodash/nth'

const template = {
	levelTypes: [
		'Ballet', 'Pointe', 'Open'
	],
	values: range(0, 21)
}

const defaultClassLevel = () => {
	return {
		name: '',
		levelType: nth(template.levelTypes, 0),
		value: nth(template.values, 1)
	}
}

export default {
	data: function () {
		return {
			template: template,
			classLevel: defaultClassLevel(),
			active: false,
			state: 'idle'
		}
	},
	methods: {
		handleSubmit: function (evt) {
			evt.preventDefault()

			if (!this.classLevel.name)
			{
				return;
			}

			this.state = 'working'

			db.collection(collections.ClassLevels).add(this.classLevel).then(ref => {
				this.classLevel.uid = ref.id
				this.$emit('done', this.classLevel)
				this.active = false

				this.state = 'idle'
				this.classLevel = defaultClassLevel()
			})
			.catch(err => {
				console.error(err)
				this.$emit('error', err)

				this.state = 'idle'
			})
		},
		show: function () {
			this.active = true
		}
	},
	components: {
		HorizontalInput,
		HorizontalSelect
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
