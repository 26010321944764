











import { Component, Vue } from 'vue-property-decorator'

import WideModal from '@/components/WideModal.vue'
import EditDancerComponent from './EditDancerComponent.vue'

import { DancerStatusTypes } from '@/utils'

@Component({
	components: {
		WideModal,
		EditDancerComponent
	}
})
export default class EditDancerModal extends Vue
{
	private active = false
	private dancer: IDancer | null = null

	get dancerIsArchived()
	{
		if (!this.dancer)
		{
			return false
		}
		return this.dancer.status === DancerStatusTypes.Archived
	}
	
	show(data: IDancer)
	{
		if (!data)
		{
			console.error('Must pass in a dancer to be updated.')
			return
		}

		this.dancer = data

		this.active = true
	}

	private handleClose()
	{
	}

	private handleRestored(data: IDancer)
	{
		this.active = false
		this.$emit('restored', data)
	}

	private setInitialClasses()
	{
		// @ts-ignore
		this.$refs.editDancerComponent.setDancer(this.dancer)

		// @ts-ignore
		this.$refs.editDancerComponent.setInitialClasses()
	}
}
