<template>
<div>
<section class="section">
<div class="container">
	<edit-dancer-component ref="editDancerComponent" @archived="handleArchived" />
	<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</section>
</div>
</template>

<script>
import EditDancerComponent from './components/EditDancerComponent.vue'

import loader from '@/dataLoader'

export default {
	data: function () {
		return {
			dancer: {
				uid: ''
			},
			isLoading: false
		}
	},
	mounted: function () {
		if (this.$store.getters.dancers.length === 0)
		{
			loader.once('dancers-loaded').then(this.getDancers)
			this.isLoading = true
			return
		}
		
		this.getDancers()
	},
	methods: {
		getDancers: function () {
			this.isLoading = false

			const data = this.$store.getters.dancer(this.$route.params.uid)

			if (!data)
			{
				console.error('no dancer with that uid')
				this.$router.push('/dancers')
				return
			}

			this.dancer = data

			this.$refs.editDancerComponent.setDancer(data)
			this.$refs.editDancerComponent.setInitialClasses()
		},

		handleArchived: function () {
			this.$router.push('/dancers')
		}
	},
	computed: {
	},
	components: {
		EditDancerComponent
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
