<template>
<div>
<div class="field is-horizontal">
	<div class="field-label is-normal">
		<label class="label">Events</label>
	</div>
	<div class="field-body">
		<b-field>
			<div class="columns">
				<div class="column">
					<section v-show="events.length > 0">
						<div class="columns is-multiline">
							<div class="column is-half" v-for="item in events" :key="item.uid">
								<div class="card">
									<div class="card-content">
										<div class="media">
											<div class="media-left">
												<b-icon
													:icon="iconForAction(item.action)"
													size="is-large"
													:type="iconTypeForAction(item.action)">
												</b-icon>
											</div>
											<div class="media-content">
												<p class="title is-4">{{ item.action }}</p>
												<p class="subtitle is-6">on {{ formatDate(item.effectiveDate) }}</p>
											</div>
											<div class="media-right">
												<a v-if="!eventBeingExecuted(item)" @click="handleDeleteEvent(item)" class="delete"></a>
												<b-icon v-if="eventBeingExecuted(item)"
													pack="fas"
													icon="sync"
													custom-class="fa-spin">
												</b-icon>
											</div>
										</div>

										<div class="content" v-if="isClassEvent(item.action) && item.class">
											<b>{{ classTypeFromUid(item.classData.classType) }} - Level {{ classLevelFromUid(item.classData.classLevel) }}</b><br>
											at {{ studioNameFromUid(item.classData.studio) }} with {{ teacherNameFromUid(item.classData.instructor) }}<br>
											{{ item.classData.dayOfWeek }}s at {{ item.classData.startTime }}
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</section>
					<div v-show="events.length === 0" class="content has-text-grey" >
						<p>This dancer has no events...</p>
					</div>
				</div>
			</div>
		</b-field>
	</div>
</div>

<div class="field is-horizontal">
	<div class="field-label">
		
	</div>
	<div class="field-body">
		<b-field>
			<div class="columns">
				<div class="column is-one-quarter">
					<b-field label="Action">
						<b-select v-model="event.action" placeholder="Select an action">
							<option
								v-for="option in template.actions"
								:value="option"
								:key="option">
								{{ option }}
							</option>
						</b-select>
					</b-field>
				</div>

				<div class="column">
					<section v-show="showClassEventParams">
						<div class="field">
							<label class="label">Class</label>
							<div class="control">
								<div v-if="selectedClass" class="tags has-addons">
									<span class="tag is-medium">{{ formatClass(selectedClass) }}</span>
									<span class="tag is-medium is-info">{{ teacherNameFromUid(selectedClass.instructor) }}</span>
									<a @click="removeSelectedClass" class="tag is-medium is-delete"></a>
								</div>
								<class-autocomplete :exclude="excludeClasses" 
									:useClasses="selectableClasses" 
									:excludeFull="!selectableClasses"
									@classSelected="handleClassSelected" />	
							</div>
						</div>
						<br>
						<b-field label="Effective Date">
							<b-datepicker
								placeholder="Select date..."
								icon="calendar-alt"
								position="is-top-right"
								v-model="event.effectiveDate">
							</b-datepicker>
						</b-field>
					</section>

					<section v-show="showMembershipStatusParams">
						<b-field label="Effective Date">
							<b-datepicker
								placeholder="Select date..."
								icon="calendar-alt"
								position="is-top-right"
								v-model="event.effectiveDate">
							</b-datepicker>
						</b-field>
					</section>

					<br>

					<button @click="handleAddEvent" 
						:disabled="!event.effectiveDate || !event.class"
						:class="addEventState === 'working' ? 'is-loading' : ''"
						class="button is-fullwidth is-primary">
						Add Event
					</button>
				</div>
			</div>
		</b-field>
	</div>
</div>
</div>
</template>

<script>
import ClassAutocomplete from '@/components/classes/ClassAutocomplete'

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import DancerEventsMixin from '@/mixins/DancerEventsMixin'

import head from 'lodash/head'
import clone from 'lodash/clone'
import map from 'lodash/map'

import moment from 'moment'

import {
	firestore as db,
	collections
} from '@/firebase'

import {
	eventActionTypes,
	eventActions
} from '@/utils'

import ClassesForDancer from '@/mixins/ClassesForDancer'

const template = {
	actions: eventActions()
}

const defaultEvent = () => {
	return {
		action: head(template.actions),
		effectiveDate: null,
		class: null
	}
}

export default {
	props: {
		dancerId: String
	},
	mixins: [
		NameFromUid,
		FormatClass,
		DancerEventsMixin,
		ClassesForDancer
	],
	data: function () {
		return {
			template: template,
			event: defaultEvent(),
			selectedClass: null,
			addEventState: 'idle',
			excludeClasses: [],
			selectableClasses: []
		}
	},
	mounted: function () {
		this.determineClasses()
	},
	watch: {
		events: function (newEvents) {
			this.$emit('eventsChanged', newEvents)

			this.determineClasses()
		},
		'event.action': function () {
			this.determineClasses()
		}
	},
	computed: {
		allClasses: function () {
			return this.$store.getters.classes
		},
		showClassEventParams: function () {
			const action = this.event.action
			return this.isClassEvent(action)
		},
		showMembershipStatusParams: function () {
			const action = this.event.action
			return this.isMembershipStatusEvent(action)
		},

		events: function () {
			const evts = this.$store.getters.eventsForDancer(this.dancerId)
			if (!evts)
			{
				return []
			}

			return map(evts, obj => {
				obj.classData = this.classFromUid(obj.class)
				return obj
			})
		}
	},
	methods: {
		handleAddEvent: function () {
			this.addEventState = 'working'

			const data = clone(this.event)

			db.collection(collections.DancerEvents).doc(this.dancerId).collection('events').add(data).then(() => {
				this.addEventState = 'idle'

				this.$emit('eventAdded', data)

				this.event = defaultEvent()
				this.selectedClass = null
			})
			.catch(err => {
				this.addEventState = 'idle'
				throw err
			})
		},
		handleDeleteEvent: function (item) {
			db.collection(collections.DancerEvents).doc(this.dancerId)
				.collection('events').doc(item.uid).delete().then(() => {
					// not sure why the onSnapshot isn't getting removed changed?
					// so this will do it manually
					this.$store.commit('removeEventForDancer', {
						dancerId: this.dancerId,
						event: item
					})

					this.$emit('eventDeleted', item)
				})
				.catch(err => {
					throw err
				})
		},
		eventBeingExecuted: function (item) {
			const now = moment()
			if (item.action === eventActionTypes.LeaveClass)
			{
				return moment(item.effectiveDate).isBefore(now, 'day')
			}

			return moment(item.effectiveDate).isSameOrBefore(now, 'day')
		},

		handleClassSelected: function (data) {
			if (!data)
			{
				return
			}

			this.event.class = data.uid
			this.selectedClass = data

			// set a default date of the next available class
			this.event.effectiveDate = moment().day(data.dayOfWeek).startOf('day').add(1, 'w').toDate()
		},

		removeSelectedClass: function () {
			this.selectedClass = null
			this.event.class = null
		},

		formatDate: function (date) {
			return moment(date).format('dddd, MMM Do YYYY')
		},

		isClassEvent: function (action) {
			const idx = this.template.actions.indexOf(action)
			return idx < 4
		},
		isMembershipStatusEvent: function (action) {
			const idx = this.template.actions.indexOf(action)
			return idx >= 4 && idx < 6
		},

		determineClasses: function () {
			if (this.event.action === eventActionTypes.JoinClass)
			{
				this.excludeClasses = this.classesForDancer(this.dancerId)
				this.selectableClasses = null
			}
			else
			{
				this.excludeClasses = []
				this.selectableClasses = this.$store.getters.classesForDancer(this.dancerId)
			}
		}
	},
	components: {
		ClassAutocomplete
	}
}
</script>

<style scoped>

</style>