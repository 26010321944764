<template>
<div>
	<form @submit="handleSubmit">
		<horizontal-input v-model="studio.name" 
			label="Name" 
			placeholder="studio name..."
			:is-narrow="true" />

		<horizontal-input v-model="studio.address.street" 
			label="Street" 
			placeholder="eg: 1111 N Broadway"
			:is-narrow="true" />

		<horizontal-input v-model="studio.address.suite" 
			label="Suite" 
			placeholder="eg: #130"
			:is-narrow="true" />

		<horizontal-input v-model="studio.address.city" 
			label="City" 
			placeholder="eg: Denver"
			:is-narrow="true" />

		<horizontal-input v-model="studio.address.state" 
			label="State" 
			placeholder="eg: Colorado"
			:is-narrow="true" />

		<horizontal-input v-model="studio.address.zip" 
			label="Zip" 
			placeholder="eg: 80203"
			:is-narrow="true" />

		<horizontal-input v-model="studio.address.note" 
			label="Note" 
			placeholder="extra directions to help dancer find location"
			:is-narrow="false" />

		<horizontal-input v-model="studio.directionsLink" 
			label="Link" 
			placeholder="directions link via Google"
			:is-narrow="false" />

		<template v-for="(item, idx) in studio.extraInfo">
		<div class="field is-horizontal" :key="'name_' + idx">
			<div class="field-label">
				<label v-if="idx === 0" class="label">Extra Info</label>
			</div>
			<div class="field-body">
				<div class="field has-addons">
					<div class="control is-expanded">
						<input v-model="item.name" class="input" type="text" placeholder="eg: Parking">
					</div>
					<div v-if="idx > 0" class="control">
						<button @click="handleMoveUp($event, idx)" class="button is-outlined">
							<span class="icon">
								<i class="fas fa-chevron-up"></i>
							</span>
						</button>
					</div>
					<div v-if="idx < studio.extraInfo.length - 1" class="control">
						<button @click="handleMoveDown($event, idx)" class="button is-outlined">
							<span class="icon">
								<i class="fas fa-chevron-down"></i>
							</span>
						</button>
					</div>

					<div v-if="idx > 0" class="control">
						<button @click="handleRemoveExtraInfo($event, idx)" class="button is-danger is-outlined">
							<span class="icon">
								<i class="fas fa-minus"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="field is-horizontal" :key="'message_' + idx">
			<div class="field-label">
				
			</div>
			<div class="field-body">
				<div class="field">
					<textarea v-model="item.message" class="textarea" placeholder="extra information..."></textarea>
				</div>
			</div>
		</div>
		
		</template>

		<div class="field is-horizontal">
			<div class="field-label">
				
			</div>
			<div class="field-body">
				<div class="field is-grouped is-grouped-right">
					<button @click="handleAddExtraInfo" class="button is-info">
						<span class="icon">
							<i class="fas fa-plus"></i>
						</span>
					</button>
				</div>
			</div>
		</div>

		<div class="field is-horizontal">
			<div class="field-label">
				<!-- Left empty for spacing -->
			</div>
			<div class="field-body">
				<div class="field is-narrow is-grouped is-grouped-right">
					<div class="control">
						<button type="submit" class="button is-primary" :class="state === 'working' ? 'is-loading' : ''">
							{{ actionText }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
</template>

<script>
import HorizontalInput from '@/components/inputs/HorizontalInput.vue'

import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'

const defaultExtraInfo = () => {
	return {
		position: 0,
		name: '',
		message: ''
	}
}

const studioTemplate = () => {
	return {
		name: '',
		address: {
			street: '',
			city: '',
			state: '',
			zip: ''
		},
		extraInfo: [
			defaultExtraInfo()
		]
	}
}

export default {
	props: {
		studioData: {
			type: Object,
			default: () => studioTemplate()
		},
		actionText: {
			type: String,
			default: 'Add'
		},
		state: {
			type: String,
			default: 'idle'
		},
		clearOnSuccess: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			studio: studioTemplate()
		}
	},
	mounted: function () {
		if (!this.studioData)
		{
			return
		}

		this.studio = cloneDeep(this.studioData)
	},
	watch: {
		studioData: function (newData) {
			this.studio = cloneDeep(newData)
		},
		state: function (newState) {
			if (this.clearOnSuccess && newState === 'success')
			{
				this.studio = studioTemplate()
			}
		}
	},
	methods: {
		handleSubmit: function (evt) {
			evt.preventDefault()

			this.$emit('submit', this.studio)
		},

		handleAddExtraInfo: function (evt) {
			evt.preventDefault()

			const ei = defaultExtraInfo()
			ei.position = this.studio.extraInfo.length
			this.studio.extraInfo.push(ei)
		},
		handleRemoveExtraInfo: function (evt, idx) {
			evt.preventDefault()

			this.$delete(this.studio.extraInfo, idx)
		},

		handleMoveUp: function (evt, idx) {
			evt.preventDefault()
			const newPosition = idx - 1
			
			this.studio.extraInfo[idx].position = newPosition
			this.studio.extraInfo[newPosition].position = idx

			this.studio.extraInfo = sortBy(this.studio.extraInfo, 'position')
		},
		handleMoveDown: function (evt, idx) {
			evt.preventDefault()
			const newPosition = idx + 1
			
			this.studio.extraInfo[idx].position = newPosition
			this.studio.extraInfo[newPosition].position = idx

			this.studio.extraInfo = sortBy(this.studio.extraInfo, 'position')
		}
	},
	computed: {
	},
	components: {
		HorizontalInput
	}
}
</script>

<style scoped>

</style>