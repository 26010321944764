





















































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'
import reduce from 'lodash/reduce'

import { filterDancers  } from '@/utils'

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import DancerEventsMixin from '@/mixins/DancerEventsMixin'
import FormatDate from '@/mixins/FormatDate'
import ClassesForDancer from '@/mixins/ClassesForDancer'
import Sortable from '@/mixins/Sortable'

import EditClassModal from '../../classes/components/EditClassModal.vue'

import throttle from '@/decorators/throttle'

import loader from '@/dataLoader'

const loadClassDependents = (uid: string) => {
	loader.fetchAndListenForMissedClasses(uid)
	loader.fetchAndListenForFreeClasses(uid)
	loader.fetchAndListenForExtraCredits(uid)
}

@Component({
	mixins: [
		NameFromUid,
		DancerEventsMixin,
		FormatDate,
		ClassesForDancer,
		Sortable
	],
	components: {
		EditClassModal
	}
})
export default class DancersList extends Mixins(FormatClass)
{
	@Prop() allDancers!: any[]
	@Prop({ default: true }) showClasses!: boolean

	private filterPhrase = ''
	private dancers: IDancer[] = []
	private isLoading = true
	private refreshClasses = true

	private sortState = {
		'firstName': 1
	}
	private lastSortKey = 'firstName'

	@Watch('allDancers')
	private onAllDancersChanged()
	{
		this.handleFilterChange()
	}

	@Watch('dancers')
	private onDancersChanged()
	{
		this.isLoading = false
	}

	mounted()
	{
		this.handleFilterChange()
	}
	
	private handleEdit(data: IDancer)
	{
		this.$emit('edit', data)
	}

	@throttle(500, { leading: false, trailing: true })
	private handleFilterChange()
	{
		var list = this.allDancers

		this.dancers = sortBy(
			filterDancers(list, this.filterPhrase), 
			[this.lastSortKey]
		)
	}

	private handleEditClass(data: any)
	{
		const d = clone(data)
		delete d.event

		// @ts-ignore
		this.$refs.editClassModal.show(d)
	}

	private handleClassEdited()
	{
		this.refreshClasses = false; 
		this.refreshClasses = true;
	}

	private getSortableList()
	{
		return this.dancers
	}

	private setSortableList(list: any[])
	{
		this.dancers = list
	}

	private getExtraCreditsCount(dancer: IDancer)
	{
		const list = this.$store.getters['extraCredits/forDancer'](dancer.uid)

		return reduce(list, (sum, obj) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	private getMissedClassesCredits(dancer: IDancer)
	{
		if (!dancer)
		{
			return -1
		}

		loadClassDependents(dancer.uid)

		const list: any[] = this.$store.getters.missedClassesForDancer(dancer.uid)
		
		const classes = list.map(
			data => {
				data.classData = this.classFromUid(data.missedClass)

				return data
			}
		)

		const missedCredits = reduce(classes, (sum, obj) => sum += (obj.credits - obj.creditsUsed), 0)
		const extraCredts = this.getExtraCreditsCount(dancer)

		return missedCredits + extraCredts
	}
}
