<template>
<div>
	<div v-if="makeups.length > 0" class="columns is-multiline">
		<div v-for="item in makeups" :key="item.uid" class="column is-12">
			<div class="box">
				<div class="columns">
					<div class="column is-3">
						{{ monthDateYearformat(item.makeup.makeupDate) }}<br>
						Credits: {{ item.credits }}
					</div>
					<div class="column">
						<span v-if="item.special">
							Special Makeup Circumstance<br>
							{{ item.makeup.description }}
						</span>
						<span v-else>
							{{ makeupFormat(item.classData) }}<br>
						</span>
					</div>
					<div class="column is-3">
						<p class="buttons is-pulled-right">
							<button class="button is-warning" @click="handleMissed(item)">
								<b-tooltip label="Mark as missed">
									<span class="icon is-small">
										<i class="far fa-calendar-minus"></i>
									</span>
									<span>
										Missed
									</span>
								</b-tooltip>
							</button>
							<button class="button is-danger" @click="handleDelete(item)">
								<span class="icon is-small">
									<i class="fas fa-times"></i>
								</span>
							</button>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<section class="section" v-if="makeups.length === 0">
		<div class="content has-text-grey has-text-centered">
			<p>
				There are no makeups.
			</p>
		</div>
	</section>
	<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</template>

<script>
import values from 'lodash/values'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import head from 'lodash/head'
import orderBy from 'lodash/orderBy'

import { deleteMakeup } from '@/utils/makeupClasses'
import missMakeup from '@/utils/missMakeup'

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import FormatDate from '@/mixins/FormatDate'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

export default {
	props: {
		dancer: {
			type: Object,
			default: defaultDancer()
		}
	},
	mixins: [
		NameFromUid,
		FormatClass,
		FormatDate
	],
	data: function () {
		return {
			isLoading: false
		}
	},
	mounted: function () {
	},
	methods: {
		handleMissed: function (item) {
			this.isLoading = true

			const makeupData = item.makeup

			const missed = {
				credits: 0,
				creditsUsed: 0,
				makeupUid: makeupData.uid,
				missedClass: head(values(makeupData.makeupClass)),
				missedDate: makeupData.makeupDate
			}
			
			missMakeup(makeupData, missed, this.dancer.uid).then(() => {
				this.isLoading = false
			})
		},
		handleDelete: function (item) {
			this.isLoading = true

			deleteMakeup(item.makeup, this.dancer.uid).then(() => {
				this.isLoading = false
			})
			.catch(err => {
				console.error(err)
			})
		}
	},
	computed: {
		makeups: function () {
			if (!this.dancer)
			{
				return []
			}

			const list = this.$store.getters.makeupsForDancer(this.dancer.uid)

			if (!list)
			{
				return []
			}

			return orderBy(map(
				list,
				makeup => {
					const ret = {
						special: makeup.type === 'special',
						makeup: makeup
					}

					if (!ret.special)
					{
						ret.classData = this.$store.getters.class(values(makeup.makeupClass)[0])
					}

					ret.credits = reduce(values(makeup.credits), (sum, n) => sum + n, 0)

					return ret
				}
			), ['makeup.makeupDate'], ['desc'])
		}
	},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
