<template>
<div class="field is-horizontal">
	<div class="field-label is-normal">
		<b-tooltip :label="tooltip" v-if="tooltip">
			<label class="label">{{ label }}</label>
		</b-tooltip>
		<label v-if="!tooltip" class="label">{{ label }}</label>
	</div>
	<div class="field-body">
		<div class="field"  v-bind:class="cssClasses">
		<div class="control">
			<div class="select is-fullwidth">
			<select v-bind:value="value"
				:disabled="disabled"
				v-on:change="$emit('input', parseValue($event.currentTarget.options[$event.currentTarget.selectedIndex].value))"
			>
				<option
					v-for="option in options"
					:selected="getOptionValue(option) === value"
					:value="getOptionValue(option)"
					:key="option.hasOwnProperty(keyProperty) ? option[keyProperty] : option">
						<slot name="option" v-bind:option="option">
							{{ option }}
						</slot>
				</option>
			</select>
			</div>
		</div>
		</div>
	</div>
</div>
</template>

<script>
import head from 'lodash/head'
import isInteger from 'lodash/isInteger'
import isString from 'lodash/isString'
import parseInt from 'lodash/parseInt'

export default {
	props: {
		label: String,
		options: Array,
		keyProperty: String,
		value: [String, Number],
		isNormal: Boolean,
		isNarrow: Boolean,
		valueProperty: {
			type: String,
			default: 'value'
		},
		tooltip: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			cssClasses: {
				'is-normal': this.isNormal,
				'is-narrow': this.isNarrow
			},
			isNumeric: false
		}
	},
	mounted: function () {
		if (this.options.length <= 0)
		{
			return
		}

		const v = this.getOptionValue(head(this.options))
		if (isInteger(v))
		{
			this.isNumeric = true
		}
	},
	methods: {
		getOptionValue: function (option) {
			if (isInteger(option))
			{
				return option
			}

			const v = !isString(option) ? option[this.valueProperty] : option

			return this.parseValue(v)
		},
		parseValue: function (v) {
			if (this.isNumeric)
			{
				return parseInt(v)
			}

			return v
		}
	}
}
</script>

<style scoped>

</style>