<template>
<div>
	<section class="section">
		<div class="container">
			<h1 class="title">Update Teacher: {{ teacher.name }}</h1>

			<b-notification :active="notificationType !== ''" :type="notificationType" :closable="false" :auto-close="true">
				{{ notificationMessage }}
			</b-notification>

			<form @submit="handleUpdate">
				<horizontal-input v-model="teacher.name" 
					label="Name" 
					placeholder="instructor name..."
					:is-narrow="true" />

				<div class="field is-horizontal">
					<div class="field-label">
						<!-- Left empty for spacing -->
					</div>
					<div class="field-body">
						<div class="field is-narrow is-grouped is-grouped-right">
							<div class="control">
								<button type="submit" class="button is-primary" :class="state === 'working' ? 'is-loading' : ''">
									Update
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<hr/>
			<h3 class="is-size-4">
				Teacher Login
			</h3>
			<br>
			<form @submit="handleCreateLogin">
				<div class="field is-horizontal">
					<div class="field-label">
						<label class="label">Email</label>
					</div>
					<div class="field-body">
						<div class="field is-narrow is-grouped is-grouped-right">
							<div class="control has-icons-left">
								<ValidationProvider rules="required|email" v-slot="{ errors }">
									<input class="input"
										v-model="form.email"
										name="email"
										type="email"
										:disabled="teacher.login"
									/>
									<span>{{ errors[0] }}</span>
								</ValidationProvider>
								<span class="icon is-left">
									<i class="fas fa-lg fa-envelope"></i>
								</span>
							</div>
							<div class="control" v-if="teacher.login">
								<a @click="$copyText(teacher.login.email)" class="button">
									<span class="icon is-small">
										<i class="fas fa-copy"></i>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="field is-horizontal">
					<div class="field-label">
						<label class="label">Password</label>
					</div>
					<div class="field-body">
						<div class="field is-narrow is-grouped is-grouped-right">
							<div class="control has-icons-left" v-if="!teacher.login">
								<ValidationProvider rules="required|min:6" v-slot="{ errors }">
									<input class="input"
										v-model="form.password"
										name="password"
										type="password"
									/>
									<span>{{ errors[0] }}</span>
									<span class="icon is-left">
										<i class="fas fa-lg fa-key"></i>
									</span>
									<p class="help is-danger" v-if="errors.length > 0">{{ errors[0] }}</p>
								</ValidationProvider>
							</div>
							<div class="has-text-centered" v-else>
								Manage from Firebase Console
							</div>
						</div>
					</div>
				</div>
				
				<div class="field is-horizontal" v-if="!teacher.login">
					<div class="field-label">
						<!-- Left empty for spacing -->
					</div>
					<div class="field-body">
						<div class="field is-narrow is-grouped is-grouped-right">
							<button class="button is-primary has-text-dark is-pulled-right"
								:class="{'is-loading': state === 'creating-login'}">
								Create Login
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</section>
</div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'

import HorizontalInput from '@/components/inputs/HorizontalInput.vue'

import {
	firestore as db,
	collections,
	functions
} from '@/firebase'

import cloneDeep from 'lodash/cloneDeep'
import clone from 'lodash/clone'

extend('email', email)
extend('required', required)
extend('min', min)

export default {
	data: function () {
		return {
			teacher: {
				name: ''
			},
			form: {
				email: '',
				password: ''
			},
			state: 'idle',
			notificationMessage: ''
		}
	},
	mounted: function () {
		if (!this.uid)
		{
			return
		}

		this.teacher = cloneDeep(this.$store.getters.teacher(this.uid))

		if (this.teacher.login)
		{
			this.form.email = this.teacher.login.email
		}
	},
	computed: {
		uid: function () {
			return this.$route.params.teacherUid
		},
		notificationType: function () {
			switch (this.state)
			{
				default:
					return ''

				case 'error':
					return 'is-danger'

				case 'success':
					return 'is-success'
			}

		}
	},
	methods: {
		handleUpdate: function (evt) {
			evt.preventDefault()
			this.state = 'working'
			
			const data = clone(this.teacher)
			delete data.uid

			db.collection(collections.Teachers).doc(this.teacher.uid).update(data).then(() => {
				this.state = 'success'
				this.notificationMessage = data.name + ' has been updated!'
			})
			.catch(err => {
				console.error(err)
				this.state = 'error'
				this.notificationMessage = err
			})
		},

		handleCreateLogin: function (evt) {
			evt.preventDefault()

			this.state = 'creating-login'

			const createLogin = functions.httpsCallable('teachers_createLogin')
			return createLogin({
				teacherUid: this.teacher.uid,
				email: this.form.email,
				password: this.form.password
			})
			.then(() => {
				this.teacher.login = {
					uid: this.teacher.uid,
					email: this.form.email
				}
				this.state = 'idle'
			})
			.catch(err => {
				this.state = 'idle'
				console.log(err)
			})
		}
	},
	components: {
		ValidationProvider,
		HorizontalInput
	}
}
</script>

<style scoped>

</style>