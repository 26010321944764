<template>
<div>
	<section class="section">
		<div class="container">
			<h1 class="title">Classes 
				<a @click="$router.push('/classes/add')">
					<b-icon icon="plus-circle" size="is-large"></b-icon>
				</a>
			</h1>
			<form @submit="evt => evt.preventDefault()">
				<div class="field is-grouped">
					<div class="control">
						<b-input placeholder="filter..."
							v-model="filterPhrase"
							@input="handleFilterChange"
							type="search"
							icon-pack="fas"
							icon="search">
						</b-input>
					</div>
					<div class="control" style="padding-top: 8px">
						<b-checkbox v-model="showDiscontinued">
							Show Discontinued
						</b-checkbox>
					</div>
				</div>
			</form>
			<br>
			<div v-for="(studio, idx) in studios" :key="idx" >
				<a v-show="classesCountByStudio[studio.uid]" @click="toggleStudio(studio)" class="button is-medium is-text">
					<b-icon
						icon="caret-down"
						size="is-small">
					</b-icon>
					<span>{{ studio.name }}</span>
				</a>
				<table v-show="classesCountByStudio[studio.uid] && !studioHidden[studio.uid]" class="table is-striped is-bordered is-narrow is-hoverable is-fullwidth" v-if="classes.length > 0">
					<thead>
						<tr>
							<th>Status</th>
							<th @click="handleSort('dayOfWeek')">Day</th>
							<th>Level</th>
							<th @click="handleSort('classType')">Type</th>
							<th @click="handleSort('startTime')">Start Time</th>
							<th @click="handleSort('startDate')">Start Date</th>
							<th @click="handleSort('length')">Length</th>
							<th @click="handleSort('instructor')">Instructor</th>
							<th>Dancers</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="row in classesByStudio(studio)" :key="row.uid" @click="handleEdit(row)">
							<td style="width: 115px">
								{{ row.status }}	
							</td>
							<td style="width: 90px">
								{{ row.dayOfWeek }}	
							</td>
							<td style="width: 80px">
								{{ classLevelFromUid(row.classLevel) }}	
							</td>
							<td style="width: 115px">
								{{ classTypeFromUid(row.classType) }}
							</td>
							<td style="width: 100px">
								{{ row.startTime }}	
							</td>
							<td style="width: 130px">
								{{ formatDate(row.startDate) }}	
							</td>
							<td style="width: 80px">
								{{ row.length + 'mins' }}	
							</td>
							<td style="width: 100px">
								{{ teacherNameFromUid(row.instructor) }}	
							</td>
							<td class="has-text-centered">
								<article class="media">
									<div class="media-content">
										<div class="field is-grouped is-grouped-multiline">
											<div v-for="(item, idx) in dancersForClass(row)" :key="idx" class="control">
												<b-tooltip type="is-dark" :label="item.event ? item.event.action : 'Active'">
													<div class="tags has-addons">
														<span class="tag" @click="$router.push('/dancers/' + item.uid)">{{ item.firstName + ' ' + item.lastName }}</span>
														<span class="tag is-dark" v-if="item.event">{{ monthDayFormat(item.event.effectiveDate) }}</span>
														<span class="tag" v-if="item.event" :class="iconTypeForAction(item.event.action)">
															<b-icon
																pack="fas"
																:icon="iconForAction(item.event.action)"
																size="is-small">
															</b-icon>
														</span>
													</div>
												</b-tooltip>
											</div>
										</div>
									</div>
									<figure class="media-right">
										<div class="tags has-addons">
											<span class="tag" :class="determineCountTagColor(row)">
												<b-tooltip type="is-dark" :label="dancerCountsByClass[row.uid].current + ' active now'">
													{{ dancerCountsByClass[row.uid].current }}
												</b-tooltip>
											</span>
											<span class="tag">
												<b-tooltip type="is-dark" :label="dancerCountsByClass[row.uid].future + ' after events'">
													{{ dancerCountsByClass[row.uid].future }}
												</b-tooltip>
											</span>
										</div>
									</figure>
								</article>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<section class="section" v-if="classes.length === 0">
				<div class="content has-text-grey has-text-centered">
					<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
					<div v-show="this.filterPhrase">
						<p>No results...</p>
					</div>
				</div>
			</section>
		</div>
  </section>
  <edit-class-modal ref="editClassModal" />
</div>
</template>

<script>
import moment from 'moment'

import throttle from 'lodash/throttle'
import filter from 'lodash/filter'
import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'

import { 
	filterClasses,
} from '@/utils'

import EditClassModal from './components/EditClassModal.vue'

import NameFromUid from '@/mixins/NameFromUid'
import DancerEventsMixin from '@/mixins/DancerEventsMixin'
import FormatDate from '@/mixins/FormatDate'
import ClassesForDancer from '@/mixins/ClassesForDancer'
import Sortable from '@/mixins/Sortable'

export default {
	mixins: [
		NameFromUid,
		DancerEventsMixin,
		FormatDate,
		ClassesForDancer,
		Sortable
	],
	data: function () {
		return {
			filterPhrase: '',
			showDiscontinued: false,
			classes: [],
			isLoading: true,
			studioHidden: {},
			classesCountByStudio: {},
			dancerCountsByClass: {},

			sortState: {
				'length': 1
			},
			lastSortKey: 'length'
		}
	},
	mounted: function () {
		this.handleFilterChange()

		const classId = this.$route.params.classId
		if (classId)
		{
			this.handleEdit(this.$store.getters.class(classId))
		}
	},
	watch: {
		allClasses: function () {
			if (this.classes > 0)
			{
				return
			}

			this.handleFilterChange()
		},
		classes: function () {
			this.isLoading = false
		}
	},
	computed: {
		allClasses: function () {
			var list = this.$store.getters.classes

			if (!this.showDiscontinued)
			{
				list = filter(list, c => c.status !== 'Discontinued')
			}

			return list
		},

		studios: function () {
			return this.$store.getters.studios
		}
	},
	methods: {
		classesByStudio: function (studio) {
			const list = filter(this.classes, obj => obj.studio === studio.uid)

			this.$set(this.classesCountByStudio, studio.uid, list.length)

			return list
		},
		toggleStudio: function (studio) {
			if (!(studio.uid in this.studioHidden))
			{
				this.$set(this.studioHidden, studio.uid, false)
			}

			this.studioHidden[studio.uid] = !this.studioHidden[studio.uid]
		},

		formatDate: function (date) {
			const now = moment()
			const d = moment(date)

			if (d.isBefore(now))
			{
				return 'Ongoing'
			}

			return d.format('MMM Do YYYY')
		},

		handleEdit: function (data) {
			const d = clone(data)

			// don't send the extra event data only used in this view
			delete d.event

			this.$refs.editClassModal.show(d)
		},
		handleFilterChange: throttle(function () {
			this.classes = sortBy(
				filterClasses(this.allClasses, this.filterPhrase),
				[this.lastSortKey]
			)
		}, 500, { leading: false, trailing: true }),

		getSortableList: function () {
			return this.classes
		},
		setSortableList: function (list) {
			this.classes = list
		},

		determineCountTagColor: function (row) {
			if (row.status !== 'Active')
			{
				return { 'is-light': true }
			}

			const count = this.dancerCountsByClass[row.uid].current

			return {
				'is-danger': count === 0,
				'is-warning': count === 1,
				'is-info': count === 2,
				'is-success': count > 2,
				'has-text-weight-semibold': count > 2
			}
		}
	},
	components: {
		EditClassModal
	}
}
</script>

<style scoped>
td 
{
	vertical-align: middle;
}

.table th {
	vertical-align: middle;
}

tr:hover {
	cursor: pointer;
}
</style>