var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field is-horizontal" }, [
    _c(
      "div",
      { staticClass: "field-label is-normal" },
      [
        _vm.tooltip
          ? _c("b-tooltip", { attrs: { label: _vm.tooltip } }, [
              _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
            ])
          : _vm._e(),
        !_vm.tooltip
          ? _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "field-body" }, [
      _c("div", { staticClass: "field", class: _vm.cssClasses }, [
        _c("div", { staticClass: "control" }, [
          _c("div", { staticClass: "select is-fullwidth" }, [
            _c(
              "select",
              {
                attrs: { disabled: _vm.disabled },
                domProps: { value: _vm.value },
                on: {
                  change: function($event) {
                    _vm.$emit(
                      "input",
                      _vm.parseValue(
                        $event.currentTarget.options[
                          $event.currentTarget.selectedIndex
                        ].value
                      )
                    )
                  }
                }
              },
              _vm._l(_vm.options, function(option) {
                return _c(
                  "option",
                  {
                    key: option.hasOwnProperty(_vm.keyProperty)
                      ? option[_vm.keyProperty]
                      : option,
                    domProps: {
                      selected: _vm.getOptionValue(option) === _vm.value,
                      value: _vm.getOptionValue(option)
                    }
                  },
                  [
                    _vm._t("option", [_vm._v(" " + _vm._s(option) + " ")], {
                      option: option
                    })
                  ],
                  2
                )
              }),
              0
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }