




























































































































































import { Vue, Component } from 'vue-property-decorator'

import { defaultPriceFormat } from '@/utils/priceFormat'

import loader from '@/dataLoader'

import {
	addPledge,
	updatePledge,
	getReleveCount,
	setReleveCount,
	chargePledge
} from '@/database/releves'

import throttle from '@/decorators/throttle'
import ConfirmChargeModal from './ConfirmChargeModal.vue'

interface PledgeItem
{
	name: string
	email: string
	stripeId: string
	pledge: number
	cap: number
	bonus: number
	total?: number
	charged?: boolean
}

@Component({
	components: {
		ConfirmChargeModal
	}
})
export default class Releveathon extends Vue
{
	private releveCount = 0
	private csvFile: File | null = null
	private pledges: PledgeItem[] = []
	private errorsByEmail: { [key: string]: string } = {}

	private isLoading = false

	get pledgesFromDatabase(): PledgeItem[]
	{
		return this.$store.getters['releves/pledges'] || []
	}

	get pledgesFromDatabaseByEmail()
	{
		const ret: { [key: string]: PledgeItem } = {}
		this.pledgesFromDatabase.forEach(item => {
			// @ts-ignore
			ret[item.email] = item
		})
		return ret
	}

	async created()
	{
		loader.fetchAndListenForReleves()

		const { count } = await getReleveCount()
		this.releveCount = count
	}

	@throttle(500, { leading: false, trailing: true })
	private handleReleveCountChanged(val: number)
	{
		setReleveCount(this.releveCount)
	}

	private handleCsvUploaded(file: File)
	{
		const reader = new FileReader()
		reader.onload = (e) => {
			const csv = e.target!.result as string

			const rows = csv.split('\n')
			const header = rows.shift()!.split(',')
			const results: PledgeItem[] = []

			rows.forEach(row => {
				if (!row)
				{
					return
				}

				const cols = row.split(',')
				const item: PledgeItem = {
					name: '',
					email: '',
					stripeId: '',
					pledge: 0,
					cap: 0,
					bonus: 0
				}

				cols.forEach((col, idx) => {
					const key = header[idx]
					switch (key)
					{
						case 'Name':
							item.name = col
							break

						case 'Email':
							item.email = col
							break

						case 'Stripe ID':
							item.stripeId = col
							break

						case 'Per Releve':
							item.pledge = parseFloat(col.replace('$', ''))
							break

						case 'Cap':
							if (col === '-')
							{
								item.cap = 0
							}
							else
							{
								item.cap = parseFloat(col.replace('$', ''))
							}
							break

						case 'Bonus':
							if (col === '-')
							{
								item.bonus = 0
							}
							else
							{
								item.bonus = parseFloat(col.replace('$', ''))
							}
							break
					}
				})
				results.push(item)
			})

			this.pledges = results

			this.reconcilePledgesWithDB()

			reader.onload = null
		}

		reader.readAsText(file)
	}

	private reconcilePledgesWithDB()
	{
		for (const pledge of this.pledges)
		{
			if (!pledge.email)
			{
				continue
			}

			if (pledge.email in this.pledgesFromDatabaseByEmail)
			{
				const dbPledge = this.pledgesFromDatabaseByEmail[pledge.email]
				if (pledge.pledge !== dbPledge.pledge || pledge.bonus !== dbPledge.bonus || pledge.cap !== dbPledge.cap)
				{
					updatePledge(pledge)
				}
				continue
			}

			addPledge(pledge)
		}
	}

	private calculateTotal(pledge: PledgeItem)
	{
		const base = Math.min(pledge.cap || 1000, (pledge.pledge || 0) * this.releveCount)
		return base + pledge.bonus
	}

	private async handleCharge(pledge: PledgeItem)
	{
		// @ts-ignore
		this.$refs.confirmChargeModal.show().then(async () => {
			// @ts-ignore
			this.$refs.confirmChargeModal.close()

			this.isLoading = true

			pledge.total = this.calculateTotal(pledge)

			try
			{
				const res = await chargePledge(pledge, this.releveCount)
				console.dir(res)
				if (pledge.email in this.errorsByEmail)
				{
					delete this.errorsByEmail[pledge.email]
				}
			}
			catch (err)
			{
				console.error(err)
				this.errorsByEmail[pledge.email] = err.message
			}

			this.isLoading = false
		})
	}

	private displayAsPrice(str: string)
	{
		if (!str)
		{
			return `--`
		}
		return defaultPriceFormat(str)
	}
}
