




























































import { Component, Mixins } from 'vue-property-decorator'

import ClassAutocomplete from '@/components/classes/ClassAutocomplete.vue'
import ClassAppointmentSelector from '@/components/classes/ClassAppointmentSelector.vue'

import clone from 'lodash/clone'

import { 
	makeupClasses
} from '@/utils/makeupClasses'

import {
	firestore as db,
	collections
} from '@/firebase'

const MakeupApplier = require('studio-shared/makeups/MakeupApplier')

const makeupApplier = new MakeupApplier(db, collections)

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import FormatDate from '@/mixins/FormatDate'
import AddMakeupClass from '@/mixins/AddMakeupClass'
import IsPrima from '@/mixins/IsPrima'

@Component({
	components: {
		ClassAutocomplete,
		ClassAppointmentSelector
	}
})
export default class AddMakeupClassMultipleModal extends Mixins(NameFromUid, FormatDate, FormatClass, AddMakeupClass, IsPrima)
{
	private active = false
	private maxCredits = 0
	private missedClasses: any[] = []
	private extraCredits: any[] = []

	get potentialClasses()
	{
		if (!this.dancer.uid)
		{
			return []
		}

		let maxCredits = this.maxCredits
		if (this.isPrima)
		{
			maxCredits = 5
		}

		return makeupClasses(this.dancer.uid, maxCredits, true)
	}

	protected getDancer()
	{
		return this.dancer
	}

	show(dancerData: IDancer, credits: number, missedClasses: any[], extraCredits: any[])
	{
		this.active = true

		this.dancer = clone(dancerData)
		this.maxCredits = credits
		this.missedClasses = missedClasses
		this.extraCredits = extraCredits
	}

	private handleClose()
	{
		this.resetData()
	}

	private async handleSubmit(evt: MouseEvent)
	{
		evt.preventDefault()

		this.isLoading = true

		const data = clone(this.makeup)

		if (!data.source)
		{
			data.source = 'admin'
		}

		if (this.isPrima)
		{
			data.credits = Math.min(data.credits, this.maxCredits)
		}

		try
		{
			if (data.credits <= 0)
			{
				await makeupApplier.applyFreeMakeup(data, this.dancer)
			}
			else
			{
				await makeupApplier.applyAutomatically(data, this.missedClasses, this.dancer.uid, this.extraCredits)
			}

			this.isLoading = false
			this.active = false

			this.handleClose()
		}
		catch(err)
		{
			console.error(err)

			this.isLoading = false
		}
	}

	private handleClassSelected(data: any)
	{
		this.makeup.makeupClass = data
		this.makeup.maxMakeups = data.maxMakeups

		const type = this.$store.getters.classType(data.classType)
		this.makeup.credits = type.credits

		this.generateSelectableDates(data)

		this.generateAppointments(data)
	}

	private removeSelectedClass()
	{
		this.makeup.makeupClass = null
		this.makeup.credits = 0
		this.makeup.makeupDate = ''

		this.selectableDates = []
		this.selectableClasses = []

		// @ts-ignore
		this.$refs.makeupClassSelector.removeSelectedAppointment()
	}

	private handleSelectedAppointment(data: any)
	{
		this.selectedAppointment = data
		this.makeup.makeupDate = this.selectedAppointment.date
	}

	private removeSelectedAppointment()
	{
		this.selectedAppointment = null
		this.makeup.makeupDate = ''
	}

	private handleLoadMore()
	{
		this.loadMoreAppointments(this.makeup.makeupClass!)
	}
}
