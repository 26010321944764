<template>
<div>
<section class="section">
<div class="container">

	<h1 class="title">
		Teachers
		<a @click="$router.push('/teachers/add')">
			<span class="icon">
				<i class="fas fa-plus-circle"></i>
			</span>
		</a>
	</h1>

	<div class="columns is-multiline">
		<div class="column is-one-third" v-for="teacher in teachers" :key="teacher.uid">
			<div class="box">
				<h3 class="title is-5">
					{{ teacher.name }}
				</h3>
				<br>
				<div class="field is-grouped is-grouped-right">
					<div class="control">
						<button @click="handleDelete(teacher)" class="button is-danger is-outlined">
							<span class="icon">
								<i class="fas fa-trash-alt"></i>
							</span>
							<span>
								Delete
							</span>
						</button>
					</div>
					<div class="control">
						<button @click="$router.push('/teachers/' + teacher.uid)" class="button">
							<span class="icon">
								<i class="fas fa-edit"></i>
							</span>
							<span>
								Edit
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
</section>
<alert-modal ref="alertModal" />
</div>
</template>

<script>
import AlertModal from '@/components/AlertModal'

import {
	firestore as db,
	collections
} from '@/firebase'

import sortBy from 'lodash/sortBy'

export default {
	data: function () {
		return {
		}
	},
	methods: {
		handleDelete: function (teacher) {
			this.$refs.alertModal.show({
				title: `Confirm Delete ${teacher.name}?`,
				message: 'Are you sure you want to delete this teacher? It cannot be undone and any classes still referencing this teacher may become broken.',
				confirm: 'Yes'
			})
			.then(() => {
				db.collection(collections.Teachers).doc(teacher.uid).delete()
			})
			.then(() => {
				this.$refs.alertModal.close()
			})

			
		}
	},
	computed: {
		teachers: function () {
			return sortBy(this.$store.getters.teachers, 'name')
		}
	},
	components: {
		AlertModal
	}
}
</script>

<style scoped>

</style>