<template>
<div>
	<section class="section">
		<div class="container">
			<h1 class="title">Archived Dancers </h1>
			<dancers-list :allDancers="allDancers" 
				:showClasses="false"
				@edit="handleEdit"
				v-show="allDancers.length > 0" />
			<section class="section" v-if="!isLoading && allDancers.length === 0">
				<div class="content has-text-grey has-text-centered">
					<p>
						There are no archived dancers.
					</p>
					<button @click="$router.push('/dancers')" class="button">
						Back to Dancers
					</button>
				</div>
			</section>
		</div>
	</section>
	<edit-dancer-modal ref="editDancerModal" @restored="handleRestored" />
</div>
</template>

<script>
import DancersList from './components/DancersList'
import EditDancerModal from './components/EditDancerModal.vue'

import findIndex from 'lodash/findIndex'

import toMoment from 'studio-shared/utils/toMoment'

import { 
	firestore as db,
	collections
} from '@/firebase'

export default {
	data: function () {
		return {
			allDancers: [],
			isLoading: true
		}
	},
	mounted: function () {
		db.collection(collections.ArchivedDancers).get().then(snap => {
			this.isLoading = false

			if (snap.empty)
			{
				return
			}

			for (var i = 0; i < snap.docs.length; ++i)
			{
				const doc = snap.docs[i]
				const data = doc.data();
				data.uid = doc.id

				// TODO: consolidate this with the same code in dataLoader
				if (data.dateOfFirstClass) {
					data.dateOfFirstClass = toMoment(data.dateOfFirstClass).toDate()
				}

				if (data.dateOfLastClass) {
					data.dateOfLastClass = toMoment(data.dateOfLastClass).toDate()
				}

				this.allDancers.push(data)
			}
		})
	},
	watch: {
	},
	computed: {
	},
	methods: {
		handleEdit: function (data) {
			this.$refs.editDancerModal.show(data)
		},
		handleRestored: function (data) {
			const idx = findIndex(this.allDancers, obj => obj.uid === data.uid)

			if (idx < 0)
			{
				return
			}

			this.$delete(this.allDancers, idx)
		}
	},
	components: {
		DancersList,
		EditDancerModal
	}
}
</script>

<style scoped>
td 
{
	vertical-align: middle;
}

.table th {
	vertical-align: middle;
}

tr:hover {
	cursor: pointer;
}
</style>