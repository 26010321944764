var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "b-notification",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notificationType,
                  expression: "notificationType"
                }
              ],
              attrs: { type: _vm.notificationType }
            },
            [_vm._v(" " + _vm._s(_vm.notificationMessage) + " ")]
          ),
          _c("h1", { staticClass: "title" }, [_vm._v("Add New Instructor")]),
          _c(
            "form",
            { on: { submit: _vm.handleSubmit } },
            [
              _c("horizontal-input", {
                attrs: {
                  label: "Name",
                  placeholder: "instructor name...",
                  "is-narrow": true
                },
                model: {
                  value: _vm.teacher.name,
                  callback: function($$v) {
                    _vm.$set(_vm.teacher, "name", $$v)
                  },
                  expression: "teacher.name"
                }
              }),
              _c("div", { staticClass: "field is-horizontal" }, [
                _c("div", { staticClass: "field-label" }),
                _c("div", { staticClass: "field-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field is-narrow is-grouped is-grouped-right"
                    },
                    [
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            class: _vm.state === "working" ? "is-loading" : "",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(" Add ")]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }