var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        }
      }
    },
    [
      _c("div", { staticClass: "box" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("Create New Class Level")]),
        _c(
          "form",
          { on: { submit: _vm.handleSubmit } },
          [
            _c("horizontal-input", {
              attrs: {
                label: "Name",
                placeholder: "eg: Intro / 1",
                "is-normal": true
              },
              model: {
                value: _vm.classLevel.name,
                callback: function($$v) {
                  _vm.$set(_vm.classLevel, "name", $$v)
                },
                expression: "classLevel.name"
              }
            }),
            _c("horizontal-select", {
              attrs: { label: "Level Type", options: _vm.template.levelTypes },
              model: {
                value: _vm.classLevel.levelType,
                callback: function($$v) {
                  _vm.$set(_vm.classLevel, "levelType", _vm._n($$v))
                },
                expression: "classLevel.levelType"
              }
            }),
            _c("horizontal-select", {
              attrs: { label: "Value", options: _vm.template.values },
              model: {
                value: _vm.classLevel.value,
                callback: function($$v) {
                  _vm.$set(_vm.classLevel, "value", _vm._n($$v))
                },
                expression: "classLevel.value"
              }
            }),
            _c("div", { staticClass: "field is-horizontal" }, [
              _c("div", { staticClass: "field-label" }),
              _c("div", { staticClass: "field-body" }, [
                _c(
                  "div",
                  { staticClass: "field is-grouped is-grouped-right" },
                  [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          class: _vm.state === "working" ? "is-loading" : "",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(" Add Class Level ")]
                      )
                    ])
                  ]
                )
              ])
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }