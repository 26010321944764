<template>
<b-modal :active.sync="active" @close="handleClose">
<div class="box" style="min-height: 600px">
	<h1 class="title">
		Add Special Makeup Class
		<p class="subtitle">
			Schedule a special makeup and apply to classes automatically.
		</p>
	</h1>
	<p>
		{{ makeup.credits }} of {{ this.maxCredits }} credits used
	</p>
	<br>
	<form @submit="handleSubmit">
		<div class="field">
			<label class="label">Description</label>
			<div class="control">
				<b-input type="text" v-model="makeup.description">
            </b-input>
			</div>
		</div>
		<div class="field">
			<label class="label">Date of Makeup Class</label>
			<div class="control">
				<b-datepicker
					placeholder="Select a date..."
					icon="calendar-alt"
					v-model="makeup.makeupDate"
					:date-formatter="defaultFormatDate">
				</b-datepicker>
			</div>
		</div>
		<div class="field">
			<label class="label">Credits</label>
			<div class="control">
				<b-select v-model="makeup.credits" placeholder="Select credits">
					<option
						v-for="option in template.credits"
						:value="option"
						:key="option">
						{{ option }}
					</option>
				</b-select>
			</div>
		</div>
		<div class="field is-grouped is-grouped-right">
			<div class="control">
				<button class="button is-primary" 
					:disabled="!makeup.description"
					:class="{ 'is-loading': isLoading }">
					Add
				</button>
			</div>
		</div>
	</form>
</div>
</b-modal>
</template>

<script>
import clone from 'lodash/clone'
import range from 'lodash/range'

import moment from 'moment'

import {
	firestore as db,
	collections
} from '@/firebase'

const MakeupApplier = require('studio-shared/makeups/MakeupApplier')

const makeupApplier = new MakeupApplier(db, collections)

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import FormatDate from '@/mixins/FormatDate'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

const defaultMakeup = () => {
	return {
		type: 'special',
		credits: 1,
		description: '',
		makeupDate: null
	}
}

export default {
	mixins: [
		NameFromUid,
		FormatClass,
		FormatDate
	],
	data: function () {
		return {
			template: {
				credits: range(0, 11)
			},
			dancer: defaultDancer(),
			makeup: defaultMakeup(),
			active: false,
			maxCredits: 0,
			missedClasses: [],
			extraCredits: [],
			isLoading: false
		}
	},
	mounted: function () {
	},
	methods: {
		show: function (dancerData, credits, missedClasses, extraCredits) {
			this.active = true

			this.dancer = clone(dancerData)
			this.maxCredits = credits
			this.missedClasses = missedClasses
			this.extraCredits = extraCredits
		},
		handleClose: function () {
			this.dancer = defaultDancer()
			this.makeup = defaultMakeup()
		},

		handleSubmit: function (evt) {
			evt.preventDefault()

			this.isLoading = true

			const data = clone(this.makeup)
			data.created = moment().toDate()

			makeupApplier.applyAutomatically(data, this.missedClasses, this.dancer.uid, this.extraCredits)
				.then(() => {
					this.isLoading = false
					this.active = false

					this.handleClose()
				})
				.catch(err => {
					console.error(err)

					this.isLoading = false
				})
		}
	},
	computed: {
	},
	components: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
