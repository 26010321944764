var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("edit-dancer-component", {
            ref: "editDancerComponent",
            on: { archived: _vm.handleArchived }
          }),
          _c("b-loading", {
            attrs: {
              "is-full-page": false,
              active: _vm.isLoading,
              "can-cancel": false
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }