var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c("div", { staticClass: "box" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(" Add Credits "),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(" Give dancer extra credits. ")
          ])
        ]),
        _c("br"),
        _c("form", { on: { submit: _vm.handleSubmit } }, [
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Description")]),
            _c(
              "div",
              { staticClass: "control" },
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.form.description,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Credits")]),
            _c(
              "div",
              { staticClass: "control" },
              [
                _c(
                  "b-select",
                  {
                    attrs: { placeholder: "Select credits" },
                    model: {
                      value: _vm.form.credits,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "credits", $$v)
                      },
                      expression: "form.credits"
                    }
                  },
                  _vm._l(_vm.template.credits, function(option) {
                    return _c(
                      "option",
                      { key: option, domProps: { value: option } },
                      [_vm._v(" " + _vm._s(option) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
            _c("div", { staticClass: "control" }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary",
                  class: { "is-loading": _vm.isLoading },
                  attrs: { disabled: !_vm.form.description }
                },
                [_vm._v(" Add ")]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }