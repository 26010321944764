
































































































































































































import { Component, Mixins } from 'vue-property-decorator'

import AddMissedClassModal from './components/AddMissedClassModal.vue'
import AddMakeupClassSingleModal from './components/AddMakeupClassSingleModal.vue'
import AddMakeupClassMultipleModal from './components/AddMakeupClassMultipleModal.vue'
import AddSpecialMakeupClassModal from './components/AddSpecialMakeupClassModal.vue'
import AddCreditsModal from './components/AddCreditsModal.vue'
import AddFreeClassModal from './components/AddFreeClassModal.vue'

import ManageMakeupComponent from './components/ManageMakeupComponent.vue'
import ManageExtraCreditsComponent from './components/ManageExtraCreditsComponent.vue'
import ManageFreeClassesComponent from './components/ManageFreeClassesComponent.vue'

import loader from '@/dataLoader'

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import clone from 'lodash/clone'
import forEach from 'lodash/forEach'
import keys from 'lodash/keys'
import flatten from 'lodash/flatten'
import startsWith from 'lodash/startsWith'

import FormatClass from '@/mixins/FormatClass'
import FormatDate from '@/mixins/FormatDate'
import IsPrima from '@/mixins/IsPrima'

import { 
	deleteMakeup,
	makeupKeyForMissedClass
} from '@/utils/makeupClasses'

import {
	firestore as db,
	collections
} from '@/firebase'

const extraCreditsApplied = (list: any) => {
	return reduce(list, (sum, value, key) => {
		if (!startsWith(key, 'ec'))
		{
			return sum
		}

		return sum + value
	}, 0)
}

const loadClassDependents = (uid: string) => {
	loader.fetchAndListenForMissedClasses(uid)
	loader.fetchAndListenForFreeClasses(uid)
}

@Component({
	components: {
		AddMissedClassModal,
		AddMakeupClassSingleModal,
		AddMakeupClassMultipleModal,
		AddSpecialMakeupClassModal,
		AddCreditsModal,
		AddFreeClassModal,

		ManageMakeupComponent,
		ManageExtraCreditsComponent,
		ManageFreeClassesComponent
	}
})
export default class ManageCredits extends Mixins(FormatDate, FormatClass, IsPrima)
{
	private dancer: IDancer | null = null
		
	private showDetails:any = {}
	private missedDeletedMessage: { affectedMissedClasses: any[] } = {
		affectedMissedClasses: []
	}
	private isLoading = true

	get uid()
	{
		return this.$route.params.uid
	}

	get missedClasses()
	{
		if (!this.dancer)
		{
			return []
		}

		const list = this.$store.getters.missedClassesForDancer(this.dancer.uid)
		
		const classes = map(list,
			data => {
				data.classData = this.classFromUid(data.missedClass)

				return data
			}
		)

		return classes
	}

	get missedClassesCreditsCount()
	{
		return reduce(this.missedClasses, (sum, obj) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	get unresolvedMissedClasses()
	{
		return filter(
			this.missedClasses,
			obj => obj.credits > obj.creditsUsed
		)
	}

	get extraCredits()
	{
		const list = this.$store.getters['extraCredits/forDancer'](this.uid)

		return list
	}

	get extraCreditsCount()
	{
		return reduce(this.extraCredits, (sum, obj) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	get creditsRemaining()
	{
		return this.missedClassesCreditsCount + this.extraCreditsCount
	}

	get dancerName()
	{
		if (!this.dancer)
		{
			return  '...'
		}

		return this.dancer.firstName + ' ' + this.dancer.lastName
	}

	protected getDancer()
	{
		return this.dancer
	}
	
	mounted()
	{
		const uid = this.uid

		loader.fetchAndListenForMakeups(uid)
		loader.fetchAndListenForExtraCredits(uid)

		if (this.$store.getters.classes.length > 0)
		{
			loadClassDependents(uid)
		}
		else
		{
			loader.once('classes-loaded').then(() => loadClassDependents(uid))
		}

		const d = this.$store.getters.dancer(uid)

		if (d)
		{
			this.dancer = d
			this.isLoading = false
			return
		}

		loader.once('dancers-loaded').then(() => {
			this.dancer = this.$store.getters.dancer(uid)
			this.isLoading = false
		})
	}

	private handleAddMissedClass()
	{
		// @ts-ignore
		this.$refs.addMissedClassModal.show(this.dancer)
	}

	private handleAddMakeupClass(item: any)
	{
		const data = clone(item)
		data.totalCreditsRemaining = this.creditsRemaining

		// @ts-ignore
		this.$refs.addMakeupClassModal.show(this.dancer, data)
	}

	private handleAddMakeupAuto()
	{
		// this will automatically find a class or classes to apply credits to
		// @ts-ignore
		this.$refs.addMakeupClassMultipleModal.show(
			this.dancer, 
			this.creditsRemaining, 
			this.missedClasses,
			this.extraCredits
		)
	}

	private handleAddMakeupSpecial()
	{
		// @ts-ignore
		this.$refs.addSpecialMakeupClassModal.show(
			this.dancer, 
			this.creditsRemaining, 
			this.missedClasses,
			this.extraCredits
		)
	}

	private handleAddCredits()
	{
		// @ts-ignore
		this.$refs.addCreditsModal.show(this.dancer)
	}

	private handleAddFreeClass()
	{
		// @ts-ignore
		this.$refs.addFreeClassModal.show(this.dancer)
	}

	private deleteMissedClass(missed: any)
	{
		this.isLoading = true

		// delete all makeups as well associated with this missed class
		const rets = []
		if (!missed.makeups)
		{
			missed.makeups = []
		}

		const lookup: any = {}
		for (var i = 0; i < missed.makeups.length; ++i)
		{
			const uid = missed.makeups[i]
			const mu = clone(this.$store.getters.makeup(uid))
			
			forEach(keys(mu.makeupClass), uid => {
				if (uid in lookup)
				{
					// this is a dupe so remove it from makeup data
					delete mu.makeupClass[uid]
					return
				}

				lookup[uid] = true
			})

			rets.push(this.deleteMakeup(mu))
		}

		Promise.all(rets).then(affectedCreditables => {
			return new Promise(resolve => {
				db.collection(collections.MissedClasses).doc(this.dancer!.uid)
					.collection('missed').doc(missed.uid)
					.delete()
					.then(() => resolve(affectedCreditables))
			})
		})
		.then((affectedCreditables: any) => {
			this.isLoading = false

			affectedCreditables = flatten(affectedCreditables)

			this.missedDeletedMessage.affectedMissedClasses = map(
				filter(
					affectedCreditables,
					m => (!m.type || m.type === 'mc') && m.uid !== missed.uid
				),
				m => {
					m.missedClass = this.$store.getters.class(m.missedClass)
					return m
				}
			)

			this.$store.commit('removeMissedClass', {
				dancerId: this.dancer!.uid,
				missed: missed
			})

		})
		.catch(err => {
			console.error(err)
		})
	}

	private deleteMakeupClass(makeup: any)
	{
		this.isLoading = true

		const makeupData = makeup.makeup
		
		this.deleteMakeup(makeupData, (uid: string, d: any) => {
			this.showDetails[uid] = d.makeups.length > 0
		})
		.then(() => {
			this.isLoading = false
		})
	}

	private handleShowDetails(item: any)
	{
		if (!item.makeups || item.makeups.length <= 0)
		{
			return
		}

		this.$set(this.showDetails, item.uid, !this.showDetails[item.uid])
	}

	private dataForMakeups(missed: any)
	{
		if (!missed.makeups)
		{
			return []
		}

		return filter(
				map(missed.makeups, uid => {
				// transform to only have data for the makeup for that missed class
				// makeups can be spread across multiple missed classes
				const makeup = clone(this.$store.getters.makeup(uid))

				if (!makeup)
				{
					return null
				}

				var missedKey = missed.uid
				if (!(missedKey in makeup.credits))
				{
					missedKey = makeupKeyForMissedClass(missed)
				}

				const ret = {
					special: makeup.type === 'special',
					makeup: makeup,
					credits: makeup.credits[missedKey],
					extraCredits: extraCreditsApplied(makeup.credits),
					classData: {}
				}	

				if (!ret.special)
				{
					ret.classData = this.$store.getters.class(makeup.makeupClass[missedKey])
				}

				return ret
			}),
			obj => obj
		)
	}

	private deleteMakeup(makeupData: any, onTransaction?: Function)
	{
		if (!this.dancer)
		{
			return Promise.resolve()
		}

		return deleteMakeup(makeupData, this.dancer.uid, onTransaction)
			.catch((err: Error) => {
				console.error(err)
			})
	}

	private getCellStyle(row: any, noStrike: boolean = false)
	{
		return { 
			strike: row.creditsUsed >= row.credits && !noStrike
		}
	}
}
