var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "box", staticStyle: { "min-height": "600px" } },
        [
          _c("h1", { staticClass: "title" }, [_vm._v("Add Missed Class")]),
          _c("form", { on: { submit: _vm.handleSubmit } }, [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Missed Class")]),
              _c("div", { staticClass: "control" }, [
                _vm.dancerClasses.length > 0
                  ? _c(
                      "div",
                      [
                        _vm.missed.missedClass
                          ? _c("div", { staticClass: "tags has-addons" }, [
                              _c("span", { staticClass: "tag is-medium" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatClass(_vm.missed.missedClass)
                                  )
                                )
                              ]),
                              _c(
                                "span",
                                { staticClass: "tag is-medium is-info" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.teacherNameFromUid(
                                        _vm.missed.missedClass.instructor
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("a", {
                                staticClass: "tag is-medium is-delete",
                                on: { click: _vm.removeSelectedClass }
                              })
                            ])
                          : _vm._e(),
                        _c("class-autocomplete", {
                          attrs: { dancerId: _vm.dancer.uid },
                          on: { classSelected: _vm.handleClassSelected }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "content has-text-grey" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dancer.firstName) +
                          " is not registered for any classes. "
                      )
                    ])
              ])
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Date of Missed Class")
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Select a date...",
                      icon: "calendar-alt"
                    },
                    model: {
                      value: _vm.missed.missedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.missed, "missedDate", $$v)
                      },
                      expression: "missed.missedDate"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    class: { "is-loading": _vm.isLoading },
                    attrs: { disabled: !_vm.missed.missedClass }
                  },
                  [_vm._v("Add")]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }