var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "columns is-mobile is-gapless" }, [
      _c(
        "div",
        { staticClass: "column is-narrow", staticStyle: { width: "200px" } },
        [_c("navigation")],
        1
      ),
      _c(
        "div",
        { staticClass: "column", staticStyle: { "overflow-x": "auto" } },
        [_c("router-view")],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }