var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "min-height": "500px" } }, [
    _c(
      "section",
      { staticClass: "section" },
      [
        _c("div", { staticClass: "container" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Start Date...",
                      icon: "calendar-alt",
                      editable: ""
                    },
                    model: {
                      value: _vm.dateRange.start,
                      callback: function($$v) {
                        _vm.$set(_vm.dateRange, "start", $$v)
                      },
                      expression: "dateRange.start"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "level-item" }, [_vm._v(" to ")]),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "End Date...",
                      icon: "calendar-alt",
                      editable: ""
                    },
                    model: {
                      value: _vm.dateRange.end,
                      callback: function($$v) {
                        _vm.$set(_vm.dateRange, "end", $$v)
                      },
                      expression: "dateRange.end"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "table",
            {
              staticClass:
                "table is-striped is-hoverable is-fullwidth is-bordered"
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("th", { staticStyle: { width: "150px" } }, [
                      _vm._v("Date")
                    ]),
                    _vm._l(_vm.teachers, function(instructor) {
                      return _c("th", { key: instructor.uid }, [
                        _vm._v(" " + _vm._s(instructor.name) + " ")
                      ])
                    })
                  ],
                  2
                )
              ]),
              _c(
                "tbody",
                _vm._l(_vm.dataForRange, function(data, key) {
                  return _c(
                    "tr",
                    { key: key },
                    [
                      _c("td", [
                        _vm._v(" " + _vm._s(key) + " "),
                        !data.summary
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "button is-small is-pulled-right",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleCalculatePayrollForDate(
                                        key
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: "Calculate*",
                                        position: "is-left"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon is-small" },
                                        [_c("i", { staticClass: "fas fa-cog" })]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._l(_vm.teachers, function(instructor) {
                        return _c("td", { key: instructor.uid }, [
                          data.summary && data.summary[instructor.uid]
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(data.summary[instructor.uid].hours) +
                                    " "
                                )
                              ])
                            : _c("span", [_vm._v(" 0 ")])
                        ])
                      })
                    ],
                    2
                  )
                }),
                0
              ),
              _c("tfoot", [
                _c(
                  "tr",
                  [
                    _c("th", [_vm._v(" Totals ")]),
                    _vm._l(_vm.teachers, function(instructor) {
                      return _c("th", { key: instructor.uid }, [
                        _vm.summedHoursForRange[instructor.uid]
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.summedHoursForRange[instructor.uid]
                                  ) +
                                  " "
                              )
                            ])
                          : _c("span", [_vm._v(" 0 ")])
                      ])
                    })
                  ],
                  2
                )
              ])
            ]
          )
        ]),
        _c("b-loading", {
          attrs: {
            "is-full-page": false,
            active: _vm.isLoading,
            "can-cancel": false
          },
          on: {
            "update:active": function($event) {
              _vm.isLoading = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-item" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Payroll Data")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }