



































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import cloneDeep from 'lodash/cloneDeep'
import nth from 'lodash/nth'
import map from 'lodash/map'
import differenceWith from 'lodash/differenceWith'
import forEach from 'lodash/forEach'

import HorizontalInput from '@/components/inputs/HorizontalInput.vue'
import HorizontalSelect from '@/components/inputs/HorizontalSelect.vue'
import ClassesDisplay from '@/components/classes/ClassesDisplay.vue'
import DancerEvents from './DancerEvents.vue'

import {
	firestore as db,
	collections
} from '@/firebase'

import {
	dancerStatuses,
	DancerStatusTypes,
	eventActionTypes as ActionTypes,
	dancerBillingTypes
} from '@/utils'

import FormatDate from '@/mixins/FormatDate'
import ClassesForDancer from '@/mixins/ClassesForDancer'

const template = {
	statuses: dancerStatuses(),
	billingTypes: dancerBillingTypes()
}

const defaultDancer = () => {
	return {
		uid: '',
		firstName: '',
		lastName: '',
		email: '',
		status: nth(template.statuses, 1)!,
		billingType: nth(template.billingTypes, 0)!,
		dateOfFirstClass: null
	}
}

@Component({
	components: {
		HorizontalInput,
		HorizontalSelect,
		ClassesDisplay,
		DancerEvents
	}
})
export default class EditDancerComponent extends Mixins(ClassesForDancer, FormatDate)
{
	private template = template
	private dancer: IDancer = defaultDancer()
	private active = false
	private state = 'idle'
	private initialClassesSet = false

	get dancerIsArchived()
	{
		return this.dancer.status === DancerStatusTypes.Archived
	}
	
	get dancerHasClasses()
	{
		return this.dancerClasses.length > 0
	}
	
	get dancerHasClassesOrUpcoming()
	{
		return this.classesForDancer(this.dancer.uid).length > 0
	}
	
	get dancerClasses()
	{
		return map(
			this.$store.getters.classesForDancer(this.dancer.uid),
			uid => this.$store.getters.class(uid)
		)
	}

	get dancerIsPrima()
	{
		if (!this.dancer.tags)
		{
			return false
		}

		const val = this.dancer.tags.find(tag => tag.toLowerCase() === 'prima')
		return !!val
	}

	@Watch('dancerClasses')
	private onDancerClassesChanged()
	{
		if (this.dancerIsArchived)
		{
			return
		}
		this.updateDancerStatus()
	}

	private handleIsPrimaChanged(isPrima: boolean)
	{
		if (!this.dancer.tags)
		{
			this.dancer.tags = []
		}

		const idx = this.dancer.tags.findIndex(tag => tag.toLowerCase() === 'prima')

		if (!isPrima)
		{
			if (idx >= 0)
			{
				this.dancer.tags.splice(idx, 1)
			}

			return
		}

		if (idx >= 0)
		{
			return
		}

		this.dancer.tags.push('prima')
	}

	private handleSubmit(evt: MouseEvent)
	{
		evt.preventDefault()

		this.state = 'working'

		const data = cloneDeep(this.dancer)

		delete data.uid

		if ('classes' in data)
		{
			// NOTE: this might be legacy; see below as well
			// @ts-ignore
			delete data.classes
		}

		const dancerUid = this.dancer.uid

		db.collection(collections.Dancers).doc(dancerUid).update(data).then(() => {

			const originalClasses = this.$store.getters.classesForDancer(dancerUid)

			const removedClasses = differenceWith(originalClasses, this.dancerClasses, (value, other) => value === other.uid)

			return db.collection(collections.DancersToClasses).doc(dancerUid).set({
				classes: map(this.dancerClasses, obj => obj.uid)
			}, { merge: true }).then(() => {
				forEach(removedClasses, uid => {
					this.$store.commit('removeClassFromDancer', {
						dancerId: dancerUid,
						classId: uid
					})
				})
			})
		})
		.then(() => {
			this.$emit('done', this.dancer)
			this.done(true)
		})
		.catch((err: Error) => {
			this.$emit('error', err)

			this.state = 'idle'

			throw err
		})
	}

	private handleArchive(evt: MouseEvent)
	{
		evt.preventDefault()

		this.state = 'working'

		const data = cloneDeep(this.dancer)
		delete data.uid

		if ('classes' in data)
		{
			// NOTE: this might be legacy
			// @ts-ignore
			delete data.classes
		}

		if (!data.dateOfFirstClass && 'dateOfFirstClass' in data)
		{
			delete data.dateOfFirstClass
		}

		data.status = DancerStatusTypes.Archived

		db.collection(collections.ArchivedDancers).doc(this.dancer.uid).set(data).then(() => {
			return db.collection(collections.Dancers).doc(this.dancer.uid).delete()
		})
		.then(() => {
			this.$store.commit('removeDancer', this.dancer)

			this.$emit('archived', this.dancer)
			this.done()

			this.state = 'idle'
		})
	}

	private handleRestore(evt: MouseEvent)
	{
		evt.preventDefault()

		this.state = 'working'

		const data = cloneDeep(this.dancer)
		delete data.uid

		if ('classes' in data)
		{
			// NOTE: this might be legacy
			// @ts-ignore
			delete data.classes
		}

		if (!data.dateOfFirstClass)
		{
			delete data.dateOfFirstClass
		}

		data.status = DancerStatusTypes.Paused

		db.collection(collections.Dancers).doc(this.dancer.uid).set(data).then(() => {
			return db.collection(collections.ArchivedDancers).doc(this.dancer.uid).delete()
		})
		.then(() => {
			this.$emit('restored', this.dancer)
			this.done()

			this.state = 'idle'
		})
	}

	private handlePaymentLink()
	{
		this.$copyText(`https://app.brocheballet.com/payments/update/${this.dancer.uid}`)
		this.$buefy.toast.open({
			message: 'Payment link copied to clipboard!',
			type: 'is-success'
		})
	}

	setDancer(data: IDancer)
	{
		if (!data)
		{
			throw new Error('Must pass in a dancer to be updated.')
		}

		this.dancer = cloneDeep(data)

		if (!this.dancer.billingType)
		{
			this.dancer.billingType = nth(this.template.billingTypes, 0)!
		}

		this.active = true
	}

	setInitialClasses()
	{
		if (this.initialClassesSet)
		{
			return
		}

		// @ts-ignore
		if (!this.$refs.classesDisplay || !this.$refs.classesDisplay.setInitalClasses)
		{
			return
		}

		if (!this.dancerClasses)
		{
			return
		}

		this.initialClassesSet = true

		// @ts-ignore
		this.$refs.classesDisplay.setInitalClasses(this.dancerClasses)
	}

	private handleEventAdded(event: any)
	{
		this.updateDancerStatus()

		if (this.dancer.dateOfFirstClass)
		{
			return
		}

		if (event.action !== ActionTypes.JoinClass)
		{
			return
		}
		
		this.$set(this.dancer, 'dateOfFirstClass', event.effectiveDate)

		// TODO: this should happen from a cloud function trigger when event is added
		db.collection(collections.Dancers).doc(this.dancer.uid).update({
			dateOfFirstClass: this.dancer.dateOfFirstClass
		})
	}

	private handleEventDeleted()
	{
		this.updateDancerStatus()
	}

	private handleEventsChanged()
	{
		// @ts-ignore
		this.$refs.classesDisplay.updateClasses(this.dancerClasses)

		this.updateDancerStatus()
	}

	private updateDancerStatus()
	{
		const prevStatus = this.dancer.status

		// this includes all classes joining as well
		const list = this.classesForDancer(this.dancer)
		
		this.dancer.status = list.length <= 0 ? template.statuses[1] : template.statuses[0]

		if (prevStatus !== this.dancer.status)
		{
			// update status if changed
			db.collection(collections.Dancers).doc(this.dancer.uid).update({
				status: this.dancer.status
			})
		}
	}

	private done(keepOpen = false)
	{
		if (!keepOpen)
		{
			this.active = false
			this.initialClassesSet = false

			this.dancer = defaultDancer()
		}

		this.state = 'idle'
	}
}
