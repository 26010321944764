import { releves } from '@/database'
import { functions } from '@/firebase'

interface PledgeItem
{
	name: string
	email: string
	stripeId: string
	pledge: number
	cap: number
	bonus: number
	total?: number
	charged?: boolean
}

interface ReleveathonDetails
{
	count: number
}

const addPledge = async (pledge: PledgeItem) => {
	await releves.doc(pledge.email).set(pledge)

	return pledge
}

const updatePledge = async (pledge: PledgeItem) => {
	await releves.doc(pledge.email).update(pledge)

	return pledge
}

const setReleveCount = async (count: number) => {
	await releves.doc('_details').set({ count }, { merge: true })

	return { count }
}

const getReleveCount = async () => {
	const snap = await releves.doc('_details').get()
	if (!snap.exists)
	{
		return { count: 0 }
	}

	return snap.data()! as ReleveathonDetails
}

const chargePledge = async (pledge: PledgeItem, releveCount: number) => {
	const charge = functions.httpsCallable('chargeRelevePledge')
	
	const res = await charge({
		pledge,
		releveCount
	})

	return res
}

export {
	addPledge,
	updatePledge,

	getReleveCount,
	setReleveCount,
	
	chargePledge
}
