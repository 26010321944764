import { Component, Mixins } from 'vue-property-decorator'

import store from '@/store'

import NameFromUid from '@/mixins/NameFromUid'

import { registrationFormat, makeupFormat } from '@/utils/classFormats'

@Component
export default class FormatClass extends Mixins(NameFromUid)
{
	protected formatClass(item: IStudioClass)
	{
		const parts = [
			item.dayOfWeek,
			item.startTime.replace(' ', ''),
			this.studioNameFromUid(item.studio),
			this.classTypeFromUid(item.classType),
			this.classLevelFromUid(item.classLevel)
		]
		return parts.join(' ')
	}

	protected formatClassNoStudio(item: IStudioClass)
	{
		const parts = [
			item.dayOfWeek,
			item.startTime.replace(' ', ''),
			this.classTypeFromUid(item.classType),
			this.classLevelFromUid(item.classLevel)
		]
		return parts.join(' ')
	}

	protected registrationFormat(item: IStudioClass)
	{
		return registrationFormat(item)
	}

	protected makeupFormat(item: IStudioClass)
	{
		return makeupFormat(item)
	}

	protected classFromUid(uid: string)
	{
		return store.getters.class(uid)
	}
}
