var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "field is-horizontal" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "field-body" },
        [
          _c("b-field", [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.events.length > 0,
                        expression: "events.length > 0"
                      }
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "columns is-multiline" },
                      _vm._l(_vm.events, function(item) {
                        return _c(
                          "div",
                          { key: item.uid, staticClass: "column is-half" },
                          [
                            _c("div", { staticClass: "card" }, [
                              _c("div", { staticClass: "card-content" }, [
                                _c("div", { staticClass: "media" }, [
                                  _c(
                                    "div",
                                    { staticClass: "media-left" },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: _vm.iconForAction(item.action),
                                          size: "is-large",
                                          type: _vm.iconTypeForAction(
                                            item.action
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "media-content" }, [
                                    _c("p", { staticClass: "title is-4" }, [
                                      _vm._v(_vm._s(item.action))
                                    ]),
                                    _c("p", { staticClass: "subtitle is-6" }, [
                                      _vm._v(
                                        "on " +
                                          _vm._s(
                                            _vm.formatDate(item.effectiveDate)
                                          )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "media-right" },
                                    [
                                      !_vm.eventBeingExecuted(item)
                                        ? _c("a", {
                                            staticClass: "delete",
                                            on: {
                                              click: function($event) {
                                                return _vm.handleDeleteEvent(
                                                  item
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.eventBeingExecuted(item)
                                        ? _c("b-icon", {
                                            attrs: {
                                              pack: "fas",
                                              icon: "sync",
                                              "custom-class": "fa-spin"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm.isClassEvent(item.action) && item.class
                                  ? _c("div", { staticClass: "content" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.classTypeFromUid(
                                              item.classData.classType
                                            )
                                          ) +
                                            " - Level " +
                                            _vm._s(
                                              _vm.classLevelFromUid(
                                                item.classData.classLevel
                                              )
                                            )
                                        )
                                      ]),
                                      _c("br"),
                                      _vm._v(
                                        " at " +
                                          _vm._s(
                                            _vm.studioNameFromUid(
                                              item.classData.studio
                                            )
                                          ) +
                                          " with " +
                                          _vm._s(
                                            _vm.teacherNameFromUid(
                                              item.classData.instructor
                                            )
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(item.classData.dayOfWeek) +
                                          "s at " +
                                          _vm._s(item.classData.startTime) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.events.length === 0,
                        expression: "events.length === 0"
                      }
                    ],
                    staticClass: "content has-text-grey"
                  },
                  [_c("p", [_vm._v("This dancer has no events...")])]
                )
              ])
            ])
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "field is-horizontal" }, [
      _c("div", { staticClass: "field-label" }),
      _c(
        "div",
        { staticClass: "field-body" },
        [
          _c("b-field", [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-one-quarter" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Action" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: { placeholder: "Select an action" },
                          model: {
                            value: _vm.event.action,
                            callback: function($$v) {
                              _vm.$set(_vm.event, "action", $$v)
                            },
                            expression: "event.action"
                          }
                        },
                        _vm._l(_vm.template.actions, function(option) {
                          return _c(
                            "option",
                            { key: option, domProps: { value: option } },
                            [_vm._v(" " + _vm._s(option) + " ")]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "column" }, [
                _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showClassEventParams,
                        expression: "showClassEventParams"
                      }
                    ]
                  },
                  [
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [_vm._v("Class")]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _vm.selectedClass
                            ? _c("div", { staticClass: "tags has-addons" }, [
                                _c("span", { staticClass: "tag is-medium" }, [
                                  _vm._v(
                                    _vm._s(_vm.formatClass(_vm.selectedClass))
                                  )
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "tag is-medium is-info" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.teacherNameFromUid(
                                          _vm.selectedClass.instructor
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("a", {
                                  staticClass: "tag is-medium is-delete",
                                  on: { click: _vm.removeSelectedClass }
                                })
                              ])
                            : _vm._e(),
                          _c("class-autocomplete", {
                            attrs: {
                              exclude: _vm.excludeClasses,
                              useClasses: _vm.selectableClasses,
                              excludeFull: !_vm.selectableClasses
                            },
                            on: { classSelected: _vm.handleClassSelected }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("br"),
                    _c(
                      "b-field",
                      { attrs: { label: "Effective Date" } },
                      [
                        _c("b-datepicker", {
                          attrs: {
                            placeholder: "Select date...",
                            icon: "calendar-alt",
                            position: "is-top-right"
                          },
                          model: {
                            value: _vm.event.effectiveDate,
                            callback: function($$v) {
                              _vm.$set(_vm.event, "effectiveDate", $$v)
                            },
                            expression: "event.effectiveDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMembershipStatusParams,
                        expression: "showMembershipStatusParams"
                      }
                    ]
                  },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "Effective Date" } },
                      [
                        _c("b-datepicker", {
                          attrs: {
                            placeholder: "Select date...",
                            icon: "calendar-alt",
                            position: "is-top-right"
                          },
                          model: {
                            value: _vm.event.effectiveDate,
                            callback: function($$v) {
                              _vm.$set(_vm.event, "effectiveDate", $$v)
                            },
                            expression: "event.effectiveDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "button",
                  {
                    staticClass: "button is-fullwidth is-primary",
                    class: _vm.addEventState === "working" ? "is-loading" : "",
                    attrs: {
                      disabled: !_vm.event.effectiveDate || !_vm.event.class
                    },
                    on: { click: _vm.handleAddEvent }
                  },
                  [_vm._v(" Add Event ")]
                )
              ])
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label is-normal" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Events")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }