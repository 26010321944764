var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixed has-background-dark",
      staticStyle: { width: "200px", padding: "10px", height: "100%" }
    },
    [
      _vm._m(0),
      _vm.isLoggedIn
        ? _c("aside", { staticClass: "menu" }, [
            _c("p", { staticClass: "menu-label has-text-light" }, [
              _vm._v(" General ")
            ]),
            _c("ul", { staticClass: "menu-list menu-link" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/dashboard")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "tachometer-alt" }
                    }),
                    _vm._v(" Dashboard ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/schedule")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "calendar", pack: "far" }
                    }),
                    _vm._v(" Schedule ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/classes")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "list-ul" }
                    }),
                    _vm._v(" Classes ")
                  ],
                  1
                )
              ]),
              _c(
                "li",
                [
                  _c("div", { staticClass: "is-clearfix" }, [
                    _c(
                      "a",
                      {
                        staticClass: "is-pulled-left",
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/dancers")
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          staticClass: "align-icon",
                          attrs: { icon: "user" }
                        }),
                        _vm._v(" Dancers ")
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button is-dark is-pulled-right align-dropdown-icon",
                        on: {
                          click: function($event) {
                            _vm.expandMenu.dancers = !_vm.expandMenu.dancers
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { type: "is-white", icon: "caret-down" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-collapse",
                    {
                      attrs: { open: _vm.expandMenu.dancers },
                      on: {
                        "update:open": function($event) {
                          return _vm.$set(_vm.expandMenu, "dancers", $event)
                        }
                      }
                    },
                    [
                      _c("ul", [
                        _c("li", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/dancers/archived")
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                staticClass: "align-icon",
                                attrs: { icon: "user-times" }
                              }),
                              _vm._v("Archived ")
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ]),
            _c("p", { staticClass: "menu-label has-text-light" }, [
              _vm._v(" Administration ")
            ]),
            _c("ul", { staticClass: "menu-list menu-link" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/dancers/add")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "plus-circle" }
                    }),
                    _vm._v("Add Dancer ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/classes/add")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "plus-circle" }
                    }),
                    _vm._v("Add Class ")
                  ],
                  1
                )
              ]),
              _c("hr", { staticClass: "has-background-grey horizontal-rule" }),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/teachers")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "chalkboard-teacher" }
                    }),
                    _vm._v("Teachers ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/substitutes")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "chalkboard" }
                    }),
                    _vm._v("Substitutes ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/studios")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "store-alt" }
                    }),
                    _vm._v("Studios ")
                  ],
                  1
                )
              ]),
              _c("hr", { staticClass: "has-background-grey horizontal-rule" }),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/closures")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { pack: "far", icon: "calendar" }
                    }),
                    _vm._v("Closures ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/billing")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "dollar-sign" }
                    }),
                    _vm._v("Billing ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/billing/releve")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "dollar-sign" }
                    }),
                    _vm._v("Releve-a-thon ")
                  ],
                  1
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/payroll")
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "clock" }
                    }),
                    _vm._v("Payroll ")
                  ],
                  1
                )
              ])
            ]),
            _c("hr", { staticClass: "has-background-grey horizontal-rule" }),
            _c("ul", { staticClass: "menu-list menu-link" }, [
              _c("li", [
                _c(
                  "a",
                  { on: { click: _vm.handleLogout } },
                  [
                    _c("b-icon", {
                      staticClass: "align-icon",
                      attrs: { icon: "sign-out-alt" }
                    }),
                    _vm._v("Logout ")
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "10px", "margin-bottom": "10px" } },
      [_c("img", { attrs: { src: require("../assets/logo.png") } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }