<template>
<div style="min-height: 500px">
<section class="section">
<div class="container">
	<nav class="level">
		<!-- Left side -->
		<div class="level-left">
			<div class="level-item">
				<h1 class="title">Payroll Data</h1>
			</div>
			<div class="level-item">
				<b-datepicker
					placeholder="Start Date..."
					icon="calendar-alt"
					v-model="dateRange.start"
					editable>
				</b-datepicker>
			</div>
			<div class="level-item">
				to
			</div>
			<div class="level-item">
				<b-datepicker
					placeholder="End Date..."
					icon="calendar-alt"
					v-model="dateRange.end"
					editable>
				</b-datepicker>
			</div>
		</div>
	</nav>

	<table class="table is-striped is-hoverable is-fullwidth is-bordered">
		<thead>
			<tr>
				<th style="width: 150px">Date</th>
				<th v-for="instructor in teachers" :key="instructor.uid">
					{{ instructor.name }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(data, key) in dataForRange" :key="key">
				<td>
					{{ key }}
					<span v-if="!data.summary">
						<a @click="handleCalculatePayrollForDate(key)" class="button is-small is-pulled-right">
							<b-tooltip label="Calculate*" position="is-left">
								<span class="icon is-small">
									<i class="fas fa-cog"></i>
								</span>
							</b-tooltip>
						</a>
					</span>
				</td>
				<td v-for="instructor in teachers" :key="instructor.uid">
					<span v-if="data.summary && data.summary[instructor.uid]">
						{{ data.summary[instructor.uid].hours }}
					</span>
					<span v-else>
						0
					</span>
				</td>
			</tr>
		</tbody>
		<tfoot>
			<tr>
				<th>
					Totals
				</th>
				<th v-for="instructor in teachers" :key="instructor.uid">
					<span v-if="summedHoursForRange[instructor.uid]">
						{{ summedHoursForRange[instructor.uid] }}
					</span>
					<span v-else>
						0
					</span>
				</th>
			</tr>
		</tfoot>
	</table>
</div>
<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</section>
</div>
</template>

<script>
// import loader from '@/dataLoader'
import moment from 'moment'

import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'

import { payroll } from '@/database'

import { functions } from '@/firebase'

const today = moment().startOf('day')

const data = function () {
	return {
		dateRange: {
			start: moment(today).subtract(2, 'weeks').toDate(),
			end: today.toDate()
		},

		isLoading: false,

		dataForRange: {},
		summedHoursForRange: {}
	}
}

const mounted = function () {
	this.getDataForRange()
}

const watch = {
	dateRange: {
		handler: function () {
			this.getDataForRange()
		},
		deep: true
	}
}

const computed = {
	teachers: function () {
		return sortBy(
			filter(this.$store.getters.teachers, t => t.name !== 'TBD'),
			'name'
		)
	}
}

const methods = {
	getDataForRange: async function () {
		const newDataForRange = {}
		const newSummedHours = {}

		const start = moment(this.dateRange.start)
		const end = moment(this.dateRange.end)

		const duration = moment.duration(end.diff(start))
		const days = duration.asDays()

		const begin = moment(start)

		for (let i = 0; i <= days; ++i)
		{
			const key = begin.format('YYYY-MM-DD')
			newDataForRange[key] = {}
			begin.add(1, 'day')
		}

		const query = payroll
			.where('date', '>=', this.dateRange.start)
			.where('date', '<=', this.dateRange.end)

		const snap = await query.get()

		forEach(snap.docs, doc => {
			const data = doc.data()
			newDataForRange[doc.id] = data

			forEach(data.summary, (value, instructorId) => {
				if (!(instructorId in newSummedHours))
				{
					newSummedHours[instructorId] = 0
				}

				newSummedHours[instructorId] += value.hours
			})
		})

		this.dataForRange = newDataForRange
		this.summedHoursForRange = newSummedHours
	},

	/**
	 * This may not always be correct data; normally data is calculated
	 * on the day of so that any schedule changes in the future do not affect
	 * the data
	 * @param {string} dateString
	 */
	handleCalculatePayrollForDate: function (dateString) {
		const calculatePayrollForDate = functions.httpsCallable('calculatePayrollForDate')

		this.isLoading = true
		return calculatePayrollForDate({
			date: moment(dateString).format()
		})
		.then(() => {
			return this.getDataForRange()
		})
		.then(() => {
			this.isLoading = false
		})
		.catch(err => {
			console.error(err)
		})
	}
}

export default {
	data,
	mounted,
	watch,
	methods,
	computed,
	components: {
	}
}
</script>

<style scoped>

</style>