import { Component, Vue } from 'vue-property-decorator'

import filter from 'lodash/filter'

import store from '@/store'

import { ClassLevelTypes } from '@/utils/classLevels'
import * as ClassTypes from '@/utils/classTypes'

import isObjectLike from 'lodash/isObjectLike'

@Component
export default class ClassLevels extends Vue
{
	protected classLevelsForClassType(classTypeObject: IStudioClassType | string)
	{
		let classType = classTypeObject
		if (isObjectLike(classTypeObject))
		{
			classType = (classTypeObject as IStudioClassType).name
		}

		return filter(store.getters.classLevels, lvl => {
			switch (classType) {
				case ClassTypes.Pointe:
					return lvl.levelType === ClassLevelTypes.Pointe

				case ClassTypes.Stretch:
				case ClassTypes.Prepointe:
					return lvl.levelType === ClassLevelTypes.Open

				case ClassTypes.BalletTechnique:
				case ClassTypes.LowImpact:
				case ClassTypes.JumpsAndTurns:
				case ClassTypes.TeenTechnique:
					return lvl.levelType === ClassLevelTypes.Ballet

				default:
					if (!(classTypeObject as IStudioClassType).levelType)
					{
						return lvl.levelType === ClassLevelTypes.Ballet
					}
					return lvl.levelType === (classTypeObject as IStudioClassType).levelType
			}
		})
	}
}
