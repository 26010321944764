import { Component, Vue } from 'vue-property-decorator'

import concat from 'lodash/concat'
import map from 'lodash/map'
import forEach from 'lodash/forEach'

import loader from '@/dataLoader'

import generateSelectableDates from '@/utils/generateSelectableDates'

const AppointmentGenerator = require('studio-shared/appointments')

import store from '@/store'

const appointments = new AppointmentGenerator({
	getClass: (uid: string) => store.getters.class(uid),
	missedClassesForDate: (date: Date) => store.getters['missedClassesByDate/forDate'](date),
	makeupsForDate: (date: Date) => store.getters['makeupsByDate/forDate'](date),
	freeClassesForDate: (date: Date) => store.getters['freeClassesByDate/forDate'](date),

	dancersForClass: (uid: string) => store.getters.dancersForClass(uid),
	joinsForClass: (uid: string) => store.getters['eventsByClass/joinsForClass'](uid),
	leavesForClass: (uid: string) => store.getters['eventsByClass/leavesForClass'](uid)
})

@Component
export default class ScheduleClass extends Vue
{
	protected selectableDates: any[] = []
	protected selectableClasses: any[] = []
	protected selectedAppointment: any = null

	get dancerUid()
	{
		return ''
	}

	get selectedClassUid()
	{
		return ''
	}

	get selectedClassDate()
	{
		return ''
	}

	get ignoreAlreadyScheduled()
	{
		return false
	}

	get makeups()
	{
		return this.$store.getters.makeupsForDancer(this.dancerUid)
	}

	get freeClasses()
	{
		if (!this.dancerUid)
		{
			return []
		}
		return this.$store.getters['freeClasses/forDancer'](this.dancerUid)
	}

	get alreadyScheduledClass()
	{
		if (this.ignoreAlreadyScheduled)
		{
			return false
		}

		if (!this.selectedClassUid) 
		{
			return false
		}

		const classUid = this.selectedClassUid
		const classDate = this.selectedClassDate

		return this.alreadyScheduledForClassAndDate(classUid, classDate)
	}

	get readyToSchedule()
	{
		if (!this.selectedAppointment) 
		{
			return false
		}

		return !this.selectedAppointment.conflict && this.selectedAppointment.makeupSpaceLeft > 0
	}

	
	protected alreadyScheduledForClassAndDate(classUid: string, classDate: string)
	{
		return appointments.alreadyScheduledForClassAndDate(classUid, classDate, {
			makeups: this.makeups,
			freeClasses: this.freeClasses
		})
	}

	protected generateClassDataForDate(classData: IStudioClass, date: Date)
	{
		return appointments.generateClassDataForDate(classData, date, this.dancerUid, {
			makeups: this.makeups,
			freeClasses: this.freeClasses
		})
	}

	protected generateAppointments(classData: IStudioClass)
	{
		return Promise.all(map(this.selectableDates, date => {
			return Promise.all([
				loader.fetchAndListenForMissedClassesByDate(date),
				loader.fetchAndListenForMakeupsByDate(date),
				loader.fetchAndListenForFreeClassesByDate(date),
				loader.fetchAndListenForDancerEventsByClass(classData.uid)
			])
		}))
		.then(() => {
			this.selectableClasses = []

			forEach(this.selectableDates, date => {
				this.selectableClasses.push(this.generateClassDataForDate(classData, date))
			})

			this.selectableClasses.push({
				loadMore: true
			})
		})
	}

	protected loadMoreAppointments(classData: IStudioClass, count = 9)
	{
		const newDates = generateSelectableDates(classData, count, this.selectableDates.length)
		const loadMore = this.selectableClasses.pop()
		forEach(newDates, date => {
			this.selectableClasses.push(this.generateClassDataForDate(classData, date))
		})

		this.selectableClasses.push(loadMore)
		this.selectableDates = concat(this.selectableDates, newDates)
	}
}
