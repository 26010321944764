var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wide-modal",
    {
      attrs: { visible: _vm.active },
      on: {
        "update:visible": function($event) {
          _vm.active = $event
        }
      }
    },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(" Edit Class "),
        _c(
          "a",
          {
            staticClass: "has-text-black-ter",
            on: {
              click: function($event) {
                return _vm.$copyText(_vm.classData.uid)
              }
            }
          },
          [
            _c(
              "b-tooltip",
              { attrs: { label: "Copy UID", position: "is-bottom" } },
              [
                _c("b-icon", {
                  attrs: { pack: "fas", icon: "copy", size: "is-large" }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("b-notification", { attrs: { closable: false } }, [
        _vm._v(" If "),
        _c("b", [_vm._v("day of week")]),
        _vm._v(", "),
        _c("b", [_vm._v("start time")]),
        _vm._v(", or "),
        _c("b", [_vm._v("class type")]),
        _vm._v(
          " needs to be changed then discontinue this class and create a new one. "
        )
      ]),
      _c(
        "form",
        { on: { submit: _vm.handleSubmit } },
        [
          _c("class-information", {
            ref: "classInfo",
            attrs: { classData: _vm.classData, mode: "edit" },
            on: { "on-discontinue": _vm.handleOnDiscontinue }
          }),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column" }),
            _c("div", { staticClass: "column" }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary is-fullwidth",
                  class: _vm.state === "working" ? "is-loading" : "",
                  attrs: { type: "submit", disabled: !_vm.submitButtonActive }
                },
                [_vm._v(" Update ")]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }