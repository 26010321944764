





















































import { Component, Prop, Mixins } from 'vue-property-decorator'

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import FormatDate from '@/mixins/FormatDate'

import { removeSubstitute } from '@/database/substitutes'

@Component
export default class SubsByDate extends Mixins(NameFromUid, FormatClass, FormatDate)
{
	@Prop({ default: () => new Date() }) date!: Date
	@Prop({ default: () => [] }) substitutes!: ISubstitute[]

	private isLoading = false

	private getClassName(sub: ISubstitute)
	{
		const data = this.classFromUid(sub.classId)
		return this.formatClass(data)
	}

	private getNormalInstructor(sub: ISubstitute)
	{
		const data = this.classFromUid(sub.classId)
		return this.teacherNameFromUid(data.instructor)
	}

	private async handleDelete(sub: ISubstitute)
	{
		this.isLoading = true

		await removeSubstitute(sub)

		this.isLoading = false
	}
}
