<template>
<b-modal :active.sync="active" :width="960" @close="handleClose">
	<div class="box" style="min-height: 650px">
		<slot></slot>
	</div>
</b-modal>
</template>

<script>

export default {
	props: {
		visible: Boolean
	},
	data: function () {
		return {
			active: false
		}
	},
	mounted: function () {
		
	},
	updated: function () {
		if (!this.visible)
		{
			return
		}

		this.active = this.visible

		const el = document.querySelector('div.animation-content.modal-content')
		if (!el)
		{
			return
		}

		el.style.width = '960px'

		this.$emit('updated')
	},
	watch: {
		visible: function (newVis) {
			this.active = newVis
		}
	},
	methods: {
		handleClose: function () {
			this.active = false
			this.$emit('update:visible', this.active)
			this.$emit('close')
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
