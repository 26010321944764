import sortBy from 'lodash/sortBy'
import isFunction from 'lodash/isFunction'

const Sortable = {
	data: function () {
		return {
			sortState: {},
			lastSortKey: null
		}
	},
	methods: {
		getSortableList: function () {
			return []
		},
		// eslint-disable-next-line
		setSortableList: function (list) {

		},

		handleSort: function (key) {
			if (!(key in this.sortState)) {
				this.sortState[key] = 0
			}

			switch (this.sortState[key]) {
				// ASC
				case 0:
				case -1:
				{
					const sortLogic = isFunction(key) ? key : [key]
					this.setSortableList(sortBy(this.getSortableList(), sortLogic))
					this.sortState[key] = 1
					break
				}

				// DSC
				case 1:
					this.setSortableList(this.getSortableList().reverse())
					this.sortState[key] = -1
					break
			}

			this.lastSortKey = key

		}
	}
}

export default Sortable