var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "container",
        staticStyle: { "margin-top": "50px", "margin-bottom": "50px" }
      },
      [
        _c("div", { staticClass: "box" }, [
          _c("section", [
            _vm.error.message
              ? _c("div", { staticClass: "notification is-danger" }, [
                  _vm._v(" " + _vm._s(_vm.error.message) + " ")
                ])
              : _vm._e(),
            _c(
              "form",
              { on: { submit: _vm.handleLogin } },
              [
                _c(
                  "b-field",
                  { attrs: { label: "Email", type: "", message: "" } },
                  [
                    _c("b-input", {
                      attrs: { type: "email", icon: "envelope" },
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-field",
                  { attrs: { label: "Password" } },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "password",
                        icon: "key",
                        "password-reveal": ""
                      },
                      model: {
                        value: _vm.form.password,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "field is-grouped is-grouped-right" },
                  [
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button is-primary is-large has-text-dark is-pulled-right",
                          class: { "is-loading": _vm.isLoading }
                        },
                        [_vm._v(" Login ")]
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }