<template>
<div>
	<div>
		<button v-if="freeClasses.length > 0" class="button is-dark is-outlined" @click="handleAdd">
			<span class="icon is-small">
				<i class="fas fa-plus"></i>
			</span>
			<span>
				Free Class
			</span>
		</button>
	</div>
	<br>
	<div v-if="freeClasses.length > 0" class="columns is-multiline">
		<div v-for="item in freeClasses" :key="item.uid" class="column is-12">
			<div class="box">
				<div class="columns" :class="determineStyle(item)">
					<div class="column is-3">
						{{ monthDateYearformat(item.classDate) }}						
					</div>
					<div class="column">
						{{ makeupFormat(item.classData) }}
					</div>
					<div class="column is-3">
						<p class="buttons is-pulled-right">
							<button class="button is-danger" @click="handleDelete(item)"
									v-if="!datePassed(item)">
								<b-tooltip label="Delete" type="is-danger">
									<span class="icon is-small">
										<i class="fas fa-times"></i>
									</span>
								</b-tooltip>
							</button>
							<button class="button is-warning" @click="handleArchive(item)"
									v-if="datePassed(item)">
								<span class="icon is-small">
									<i class="fas fa-archive"></i>
								</span>
								<span>
									Archive
								</span>
							</button>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<section class="section" v-if="freeClasses.length === 0">
		<div class="content has-text-grey has-text-centered">
			<p>
				There are no free classes.
			</p>
			<br>
			<button @click="handleAdd" class="button">
				<span class="icon is-small">
					<i class="fas fa-plus"></i>
				</span>
				<span>
					Add One
				</span>
			</button>
		</div>
	</section>
	<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</template>

<script>
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import moment from 'moment'

import FormatDate from '@/mixins/FormatDate'
import FormatClass from '@/mixins/FormatClass'

import {
	firestore as db,
	collections
} from '@/firebase'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

export default {
	props: {
		dancer: {
			type: Object,
			default: defaultDancer()
		}
	},
	mixins: [
		FormatDate,
		FormatClass
	],
	data: function () {
		return {
			isLoading: false
		}
	},
	mounted: function () {
	},
	methods: {
		handleDelete: function (item) {
			this.isLoading = true

			const ref = db.collection(collections.FreeClasses).doc(this.dancer.uid)

			ref.collection('classes').doc(item.uid).delete().then(() => {
				this.isLoading = false
			})
		},
		handleArchive: function (item) {
			this.isLoading = true
			
			const data = {
				classDate: item.classDate.toDate(),
				classUid: item.classUid
			}

			const ref = db.collection(collections.FreeClasses).doc(this.dancer.uid)

			ref.collection('archived').doc(item.uid).set(data).then(() => {
				return ref.collection('classes').doc(item.uid).delete()
			})
			.then(() => {
				this.isLoading = false
			})
		},

		datePassed: function (item) {
			return moment(item.classDate).isBefore(moment(), 'day')	
		},
		determineStyle: function (item) {
			return {
				strike: this.datePassed(item)
			}
		},

		handleAdd: function () {
			this.$emit('add')
		}
	},
	computed: {
		freeClasses: function () {
			if (!this.dancer || !this.dancer.uid)
			{
				return []
			}
			const list = this.$store.getters['freeClasses/forDancer'](this.dancer.uid)

			return sortBy(
				map(list, fc => {
					fc.classData = this.$store.getters.class(fc.classUid)
					return fc
				}),
				'classDate'
			)
			.reverse()
		}
	},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.strike {
	text-decoration: line-through;
}
</style>
