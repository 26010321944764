var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-autocomplete", {
        attrs: {
          placeholder: "find an instructor...",
          "keep-first": false,
          "open-on-focus": true,
          data: _vm.filteredTeachers,
          "clear-on-select": true,
          field: "uid"
        },
        on: { select: _vm.handleSelectedTeacher },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var option = ref.option
              return [_vm._v(" " + _vm._s(option.name) + " ")]
            }
          }
        ]),
        model: {
          value: _vm.searchText,
          callback: function($$v) {
            _vm.searchText = $$v
          },
          expression: "searchText"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }