<template>
<div>
<section class="section">
	<div class="container">
		<nav class="level">
			<!-- Left side -->
			<div class="level-left">
				<div class="level-item">
					<h1 class="title">
						Schedule
					</h1>
				</div>
				<div class="level-item">
					<b-select v-model="period.selected" placeholder="Select a period">
						<option
							v-for="option in period.options"
							:value="option.value"
							:key="option.value">
							{{ option.name }}
						</option>
					</b-select>
				</div>
				<div class="level-item">
					<b-datepicker
						placeholder="Select a date..."
						icon="calendar-alt"
						v-model="startingDate"
						:date-formatter="monthDateYearShortFormat"
						position="is-bottom-left"
						editable>
					</b-datepicker>
				</div>
			</div>

			<div class="level-right">
				
			</div>
		</nav>
		<hr/>
		<div class="columns" style="overflow-x: auto">
			<template v-for="date in periodDates">
			<div class="column" :class="columnSizes" :key="date.toDate().toString()">
				<h1 class="title is-5">{{ date.format('dddd, MMM Do YYYY') }}</h1>
				<div class="box clickable" 
						v-for="appt in appointmentsForPeriodDates[monthDateYearShortFormat(date)]" 
						:key="appt.classData.uid"
						@click="handleSelectAppointment(appt)" 
						:class="{'selected-background': isSelectedAppointment(appt)}">
					{{ classTitle(appt.classData) }}<br>
					<span class="tag" :class="colorForStudio(appt.classData.studio.name)">
						at {{ appt.classData.studio.name }}
					</span>
					with <u>{{ appt.classData.instructor.name }}</u>
					<br>
					Expecting {{ appt.expectedDancers }} {{ pluralize('dancer', appt.expectedDancers) }} <span v-if="appt.expectedMakeups > 0"><i>({{ appt.expectedMakeups }} {{ pluralize('makeup', appt.expectedMakeups) }})</i></span>
				</div>
			</div>
			</template>
		</div>
	</div>
</section>
<side-panel :isOpen="showAppointmentInfo && selectedAppointment !== null" @closeMenu="handleCloseAppointmentInfo" @openMenu="handleOpenAppointmentInfo">
	<div style="padding: 10px; width: 400px" v-if="selectedAppointment">
		<button @click="showAppointmentInfo=false" class="button is-outlined">
			<span class="icon">
				<i class="fas fa-chevron-left"></i>
			</span>
			<span>Back</span>
		</button>
		<br><br>
		<div class="box clickable" @click="handleEditClass(selectedAppointment.classData)">
			<h2 class="title is-5">
				{{ classTitle(selectedAppointment.classData) }}
				<p class="subtitle">
					with {{ selectedAppointment.classData.instructor.name }} on {{ selectedAppointment.classData.dayOfWeek }}s
				</p>
			</h2>
			at {{ selectedAppointment.classData.studio.name }}
		</div>
		<div class="box">
			<div style="margin-bottom: 10px" v-if="selectedAppointment.dancersScheduled.length > 0">
				<strong>Attending ({{ selectedAppointment.expectedDancers }})</strong>
			</div>
			<table class="table is-fullwidth is-hoverable" v-if="selectedAppointment.dancersScheduled.length > 0">
				<tbody>
					<tr v-for="dancer in dancersForSelectedAppointment" :key="dancer.uid">
						<td class="clickable" @click="handleEditDancer(dancer)">
							{{ dancer.firstName }} {{ dancer.lastName }}&nbsp;
							<span v-if="dancerIsMakeup(dancer)" class="tag is-dark">Makeup</span>
							<span v-else-if="dancerIsFreeClass(dancer)" class="tag is-info">Free</span>
						</td>
						<td style="width: 20px">
							<b-tooltip :label="dancerIsFreeClass(dancer) ? 'Remove' : 'Mark Missed'">
								<a class="delete is-medium" @click="handleDancerMissSelectedAppointment(dancer)"></a>
							</b-tooltip>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-else class="content has-text-grey has-text-centered">
				No dancers for this class.
			</div>
		</div>
		<div class="box" v-if="selectedAppointment.dancersMissing.length > 0">
			<strong>Missing</strong>
			<table class="table is-fullwidth is-hoverable">
				<tbody>
					<tr v-for="dancer in missingDancersForSelectedAppointment" :key="dancer.uid">
						<td class="clickable" @click="handleEditDancer(dancer)">
							{{ dancer.firstName }} {{ dancer.lastName }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</side-panel>
<b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
<edit-dancer-modal ref="editDancerModal" style="z-index: 1000" />
<edit-class-modal ref="editClassModal" style="z-index: 1000" />
</div>
</template>

<script>
import { firestore as db, collections } from '@/firebase'

import SidePanel from 'studio-shared/vue/components/SidePanel'

import EditDancerModal from '../dancers/components/EditDancerModal.vue'
import EditClassModal from '../classes/components/EditClassModal.vue'

import moment from 'moment'

import map from 'lodash/map'
import range from 'lodash/range'
import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import find from 'lodash/find'
import head from 'lodash/head'
import values from 'lodash/values'
import remove from 'lodash/remove'

import pluralize from 'pluralize'

import loader from '@/dataLoader'

const AppointmentGenerator = require('studio-shared/appointments')

import missMakeup from '@/utils/missMakeup'

import FormatDate from '@/mixins/FormatDate'

const defaultAppointmentMeta = () => {
	return {
		date: null,
		idx: -1
	}
}

export default {
	mixins: [
		FormatDate
	],
	data: function () {
		return {
			startingDate: moment().toDate(),
			period: {
				options: [
					{
						value: 0,
						name: 'Day'
					},
					{
						value: 1,
						name: 'Week'
					}
				],
				selected: 0
			},
			showAppointmentInfo: false,
			selectedAppointmentMeta: defaultAppointmentMeta(),

			isLoading: false
		}
	},
	mounted: function () {
		loader.once('classes-loaded')
	},
	computed: {
		selectedAppointment: function () {
			if (!this.selectedAppointmentMeta.date)
			{
				return null
			}

			const date = this.monthDateYearShortFormat(moment(this.selectedAppointmentMeta.date).startOf('day'))
			const list = this.appointmentsForPeriodDates[date]
			const idx = findIndex(list, item => item.id === this.selectedAppointmentMeta.id)

			if (idx < 0)
			{
				return null
			}

			return list[idx]
		},
		appointmentGenerator: function () {
			return new AppointmentGenerator({
				getClass: uid => this.$store.getters.class(uid),
				missedClassesForDate: date => this.$store.getters['missedClassesByDate/forDate'](date),
				makeupsForDate: date => this.$store.getters['makeupsByDate/forDate'](date),
				freeClassesForDate: date => this.$store.getters['freeClassesByDate/forDate'](date),

				dancersForClass: uid => this.$store.getters.dancersForClass(uid),
				joinsForClass: uid => this.$store.getters['eventsByClass/joinsForClass'](uid),
				leavesForClass: uid => this.$store.getters['eventsByClass/leavesForClass'](uid)
			})
		},

		selectedPeriod: function () {
			return this.period.options[this.period.selected]
		},

		periodDates: function () {
			const period = this.selectedPeriod
			switch (period.value)
			{
				default:
				case 0:
				{
					const date = moment(this.startingDate).startOf('day')

					loader.fetchAndListenForMissedClassesByDate(date.toDate())
					loader.fetchAndListenForMakeupsByDate(date.toDate())
					loader.fetchAndListenForFreeClassesByDate(date.toDate())

					return [date]
				}

				case 1:
				{
					const weekStart = moment(this.startingDate).startOf('day')
					return map(range(7), i => {
						const date = moment(weekStart).startOf('day').add(i, 'day')

						loader.fetchAndListenForMissedClassesByDate(date.toDate())
						loader.fetchAndListenForMakeupsByDate(date.toDate())
						loader.fetchAndListenForFreeClassesByDate(date.toDate())

						return date
					})
				}
			}
		},

		appointmentsForPeriodDates: function () {
			const ret = {}

			forEach(this.periodDates, date => {
				ret[this.monthDateYearShortFormat(date)] = this.appointmentsForDate(date)
			})

			return ret
		},

		dancersForSelectedAppointment: function () {
			const scheduled = this.selectedAppointment.dancersScheduled
			const dancers = map(scheduled, uid => {
				const d = this.$store.getters.dancer(uid)
				if (!d)
				{
					return {
						uid: uid,
						firstName: uid,
						lastName: '(Maybe Archived)'
					}
				}

				return d
			})

			return dancers
		},
		missingDancersForSelectedAppointment: function () {
			return map(this.selectedAppointment.dancersMissing, uid => {
				return this.$store.getters.dancer(uid)
			})
		},

		columnSizes: function () {
			const period = this.selectedPeriod
			return {
				'is-half': period.value === 0,
				'is-one-third': period.value > 0
			}
		}
	},
	methods: {
		appointmentsForDate: function (date) {
			const classesForDate = this.$store.getters.classesForDayOfWeek(date.format('dddd'))
			const activeClassesForDate = filter(classesForDate, c => {
				const active = c.status === 'Active'
				var discontinuedButActive = false
				if (c.endDate)
				{
					discontinuedButActive = c.status === 'Discontinued' && moment(c.endDate).isSameOrAfter(date, 'day')
				}

				const hasStarted = moment(c.startDate).isSameOrBefore(date, 'day')

				return (active || discontinuedButActive) && hasStarted
			})

			return sortBy(map(activeClassesForDate, (classData, idx) => {
				loader.fetchAndListenForDancerEventsByClass(classData.uid)

				const appt = this.appointmentGenerator.generateClassDataForDate(classData, date.toDate())

				const startTime = this.to24Hour(classData.startTime)
				appt.date = moment(appt.date).hour(startTime.hour).minute(startTime.minute).toDate()
				const cd = clone(classData)
				cd.classType = this.$store.getters.classType(cd.classType)
				cd.classLevel = this.$store.getters.classLevel(cd.classLevel)
				cd.instructor = this.$store.getters.teacher(cd.instructor)
				cd.studio = this.$store.getters.studio(cd.studio)
				appt.classData = cd
				appt.id = idx
				
				return appt
			}), 'date')
		},

		handleSelectAppointment: function (appt) {
			this.showAppointmentInfo = true
			this.selectedAppointmentMeta = {
				date: appt.date,
				id: appt.id
			}
		},
		handleCloseAppointmentInfo: function () {
			this.showAppointmentInfo = false
			this.selectedAppointmentMeta = defaultAppointmentMeta()
		},
		handleOpenAppointmentInfo: function () {
			
		},
		handleEditDancer: function (dancer) {
			this.$refs.editDancerModal.show(dancer)
		},
		handleEditClass: function (data) {
			this.$refs.editClassModal.show(this.$store.getters.class(data.uid))
		},

		handleDancerMissSelectedAppointment: function (dancer) {
			this.isLoading = true

			const classData = this.selectedAppointment.classData

			if (this.dancerIsFreeClass(dancer))
			{
				loader.fetchAndListenForFreeClasses(dancer.uid).then(() => {

					const list = this.$store.getters['freeClasses/forDancer'](dancer.uid)
					const fcData = find(list, fc => classData.uid === fc.classUid)

					if (!fcData)
					{
						console.error('Could not find free class for dancer...')
						return
					}

					const ref = db.collection(collections.FreeClasses).doc(dancer.uid)

					ref.collection('classes').doc(fcData.uid).delete().then(() => {
						// manually remove this immediately; firebase trigger takes a little while to fire
						remove(this.selectedAppointment.dancersFreeClass, dancer.uid)
						remove(this.selectedAppointment.dancersScheduled, dancer.uid)
						this.isLoading = false
					})
				})

				return
			}

			const missed = {
				credits: classData.classType.credits,
				creditsUsed: 0,
				missedClass: classData.uid,
				missedDate: this.selectedAppointment.date
			}

			if (!this.dancerIsMakeup(dancer))
			{
				db.collection(collections.MissedClasses).doc(dancer.uid).collection('missed').add(missed).then(() => {
					remove(this.selectedAppointment.dancersScheduled, dancer.uid)
					this.selectedAppointment.dancersMissing.push(dancer.uid)
					this.isLoading = false
				})
				.catch(err => {
					console.error(err)
					this.isLoading = false
				})

				return
			}

			loader.fetchAndListenForMakeups(dancer.uid).then(() => {
				const list = this.$store.getters.makeupsForDancer(dancer.uid)
				
				const makeupData = find(list, mu => {
					const makeupClassUid = head(values(mu.makeupClass))
					return classData.uid === makeupClassUid
				})

				const key = `mc|${classData.uid}`
				if (key in makeupData.credits)
				{
					missed.credits = makeupData.credits[key]
				}

				missMakeup(makeupData, missed, dancer.uid).then(() => {
					remove(this.selectedAppointment.dancersMakeup, dancer.uid)
					remove(this.selectedAppointment.dancersScheduled, dancer.uid)
					this.isLoading = false
				})
			})
		},

		dancerIsMakeup: function (dancer) {
			return findIndex(this.selectedAppointment.dancersMakeup, uid => uid === dancer.uid) >= 0
		},
		dancerIsFreeClass: function (dancer) {
			return findIndex(this.selectedAppointment.dancersFreeClass, uid => uid === dancer.uid) >= 0
		},

		isSelectedAppointment: function (appt) {
			if (!this.selectedAppointment)
			{
				return false
			}

			const sameClass = appt.classData.uid == this.selectedAppointment.classData.uid
			const sameDate = moment(appt.date).isSame(moment(this.selectedAppointment.date))

			return sameClass && sameDate
		},

		to24Hour: function (startTime) {
			const parts = startTime.split(' ')
			const isPm = parts[1] === 'pm'

			const timeParts = parts[0].split(':')
			var hour = parseInt(timeParts[0])
			if (isPm && hour !== 12)
			{
				hour += 12
			}

			const minutes = parseInt(timeParts[1])

			return {
				hour: hour,
				minute: minutes
			}
		},

		classTitle: function (classData) {
			const type = classData.classType.name
			const level = type === 'Stretch' ? '' : classData.classLevel.name
			return `${type} ${level} @ ${classData.startTime.replace(' ', '')}`
		},

		colorForStudio: function (name) {
			return {
				'is-info': name === '1060 Bannock',
				'is-primary': name === '1111 Broadway'
			}
		},

		pluralize: pluralize
	},
	components: {
		SidePanel,
		EditDancerModal,
		EditClassModal
	}
}
</script>

<style scoped>
.clickable 
{
	cursor: pointer;
}

.appointment-info
{
	background-color: grey !important;
	height: 10px
}

.selected-background
{
	background-color: #e5ffe5
}
</style>