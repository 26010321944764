





























import { Component, Vue, Watch } from 'vue-property-decorator'

import AddSub from './AddSub.vue'
import SubsByDate from './SubsByDate.vue'

import loader from '@/dataLoader'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

@Component({
	components: {
		AddSub,
		SubsByDate
	}
})
export default class Substitutes extends Vue
{
	private noSubsMessage = 'Loading substitutes...'

	get classes(): IStudioClass[]
	{
		return this.$store.getters.classes
	}

	get substitutes(): ISubstitute[]
	{
		return this.$store.getters['substitutes/substitutes']
	}

	get substitutesByDate(): { [key: string]: ISubstitute }
	{
		return this.$store.getters['substitutes/substitutesByDate']
	}

	get orderedDates(): string[]
	{
		return sortBy(Object.keys(this.substitutesByDate))
	}

	@Watch('classes')
	private onClassesChanged(newVal: IStudioClass[])
	{
		this.fetchAndListen()
	}

	created()
	{
		this.fetchAndListen()

		setTimeout(() => {
			this.noSubsMessage = 'No substitutes were found.'
		}, 1000)
	}

	private handleAdd()
	{
		this.$buefy.modal.open({
			parent: this,
			component: AddSub
		})
	}

	private keyToDate(key: string)
	{
		return moment(key).toDate()
	}

	private fetchAndListen()
	{
		this.classes.forEach(({ uid }) => {
			loader.fetchAndListenForSubstitutes(uid)
		})
	}
}
