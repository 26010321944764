<template>
<div>
	<section class="section">
		<div class="container">
			<h1 class="title">Update {{ studio.name }}</h1>

			<b-notification :active="notificationType !== ''" :type="notificationType" :closable="false" :auto-close="true">
				{{ notificationMessage }}
			</b-notification>

			<studio-form :studio-data="studio" 
				@submit="handleSubmit" 
				action-text="Update" 
				:state="state"
				:clear-on-success="false" />
		</div>
	</section>
</div>
</template>

<script>
import StudioForm from './components/StudioForm.vue'

import {
	firestore as db,
	collections
} from '@/firebase'

import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'

const defaultExtraInfo = () => {
	return {
		position: 0,
		name: '',
		message: ''
	}
}

const studioTemplate = {
	name: '',
	address: {
		street: '',
		city: '',
		state: '',
		zip: ''
	},
	extraInfo: [
		defaultExtraInfo()
	]
}

export default {
	data: function () {
		return {
			studio: cloneDeep(studioTemplate),
			state: 'idle',
			notificationMessage: ''
		}
	},
	mounted: function () {
		if (!this.uid)
		{
			return
		}

		this.studio = cloneDeep(this.$store.getters.studio(this.uid))
	},
	computed: {
		uid: function () {
			return this.$route.params.studioUid
		},
		notificationType: function () {
			switch (this.state)
			{
				default:
					return ''

				case 'error':
					return 'is-danger'

				case 'success':
					return 'is-success'
			}

		}
	},
	methods: {
		handleSubmit: function (data) {

			this.state = 'working'

			const d = cloneDeep(data)

			forEach(d.extraInfo, ei => delete ei.position)
			
			db.collection(collections.Studios).doc(this.studio.uid).update(d).then(() => {
				this.state = 'success'
				this.notificationMessage = d.name + ' has been updated!'

				window.scrollTo(0, 0)
			})
			.catch(err => {
				console.error(err)
				this.state = 'error'
				this.notificationMessage = err

				window.scrollTo(0, 0)
			})
		}
	},
	components: {
		StudioForm
	}
}
</script>

<style scoped>

</style>