var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "min-height": "500px" } }, [
    _c(
      "section",
      { staticClass: "section" },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _c("horizontal-select", {
                      staticStyle: { width: "150px" },
                      attrs: { options: _vm.template.years },
                      model: {
                        value: _vm.targetYearString,
                        callback: function($$v) {
                          _vm.targetYearString = $$v
                        },
                        expression: "targetYearString"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _c("b-datepicker", {
                      attrs: {
                        placeholder: "Select a date...",
                        icon: "calendar-alt",
                        "date-formatter": _vm.defaultFormatDate,
                        "focused-date": _vm.targetYear.toDate(),
                        editable: ""
                      },
                      model: {
                        value: _vm.newClosureDate,
                        callback: function($$v) {
                          _vm.newClosureDate = $$v
                        },
                        expression: "newClosureDate"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "level-item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        disabled: !_vm.newClosureDate || _vm.selectedDateExists
                      },
                      on: { click: _vm.handleAddDate }
                    },
                    [_vm._v(" Add Date ")]
                  )
                ]),
                _vm.selectedDateExists
                  ? _c("div", { staticClass: "level-item" }, [
                      _c("span", { staticClass: "tag is-warning" }, [
                        _vm._v("Date already exists.")
                      ])
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._l(_vm.closureDatesByMonth, function(group) {
              return _c("div", { key: _vm.monthYearFormat(group[0]) }, [
                _c("h2", { staticClass: "is-size-2" }, [
                  _vm._v(" " + _vm._s(_vm.monthYearFormat(group[0])) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "columns is-multiline" },
                  _vm._l(group, function(date) {
                    return _c(
                      "div",
                      {
                        key: date.toString(),
                        staticClass: "column is-one-third"
                      },
                      [
                        _c("div", { staticClass: "box is-clearfix" }, [
                          _c("div", { staticClass: "is-pulled-left" }, [
                            _vm._v(
                              " " + _vm._s(_vm.defaultFormatDate(date)) + " "
                            )
                          ]),
                          _c("div", { staticClass: "is-pulled-right" }, [
                            _c("a", {
                              staticClass: "delete",
                              on: {
                                click: function($event) {
                                  return _vm.deleteDate(date)
                                }
                              }
                            })
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _c("br")
              ])
            })
          ],
          2
        ),
        _c("b-loading", {
          attrs: {
            "is-full-page": false,
            active: _vm.isLoading,
            "can-cancel": false
          },
          on: {
            "update:active": function($event) {
              _vm.isLoading = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-item" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Closure Dates")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }