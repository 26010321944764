<template>
<div>
<section class="section">
	<div class="container">
		<nav class="level">
			<div class="level-left">
				<div class="level-item  has-text-right">
					<div style="width: 250px">
						<div class="card">
							<div class="card-content is-clearfix card-content-height">
								<div class="is-pulled-left">
									<i class="far fa-user fa-4x"></i>
								</div>
								<div class="is-pulled-right">
									<p class="heading">Ballerinas</p>
									<p class="title">{{ activeDancersCount }}</p>	
								</div>
							</div>
							<footer class="card-footer">
								<p class="card-footer-item justify-left">
									<b-icon
										pack="far"
										icon="thumbs-up"
										size="is-large">
									</b-icon>
									<span>
										Great Job!
									</span>
								</p>
							</footer>
						</div>
					</div>

					<div style="width: 250px; margin-left: 30px">
						<div class="card">
							<div class="card-content is-clearfix card-content-height">
								<div class="is-pulled-left">
									<i class="far fa-clock fa-4x"></i>
								</div>
								<div class="is-pulled-right">
									<p class="heading">Weekly Class<br>Hours</p>
									<p class="title">{{ weeklyClassHours }}</p>	
								</div>
							</div>
							<footer class="card-footer">
								<p class="card-footer-item justify-left">
									<b-icon
										pack="fas"
										icon="shoe-prints"
										size="is-large">
									</b-icon>
									<span>
										That's a lot of dancing!
									</span>
								</p>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</nav>

		<div class="box">
			<h1 class="is-size-3">
				Business Health
				<p class="subtitle">Every churned customer is a lesson.</p>
			</h1>
			<br>
			<table class="table is-striped is-hoverable is-fullwidth">
				<thead>
					<tr>
						<th>Month</th>
						<th class="has-text-centered">New Dancers</th>
						<th class="has-text-centered">Canceled</th>
						<th class="has-text-centered">Paused</th>
						<th class="has-text-centered">Total Dancers</th>
						<th class="has-text-centered">Net New</th>
						<th class="has-text-centered">MoM Growth</th>
						<th class="has-text-centered">MoM Churn</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in metrics" :key="item.uid">
						<td>
							{{ item.uid }}
						</td>
						<td class="has-text-centered">
							{{ item.newDancersCount }}
						</td>
						<td class="has-text-centered">
							{{ item.canceledCount }}
						</td>
						<td class="has-text-centered">
							{{ item.pausedCount }}
						</td>
						<td class="has-text-centered">
							{{ item.totalDancers }}
						</td>
						<td class="has-text-centered">
							{{ item.netNew }}
						</td>
						<td class="has-text-centered" :class="determineGrowthInicator(item.momGrowth)">
							{{ formatPercent(item.momGrowth) }}
						</td>
						<td class="has-text-centered" :class="determineChurnInicator(item.momChurn)">
							{{ formatPercent(item.momChurn) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	
		<br>

		<div class="columns">
			<div class="column">
				<div class="card">
					<div class="card-content">
						<h1 class="is-size-3">
							Weekly Hours by Instructor
							<p class="subtitle">How many hours of joy we are spreading.</p>
						</h1>
						<br>
						<weekly-hours-by-instructor-chart/>
					</div>
					<footer class="card-footer">
						<p class="card-footer-item justify-left">
							<b-icon
								icon="check"
								size="is-large">
							</b-icon>
							<span>
								Fabulous work!
							</span>
						</p>
					</footer>
				</div>
			</div>
			<div class="column">
			</div>
		</div>
	</div>
</section>
</div>
</template>

<script>
import WeeklyHoursByInstructorChart from '@/components/dashboard/WeeklyHoursByInstructorChart'

import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'

import moment from 'moment'

import loader from '@/dataLoader'

const generateMetricMonths = (start) => {
	if (!start)
	{
		start = '2017-05-01'
	}

	const beginning = moment(start)
	const now = moment().startOf('day')

	const months = moment.duration(now.diff(beginning)).asMonths()
	
	const currentMonth = beginning

	const ret = []
	for (var i = 0; i < months; ++i)
	{
		ret.push(currentMonth.format('MMM YYYY'))

		currentMonth.add(1, 'months')
	}

	return ret
}

export default {
	data: function () {
		return {
			metricMonths: generateMetricMonths(),
			metrics: []
		}
	},
	mounted: function () {
		loader.fetchAndListenForBusinessMetrics()

		if (!isEmpty(this.monthlyData))
		{
			this.populateMetrics(this.monthlyData)
		}
	},
	watch: {
		monthlyData: function (newData) {
			this.populateMetrics(newData)
		}
	},
	computed: {
		activeDancersCount: function () {
			return filter(this.$store.getters.dancers, obj => obj.status === 'Active').length
		},
		monthlyData: function () {
			const lookup = {}

			forEach(this.$store.getters['metrics/monthlyData'], d => {
				lookup[d.uid] = d
			})

			return lookup
		},

		weeklyClassHours: function () {
			const totalMins = reduce(
				filter(this.$store.getters.classes, obj => obj.status === 'Active'),
				(sum, itm) => sum + itm.length,
				0
			)

			return totalMins / 60
		}
	},
	methods: {
		populateMetrics: function (list) {
			this.metrics = []
			for (var i = 0; i < this.metricMonths.length; ++i)
			{
				var data = list[this.metricMonths[i]]
				var prev

				if (i > 0)
				{
					prev = this.metrics[i - 1]
				}

				if (!data)
				{
					// we have no data for this time period so create an empty set
					data = {
						uid: this.metricMonths[i],
						canceledCount: 0,
						newDancersCount: 0,
						pausedCount: 0,
						startCount: prev.totalDancers,
						totalDancers: prev.totalDancers
					}
				}

				data.netNew = data.newDancersCount - (data.canceledCount + data.pausedCount)
				data.momGrowth = 0

				if (i > 0)
				{
					if (prev.totalDancers > 0)
					{
						data.momGrowth = (data.totalDancers - prev.totalDancers) / prev.totalDancers
					}
				}

				data.momChurn = 0
				if (data.startCount > 0)
				{
					data.momChurn = (data.canceledCount + data.pausedCount) / data.startCount
				}

				this.metrics.push(data)
			}

			this.metrics.reverse()
		},

		formatPercent: function (value) {
			return parseFloat(value * 100).toFixed(2) + '%'
		},

		determineGrowthInicator: function (growth) {
			if (growth >= .2)
			{
				return 'has-background-success has-text-weight-semibold'
			}

			if (growth >= .1)
			{
				return 'has-background-info has-text-light'
			}

			if (growth >= 0)
			{
				return 'has-background-warning'
			}

			if (growth < 0)
			{
				return 'has-background-danger has-text-weight-semibold'
			}
		},
		determineChurnInicator: function (churn) {
			if (churn >= .2)
			{
				return 'has-background-danger has-text-weight-semibold'
			}

			if (churn >= .1)
			{
				return 'has-background-warning'
			}
		}
	},
	components: {
		WeeklyHoursByInstructorChart
	}
}
</script>

<style scoped>
.justify-left
{
	justify-content: left !important;
}

.card-content-height
{
	height: 120px;
}
</style>