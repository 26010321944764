
































































































import { Component, Mixins } from 'vue-property-decorator'
import { firestore as db, collections } from '@/firebase'

import SidePanel from 'studio-shared/vue/components/SidePanel.vue'

import DancersList from './components/DancersList.vue'
import EditDancerModal from './components/EditDancerModal.vue'

import ClassesForDancer from '@/mixins/ClassesForDancer'
import FormatDate from '@/mixins/FormatDate'

import generateSelectableDates from '@/utils/generateSelectableDates'

const AppointmentGenerator = require('studio-shared/appointments')
import missMakeup from '@/utils/missMakeup'

import loader from '@/dataLoader'

import eventActionTypes from '@/utils/eventActions'

import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import clone from 'lodash/clone'
import findIndex from 'lodash/findIndex'
import values from 'lodash/values'
import head from 'lodash/head'
import uniq from 'lodash/uniq'
import find from 'lodash/find'
import remove from 'lodash/remove'

import moment from 'moment';

declare interface ScheduleData
{
	dancer: IDancer | null
}

@Component({
	components: {
		SidePanel,
		DancersList,
		EditDancerModal
	}
})
export default class Dancers extends Mixins(ClassesForDancer, FormatDate)
{
	private scheduleData: ScheduleData = {
		dancer: null
	}

	private isLoading = false

	mounted()
	{
		// preload this year's close dates; good chance we'll be using them
		loader.fetchAndListenForClosures(moment().format('YYYY'))
	}

	get allDancers()
	{
		return this.$store.getters.dancers
	}

	get selectedDancer()
	{
		return this.scheduleData.dancer
	}

	get appointmentGenerator()
	{
		return new AppointmentGenerator({
			getClass: (uid: string) => this.$store.getters.class(uid),
			missedClassesForDate: (date: Date) => this.$store.getters['missedClassesByDate/forDate'](date),
			makeupsForDate: (date: Date) => this.$store.getters['makeupsByDate/forDate'](date),
			freeClassesForDate: (date: Date) => this.$store.getters['freeClassesByDate/forDate'](date),

			dancersForClass: (uid: string) => this.$store.getters.dancersForClass(uid),
			joinsForClass: (uid: string) => this.$store.getters['eventsByClass/joinsForClass'](uid),
			leavesForClass: (uid: string) => this.$store.getters['eventsByClass/leavesForClass'](uid)
		})
	}

	get classesForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		return this.classesForDancer(this.scheduleData.dancer!.uid)
	}

	get classesLeftForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		const events = this.$store.getters['archivedEvents/forDancer'](this.selectedDancer.uid)
		return map(
			filter(events, evt => {
				return evt.action === eventActionTypes.LeaveClass
			}),
			(evt: any) => {
				return evt.class
			}
		)
	}

	get makeupsForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		const list = this.$store.getters.makeupsForDancer(this.selectedDancer.uid)
		const now = moment().startOf('day')
		return filter(list, mu => {
			return moment(mu.makeupDate).isSameOrAfter(now)
		})
	}

	get pastMakeupsForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		const list = this.$store.getters.makeupsForDancer(this.selectedDancer.uid)
		
		const now = moment().startOf('day')
		return filter(list, mu => {
			return moment(mu.makeupDate).isBefore(now)
		})
	}

	get freeClassesForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		const list = this.$store.getters['freeClasses/forDancer'](this.selectedDancer.uid)
		const now = moment().startOf('day')
		return filter(list, fc => {
			return moment(fc.classDate).isSameOrAfter(now)
		})
	}

	get pastFreeClassesForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		const list = this.$store.getters['freeClasses/forDancer'](this.selectedDancer.uid)
		const now = moment().startOf('day')
		return filter(list, fc => {
			return moment(fc.classDate).isBefore(now)
		})
	}

	get upcomingAppointmentsForSelectedDancer()
	{
		const classes = this.classesForSelectedDancer
		
		const appointments: any = []

		for (var i = 0; i < classes.length; ++i)
		{
			const classData = classes[i]
			const dates = generateSelectableDates(classData, 4)
			const list = this.generateAppointments(classData, dates)
			this.addIfScheduled(appointments, list)
		}

		const makeups = this.makeupsForSelectedDancer

		forEach(makeups, mu => {
			if (mu.type === 'special')
			{
				return
			}

			const classUid = head(values(mu.makeupClass))
			const classData = this.$store.getters.class(classUid)
			const list = this.generateAppointments(classData, [mu.makeupDate])
			this.addIfScheduled(appointments, list)
		})

		const freeClasses = this.freeClassesForSelectedDancer

		forEach(freeClasses, fc => {
			const classUid = fc.classUid
			const classData = this.$store.getters.class(classUid)
			const list = this.generateAppointments(classData, [fc.classDate])
			this.addIfScheduled(appointments, list)
		})

		return orderBy(appointments, ['date'], ['asc'])
	}

	get pastAppointmentsForSelectedDancer()
	{
		if (!this.selectedDancer)
		{
			return []
		}

		// don't need future joins/leaves for past appointments
		// only currently active classes and previous past leaves
		const classes = uniq(concat(
			this.$store.getters.classesForDancer(this.selectedDancer.uid),
			this.classesLeftForSelectedDancer
		))

		const appointments: any[] = []

		for (var i = 0; i < classes.length; ++i)
		{
			const uid = classes[i]
			const classData = this.$store.getters.class(uid)
			const dates = this.generatePastDatesForClass(classData, this.selectedDancer.uid)
			const list = this.generateAppointments(classData, dates)
			this.addIfScheduled(appointments, list)
		}

		const makeups = this.pastMakeupsForSelectedDancer

		forEach(makeups, (mu: any) => {
			if (mu.type === 'special')
			{
				return
			}

			const classUid = head(values(mu.makeupClass))
			const classData = this.$store.getters.class(classUid)

			if (!classData)
			{
				return
			}

			const list = this.generateAppointments(classData, [mu.makeupDate])
			forEach(list, (item: any) => {
				if (!this.isMakeup(item))
				{
					// NOTE: past data where we haven't created the makeupByDate lookup so just add it manually
					item.dancersMakeup.push(this.selectedDancer!.uid)
				}
				appointments.push(item)
			})
		})

		const freeClasses = this.pastFreeClassesForSelectedDancer

		forEach(freeClasses, fc => {
			const classUid = fc.classUid
			const classData = this.$store.getters.class(classUid)

			if (!classData)
			{
				return
			}

			const list = this.generateAppointments(classData, [fc.classDate])
			forEach(list, item => appointments.push(item))
		})

		return orderBy(appointments, ['date'], ['desc'])
	}
	
	private generateAppointments(classData: any, dates: any[])
	{
		forEach(dates, (date: any) => {
			loader.fetchAndListenForMissedClassesByDate(date),
			loader.fetchAndListenForMakeupsByDate(date),
			loader.fetchAndListenForFreeClassesByDate(date),
			loader.fetchAndListenForDancerEventsByClass(classData.uid)
		})

		const selectableClasses: any[] = []

		forEach(dates, (date: any) => {
			const appt = this.appointmentGenerator.generateClassDataForDate(classData, date)
			const cd = clone(classData)
			cd.classLevel = this.$store.getters.classLevel(cd.classLevel)
			cd.classType = this.$store.getters.classType(cd.classType)
			cd.instructor = this.$store.getters.teacher(cd.instructor)
			cd.studio = this.$store.getters.studio(cd.studio)

			appt.classData = cd
			appt.id = `${cd.uid}-${date}`

			selectableClasses.push(appt)
		})

		return selectableClasses
	}

	private generatePastDatesForClass(classData: any, dancerUid: any)
	{
		if (!classData)
		{
			return []
		}

		const events = this.$store.getters['archivedEvents/forDancer'](dancerUid)
		const joins = filter(events, evt => {
			return evt.action === eventActionTypes.JoinClass && evt.class === classData.uid
		})

		var earliestJoin = moment().subtract(6, 'months').day(classData.dayOfWeek).startOf('day')
		if (joins.length > 0)
		{
			// default to past 6 months if no join date is found otherwise use the found date
			earliestJoin = moment(joins[0].effectiveDate).startOf('day')
		}

		const now = moment().startOf('day')
		const weeks = now.diff(earliestJoin, 'weeks')

		const dates = []
		for (var i = 0; i < weeks; ++i)
		{
			dates.push(moment(earliestJoin).add(i, 'week').toDate())
		}

		return dates
	}

	private isMissing(appt: any)
	{
		return findIndex(appt.dancersMissing, uid => uid === this.selectedDancer!.uid) >= 0
	}

	private isMakeup(appt: any)
	{
		return findIndex(appt.dancersMakeup, uid => uid === this.selectedDancer!.uid) >= 0
	}

	private isFreeClass(appt: any)
	{
		return findIndex(appt.dancersFreeClass, uid => uid === this.selectedDancer!.uid) >= 0
	}

	private isClosed(appt: any)
	{
		const date = moment(appt.date)
		const year = date.format('YYYY')

		// this year is already pre-fetched but just in case of other years
		loader.fetchAndListenForClosures(year)

		const closedDates: moment.Moment[] = this.$store.getters['closures/closures'](year)
		return findIndex(closedDates, d => d.isSame(date, 'day')) >= 0
	}

	private handleEdit(data: any)
	{
		// @ts-ignore
		this.$refs.editDancerModal.show(data)
	}

	private handleSchedule(data: any)
	{
		this.scheduleData.dancer = data

		loader.fetchAndListenForArchivedDancerEvents(data.uid)
		loader.fetchAndListenForMakeups(data.uid)
		loader.fetchAndListenForFreeClasses(data.uid)
	}

	private handleMissed(appt: any)
	{
		if (!this.selectedDancer)
		{
			return
		}

		this.isLoading = true

		const classData = appt.classData
		const dancerUid = this.selectedDancer.uid

		const missed = {
			credits: classData.classType.credits,
			creditsUsed: 0,
			missedClass: classData.uid,
			missedDate: appt.date
		}

		if (!this.isMakeup(appt))
		{
			db.collection(collections.MissedClasses).doc(dancerUid).collection('missed').add(missed).then(() => {
				remove(appt.dancersScheduled, dancerUid)
				appt.dancersMissing.push(dancerUid)
				this.isLoading = false
			})
			.catch((err: Error) => {
				console.error(err)
				this.isLoading = false
			})

			return
		}

		loader.fetchAndListenForMakeups(dancerUid).then(() => {
			const list = this.$store.getters.makeupsForDancer(dancerUid)
			const makeupData = find(list, mu => moment(mu.makeupDate).isSame(moment(appt.date), 'day'))

			if (!makeupData)
			{
				console.warn('Could not find makeup to be missed...')
				return
			}

			missMakeup(makeupData, missed, dancerUid).then(() => {
				remove(appt.dancersMakeup, dancerUid)
				remove(appt.dancersScheduled, dancerUid)
				this.isLoading = false
			})
			.catch((err: Error) => {
				console.log(err)
			})
		})
	}

	private handleDeleteFreeClass(appt: any)
	{
		if (!this.selectedDancer)
		{
			return
		}

		const classData = appt.classData
		const dancerUid = this.selectedDancer.uid

		loader.fetchAndListenForFreeClasses(dancerUid).then(() => {

			const list = this.$store.getters['freeClasses/forDancer'](dancerUid)
			const fcData = find(list, fc => classData.uid === fc.classUid)

			if (!fcData)
			{
				console.error('Could not find free class for dancer...')
				return
			}

			const ref = db.collection(collections.FreeClasses).doc(dancerUid)

			ref.collection('classes').doc(fcData.uid).delete().then(() => {
				// manually remove this immediately; firebase trigger takes a little while to fire
				remove(appt.dancersFreeClass, dancerUid)
				remove(appt.dancersScheduled, dancerUid)
				this.isLoading = false
			})
		})
	}

	private titleForClass(classData: any)
	{
		var title = classData.classType.name
		if (classData.classLevel.name !== 'Open')
		{
			title += ` ${classData.classLevel.name}`
		}
		return title
	}

	private addIfScheduled(destination: any, appointments: any)
	{
		forEach(appointments, (item: any) => {
			if (this.isClosed(item))
			{
				return
			}

			if (!this.isMissing(item) && findIndex(item.dancersScheduled, uid => uid === this.selectedDancer!.uid) < 0)
			{
				return
			}

			destination.push(item)
		})
	}
}
