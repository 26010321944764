






























































import { Component, Mixins } from 'vue-property-decorator'

import AddClassTypeModal from '@/components/classes/AddClassTypeModal.vue'
import AddClassLevelModal from '@/components/classes/AddClassLevelModal.vue'

import ClassInformation from '@/components/classes/ClassInformation.vue'

import {
	formatClassLength,

	classTemplate,
	defaultClass
} from '../../utils'

import FormatDate from '@/mixins/FormatDate'

import head from 'lodash/head'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import { 
	firestore as db,
	collections
} from '../../firebase'

@Component({
	components: {
		ClassInformation,
		AddClassTypeModal,
		AddClassLevelModal
	}
})
export default class AddClass extends Mixins(FormatDate)
{
	private classData = defaultClass(classTemplate())
	private addTypeModal = {
		active: false
	}
	private state = 'idle'
	private notificationMessage = ''

	get notificationType()
	{
		switch (this.state)
		{
			default:
				return ''

			case 'error':
				return 'is-danger'

			case 'success':
				return 'is-success'
		}
	}

	private async handleSubmit(evt: MouseEvent)
	{
		evt.preventDefault()

		const data = cloneDeep(this.classData)

		// @ts-ignore
		data.startTime = this.formatTimeNoLeadingZero(data.startTime)

		// pick a date in the past if 'ongoing' which means already started
		if (data.ongoing)
		{
			data.startDate = moment("2017-05-15").toDate()
		}

		// don't save this to db
		delete data.ongoing

		this.state = 'working'

		try
		{
			await db.collection(collections.Classes).add(data)

			this.state = 'success'
			this.notificationMessage = 'New class has been added!'
		}
		catch(err)
		{
			console.error(err)

			this.state = 'error'
			this.notificationMessage = err
		}
	}
	
	private handleAddClassType()
	{
		// @ts-ignore
		this.$refs.addTypeModal.show()
	}

	private handleClassTypeAdded(data: any)
	{
		this.$store.commit('addClassType', data)
		this.classData.classType = data.uid
	}

	private handleAddClassLevel()
	{
		// @ts-ignore
		this.$refs.addLevelModal.show()
	}

	private handleClassLevelAdded(data: any)
	{
		this.$store.commit('addClassLevel', data)
		this.$store.commit('sortClassLevels')
		this.classData.classLevel = data.uid
	}
}
