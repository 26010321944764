<template>
<b-modal :active.sync="active" @close="handleClose">
<div class="box">
	<h1 class="title">
		Add Credits
		<p class="subtitle">
			Give dancer extra credits.
		</p>
	</h1>
	<br>
	<form @submit="handleSubmit">
		<div class="field">
			<label class="label">Description</label>
			<div class="control">
				<b-input type="text" v-model="form.description">
            </b-input>
			</div>
		</div>
		<div class="field">
			<label class="label">Credits</label>
			<div class="control">
				<b-select v-model="form.credits" placeholder="Select credits">
					<option
						v-for="option in template.credits"
						:value="option"
						:key="option">
						{{ option }}
					</option>
				</b-select>
			</div>
		</div>
		<div class="field is-grouped is-grouped-right">
			<div class="control">
				<button class="button is-primary" 
					:disabled="!form.description"
					:class="{ 'is-loading': isLoading }">
					Add
				</button>
			</div>
		</div>
	</form>
</div>
</b-modal>
</template>

<script>
import range from 'lodash/range'

import moment from 'moment'

import {
	firestore as db,
	collections
} from '@/firebase'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

const defaultData = () => {
	return {
		description: '',
		credits: 1
	}
}

export default {
	mixins: [],
	data: function () {
		return {
			template: {
				credits: range(1, 11)
			},

			active: false,
			isLoading: false,

			dancer: defaultDancer(),
			form: defaultData()
		}
	},
	mounted: function () {
	},
	methods: {
		show: function (dancer) {
			this.active = true

			this.dancer = dancer
		},
		handleClose: function () {
			this.dancer = defaultDancer()
			this.form = defaultData()
		},

		handleSubmit: function (evt) {
			evt.preventDefault()

			this.isLoading = true

			const data = this.form
			data.creditsUsed = 0
			data.created = moment().toDate()
			data.type = 'ec'

			db.collection(collections.MissedClasses).doc(this.dancer.uid).collection('extra-credits').add(data).then(() => {
				this.isLoading = false
				this.active = false

				this.handleClose()
			})
			.catch(err => {
				console.error(err)

				this.isLoading = false
			})
		}
	},
	computed: {
	},
	components: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
