var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active, width: 960 },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "box", staticStyle: { "min-height": "650px" } },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }