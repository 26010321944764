var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns is-multiline" }, [
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("horizontal-select", {
          attrs: {
            options: _vm.template.daysOfWeek,
            label: "Day",
            disabled: _vm.isEditMode
          },
          model: {
            value: _vm.classData.dayOfWeek,
            callback: function($$v) {
              _vm.$set(_vm.classData, "dayOfWeek", $$v)
            },
            expression: "classData.dayOfWeek"
          }
        }),
        _c("horizontal-select", {
          attrs: {
            options: _vm.studios,
            "key-property": "uid",
            "value-property": "uid",
            label: "Studio"
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(data) {
                return [_vm._v(" " + _vm._s(data.option.name) + " ")]
              }
            }
          ]),
          model: {
            value: _vm.classData.studio,
            callback: function($$v) {
              _vm.$set(_vm.classData, "studio", $$v)
            },
            expression: "classData.studio"
          }
        }),
        _c("horizontal-select", {
          attrs: {
            options: _vm.classLevels,
            "key-property": "uid",
            "value-property": "uid",
            label: "Class Level"
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(data) {
                return [_vm._v(" " + _vm._s(data.option.name) + " ")]
              }
            }
          ]),
          model: {
            value: _vm.classData.classLevel,
            callback: function($$v) {
              _vm.$set(_vm.classData, "classLevel", $$v)
            },
            expression: "classData.classLevel"
          }
        }),
        _vm.isEditMode
          ? _c("horizontal-input", {
              attrs: {
                value: _vm.classTypeFromUid(_vm.classData.classType),
                label: "Class Type",
                disabled: true
              }
            })
          : _c("horizontal-select", {
              attrs: {
                options: _vm.classTypes,
                "key-property": "uid",
                "value-property": "uid",
                label: "Class Type"
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function(data) {
                    return [_vm._v(" " + _vm._s(data.option.name) + " ")]
                  }
                }
              ]),
              model: {
                value: _vm.classData.classType,
                callback: function($$v) {
                  _vm.$set(_vm.classData, "classType", $$v)
                },
                expression: "classData.classType"
              }
            }),
        _vm.isEditMode
          ? _c("horizontal-input", {
              attrs: {
                value: _vm.classData.startTime,
                label: "Start Time",
                disabled: true
              }
            })
          : _c("div", { staticClass: "field is-horizontal" }, [
              _c("div", { staticClass: "field-label" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Start Time")])
              ]),
              _c("div", { staticClass: "field-body" }, [
                _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("b-timepicker", {
                        attrs: {
                          placeholder: "Type or select a date...",
                          icon: "clock",
                          "hour-format": "12",
                          "increment-minutes": 15,
                          "time-formatter": _vm.formatTimeNoLeadingZero
                        },
                        model: {
                          value: _vm.classData.startTime,
                          callback: function($$v) {
                            _vm.$set(_vm.classData, "startTime", $$v)
                          },
                          expression: "classData.startTime"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
        !_vm.isEditMode
          ? [
              _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(0),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("b-datepicker", {
                          attrs: {
                            disabled: _vm.classData.ongoing,
                            placeholder: "Select a date...",
                            icon: "calendar-alt"
                          },
                          model: {
                            value: _vm.classData.startDate,
                            callback: function($$v) {
                              _vm.$set(_vm.classData, "startDate", $$v)
                            },
                            expression: "classData.startDate"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "field is-horizontal" }, [
                _c("div", { staticClass: "field-label" }),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "b-checkbox",
                          {
                            model: {
                              value: _vm.classData.ongoing,
                              callback: function($$v) {
                                _vm.$set(_vm.classData, "ongoing", $$v)
                              },
                              expression: "classData.ongoing"
                            }
                          },
                          [_vm._v(" Class is ongoing ")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          : _vm._e(),
        _c("horizontal-input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.classData.classType,
              expression: "classData.classType"
            }
          ],
          attrs: {
            value: _vm.formatClassLength(_vm.classData),
            label: "Length",
            disabled: true
          }
        }),
        _c("div", { staticClass: "field is-horizontal" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "field-body" },
            [
              _c("b-switch", {
                model: {
                  value: _vm.customRate,
                  callback: function($$v) {
                    _vm.customRate = $$v
                  },
                  expression: "customRate"
                }
              })
            ],
            1
          )
        ]),
        _vm.customRate
          ? _c("horizontal-input", {
              attrs: {
                label: "Monthly Price",
                placeholder: "108",
                "icon-left": "dollar-sign"
              },
              model: {
                value: _vm.classData.monthlyRate,
                callback: function($$v) {
                  _vm.$set(_vm.classData, "monthlyRate", _vm._n($$v))
                },
                expression: "classData.monthlyRate"
              }
            })
          : _vm._e()
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("horizontal-select", {
          attrs: {
            options: _vm.instructors,
            "key-property": "uid",
            "value-property": "uid",
            label: "Instructor"
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(data) {
                return [_vm._v(" " + _vm._s(data.option.name) + " ")]
              }
            }
          ]),
          model: {
            value: _vm.classData.instructor,
            callback: function($$v) {
              _vm.$set(_vm.classData, "instructor", $$v)
            },
            expression: "classData.instructor"
          }
        }),
        _c("horizontal-select", {
          attrs: { options: _vm.template.statuses, label: "Status" },
          on: { input: _vm.handleStatusChanged },
          model: {
            value: _vm.classData.status,
            callback: function($$v) {
              _vm.$set(_vm.classData, "status", $$v)
            },
            expression: "classData.status"
          }
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.classData.status === _vm.template.statuses[1],
                expression: "classData.status === template.statuses[1]"
              }
            ],
            staticClass: "field is-horizontal"
          },
          [
            _vm._m(2),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field is-grouped" }, [
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("b-datepicker", {
                      attrs: {
                        placeholder: "Select the end date...",
                        icon: "calendar-alt"
                      },
                      model: {
                        value: _vm.classData.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.classData, "endDate", $$v)
                        },
                        expression: "classData.endDate"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        ),
        _c("horizontal-select", {
          attrs: {
            options: _vm.template.maxDancers,
            label: "Max Dancers",
            tooltip: "Max dancers in a class"
          },
          model: {
            value: _vm.classData.maxDancers,
            callback: function($$v) {
              _vm.$set(_vm.classData, "maxDancers", $$v)
            },
            expression: "classData.maxDancers"
          }
        }),
        _c("horizontal-select", {
          attrs: {
            options: _vm.template.maxRegisters,
            label: "Max Registers",
            tooltip: "Max dancers can register"
          },
          model: {
            value: _vm.classData.maxRegisters,
            callback: function($$v) {
              _vm.$set(_vm.classData, "maxRegisters", $$v)
            },
            expression: "classData.maxRegisters"
          }
        }),
        _c("horizontal-select", {
          attrs: {
            options: _vm.template.maxMakeups,
            label: "Max Makeups",
            tooltip: "Max makeup openings"
          },
          model: {
            value: _vm.classData.maxMakeups,
            callback: function($$v) {
              _vm.$set(_vm.classData, "maxMakeups", $$v)
            },
            expression: "classData.maxMakeups"
          }
        }),
        _c("horizontal-input", {
          attrs: { label: "Acuity Link", placeholder: "http://..." },
          model: {
            value: _vm.classData.acuityLink,
            callback: function($$v) {
              _vm.$set(_vm.classData, "acuityLink", $$v)
            },
            expression: "classData.acuityLink"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Start Date")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label is-normal" }, [
      _c("label", { staticClass: "label" }, [_vm._v(" Custom Price ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("End Date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }