<template>
<b-modal :active.sync="active">
	<div class="box">
		<add-class-type v-on:done="handleDone" />
	</div>
</b-modal>
</template>

<script>
import AddClassType from './AddClassType.vue'

export default {
	data: function () {
		return {
			active: false
		}
	},
	methods: {
		handleDone: function (data) {
			this.$emit('done', data)
			this.active = false
		},
		show: function () {
			this.active = true
		}
	},
	components: {
		AddClassType
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
