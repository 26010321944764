var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-autocomplete", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.classes.length > 0,
            expression: "classes.length > 0"
          }
        ],
        attrs: {
          placeholder:
            "find a class: e.g. thursday 1111 broadway ballet technique 3a",
          "keep-first": false,
          "open-on-focus": true,
          data: _vm.filteredClasses,
          "clear-on-select": true,
          field: "uid"
        },
        on: { select: _vm.handleSelectedClass },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c("div", { staticClass: "box" }, [
                  _c("h5", { staticClass: "is-size-5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          props.option.dayOfWeek +
                            " " +
                            _vm.classTypeFromUid(props.option.classType)
                        ) +
                        " at " +
                        _vm._s(props.option.startTime) +
                        " "
                    ),
                    _vm.denoteNotRecommended && !props.option.recommended
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "tag is-danger is-pulled-right is-normal"
                          },
                          [_c("small", [_vm._v("Not Recommended")])]
                        )
                      : props.option.event &&
                        props.option.event.action === "Join Class"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "tags has-addons is-pulled-right is-normal"
                          },
                          [
                            _c("span", { staticClass: "tag is-info" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.shortDate(
                                      props.option.event.effectiveDate
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "span",
                              { staticClass: "tag is-success" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    pack: "fas",
                                    icon: "plus",
                                    size: "is-small"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("br"),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _c("b", [_vm._v("Instructor:")])
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.teacherNameFromUid(props.option.instructor)
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _c("b", [_vm._v("Studio:")])
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.studioNameFromUid(props.option.studio)
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _c("b", [_vm._v("Level:")])
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.classLevelFromUid(props.option.classLevel)
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _c("b", [_vm._v("Status:")])
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _vm._v(" " + _vm._s(props.option.status) + " ")
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.forMakeup,
                                  expression: "!forMakeup"
                                }
                              ]
                            },
                            [_c("b", [_vm._v("Dancers:")])]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.forMakeup,
                                  expression: "forMakeup"
                                }
                              ]
                            },
                            [_c("b", [_vm._v("Makeup Spots:")])]
                          )
                        ]),
                        _c("div", { staticClass: "column" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.forMakeup,
                                  expression: "!forMakeup"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dancersCountForClass(props.option)
                                  ) +
                                  " / " +
                                  _vm._s(props.option.maxRegisters) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.forMakeup,
                                  expression: "forMakeup"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.makeupSpacesLeftForClass(props.option)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.classFilter,
          callback: function($$v) {
            _vm.classFilter = $$v
          },
          expression: "classFilter"
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.classes.length <= 0,
              expression: "classes.length <= 0"
            }
          ],
          staticClass: "content has-text-grey"
        },
        [_vm._v(" No suitable classes were found. ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }