import store from '@/store'

import _missMakeup from 'studio-shared/makeups/missMakeup'

/**
 * 
 * @param {Object} makeupData 
 * @param {Object} missed 
 * @param {String} dancerUid 
 */
const missMakeup = (makeupData, missed, dancerUid) => {
	return _missMakeup(makeupData, missed, dancerUid, () => {
		store.commit('removeMakeup', {
			dancerId: dancerUid,
			makeup: makeupData
		})
	})
	.catch(err => {
		console.error(err)
	})
}

export default missMakeup
