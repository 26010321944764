<script>
import { Bar } from 'vue-chartjs'

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'

import loader from '@/dataLoader'

export default {
	extends: Bar,
	data: function () {
		return {
			options: {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero:true
						}
					}]
				}
			}
		}
	},
	mounted: function () {
		const render = () => {
			this.renderChart({
				labels: this.instructorsByName,
				datasets: [
					{
						label: 'Weekly Hours',
						backgroundColor: '#6acecd',
						data: this.weeklyHours
					}
				]
			}, this.options)
		}

		if (this.$store.getters.classes.length == 0)
		{
			loader.once('classes-loaded').then(render)
			return
		}

		render()
	},
	computed: {
		instructors: function () {
			return filter(this.$store.getters.teachers, t => t.name !== 'TBD')
		},
		instructorsByName: function () {
			return map(this.instructors, obj => obj.name)
		},
		weeklyHours: function () {
			const teachers = this.instructors
			const list = map(
				map(teachers, item => {
					return reduce(
						filter(
							this.$store.getters.classesForInstructor(item.uid),
							itm => itm.status === 'Active'
						),
						(sum, obj) => sum += obj.length,
						0
					)
				}),
				sum => sum / 60
			)

			return list
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
