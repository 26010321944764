<template>
<div>
	<div>
		<button v-if="extraCredits.length > 0" class="button is-dark is-outlined" @click="handleAdd">
			<span class="icon is-small">
				<i class="fas fa-plus"></i>
			</span>
			<span>
				Extra Credits
			</span>
		</button>
	</div>
	<br>
	<div v-if="extraCredits.length > 0" class="columns is-multiline">
		<div v-for="item in extraCredits" :key="item.uid" class="column is-12">
			<div class="box">
				<div class="columns">
					<div class="column is-3">
						Credits: {{ item.credits }}<br>
						Credits Used: {{ item.creditsUsed }}<br>
						Awarded {{ monthDateYearShortFormat(item.created) }}						
					</div>
					<div class="column">
						{{ item.description }}
					</div>
					<div class="column is-3">
						<p class="buttons is-pulled-right">
							<button class="button is-danger" @click="handleDelete(item)"
									:disabled="item.makeups && item.makeups.length > 0">
								<b-tooltip label="Delete" type="is-danger">
									<span class="icon is-small">
										<i class="fas fa-times"></i>
									</span>
								</b-tooltip>
							</button>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<section class="section" v-if="extraCredits.length === 0">
		<div class="content has-text-grey has-text-centered">
			<p>
				There are no extra credits.
			</p>
			<br>
			<button @click="handleAdd" class="button">
				<span class="icon is-small">
					<i class="fas fa-plus"></i>
				</span>
				<span>
					Add Some
				</span>
			</button>
		</div>
	</section>
	<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

import {
	firestore as db,
	collections
} from '@/firebase'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

export default {
	props: {
		dancer: {
			type: Object,
			default: defaultDancer()
		}
	},
	mixins: [
		FormatDate
	],
	data: function () {
		return {
			isLoading: false
		}
	},
	mounted: function () {
	},
	methods: {
		handleDelete: function (item) {
			this.isLoading = true

			db.collection(collections.MissedClasses).doc(this.dancer.uid).collection('extra-credits').doc(item.uid).delete().then(() => {
				this.isLoading = false
			})
		},

		handleAdd: function () {
			this.$emit('add')
		}
	},
	computed: {
		extraCredits: function () {
			if (!this.dancer)
			{
				return []
			}
			return this.$store.getters['extraCredits/forDancer'](this.dancer.uid)
		}
	},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
