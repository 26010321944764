<template>
	<div>
		<div class="container" style="margin-top: 50px; margin-bottom: 50px">
			<div class="box">
				<section>
					
					<div class="notification is-danger" v-if="error.message">
						{{ error.message }}
					</div>

					<form @submit="handleLogin">
						<b-field label="Email"
							type=""
							message="">
							<b-input v-model="form.email"
								type="email"
								icon="envelope">
							</b-input>
						</b-field>

						<b-field label="Password">
							<b-input v-model="form.password"
								type="password"
								icon="key"
								password-reveal>
							</b-input>
						</b-field>

						<div class="field is-grouped is-grouped-right">
							<p class="control">
								<button class="button is-primary is-large has-text-dark is-pulled-right"
									:class="{'is-loading': isLoading}">
									Login
								</button>
							</p>
						</div>
					</form>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { auth, functions } from '../firebase'

export default {
	data: function () {
		return {
			form: {
				email: '',
				password: ''
			},
			isLoading: false,
			error: {
				message: null
			}
		}
	},
	methods: {
		handleLogin: function (evt) {
			evt.preventDefault()

			const email = this.form.email
			const password = this.form.password

			this.isLoading = true
			this.error.message = null

			auth.signInWithEmailAndPassword(email, password).then(res => {
				return res.user.getIdTokenResult(true)
			})
			.then(tokenResult => {
				const token = tokenResult.token

				const adminToken = functions.httpsCallable('adminToken')
				return adminToken({
					token: token
				})
				.then(res => {
					const token = res.data.token

					if (!token)
					{
						Promise.reject("Admin credentials required.")
						return
					}

					return auth.signInWithCustomToken(token)
				})
			})
			.then(user => {
				console.log(user)
				if (!user)
				{
					Promise.reject("Authentication failed.")
					return
				}

				this.$router.push('/dashboard')				
			})
			.catch(err => {
				console.error(err)

				this.isLoading = false
				this.error.message = 'Authentication failed.'
			})
		}
	}
}
</script>

<style scoped>

</style>