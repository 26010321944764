import moment from 'moment'

const generateSelectableDates = (classData, size, startFrom) => {
	if (!size)
	{
		size = 10
	}

	if (!startFrom)
	{
		startFrom = 0
	}

	const selectableDates = []

	const now = moment().startOf('day')

	for (var i = 0; i < size; ++i) 
	{
		var d = moment().day(classData.dayOfWeek).startOf('day').add(startFrom + i, 'w')
		if (d.isBefore(now, 'day'))
		{
			size += 1
			continue
		}
		selectableDates.push(d.toDate())
	}

	return selectableDates
}

export default generateSelectableDates
