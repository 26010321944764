import _throttle from 'lodash/throttle'

interface ThrottleOptions
{
	leading?: boolean
	trailing?: boolean
}

function throttle(wait: number = 100, options: ThrottleOptions | undefined = undefined)
{
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor)
	{
		const originalFunc = descriptor.value
		delete descriptor.value
        delete descriptor.writable
		descriptor.get = function () {
			return _throttle(originalFunc, wait, options).bind(this)
		}
    }
}

export default throttle
