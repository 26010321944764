
































































import { Component, Mixins } from 'vue-property-decorator'

import FormatClass from '@/mixins/FormatClass'
import NameFromUid from '@/mixins/NameFromUid'

import ClassAutocomplete from '@/components/classes/ClassAutocomplete.vue'
import TeacherSelector from '@/components/teachers/TeacherSelector.vue'

import { addSubtitute } from '@/database/substitutes'

import moment from 'moment'

@Component({
	components: {
		ClassAutocomplete,
		TeacherSelector
	}
})
export default class AddSub extends Mixins(FormatClass, NameFromUid)
{
	private selectedClass: IStudioClass | null = null
	private selectedTeacher: ITeacher | null = null
	private selectedDate: Date = moment().toDate()

	private handleClassSelected(classData: IStudioClass)
	{
		this.selectedClass = classData
		this.selectedDate = moment().day(classData.dayOfWeek).startOf('day').add(1, 'w').toDate()
	}

	private handleTeacherSelected(teacher: ITeacher)
	{
		this.selectedTeacher = teacher
	}

	private async handleCreate()
	{
		await addSubtitute(this.selectedClass!, this.selectedDate, this.selectedTeacher!)

		this.selectedClass = null
		this.selectedTeacher = null

		// @ts-ignore
		this.$parent.close()
	}
}
