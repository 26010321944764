import { Component, Vue } from 'vue-property-decorator'

import moment, { Moment } from 'moment'

@Component
export default class FormatDate extends Vue
{
	protected defaultFormatDate(date: Date | Moment)
	{
		return moment(date).format('dddd, MMM Do YYYY')
	}

	protected monthDateYearformat(date: Date | Moment)
	{
		return moment(date).format('MMMM Do YYYY')
	}

	protected monthDateYearShortFormat(date: Date | Moment)
	{
		return moment(date).format('MMM Do YYYY')
	}

	protected monthDateYearNumber(date: Date | Moment)
	{
		return moment(date).format('MM/DD/YY')
	}

	protected monthDayFormat(date: Date | Moment)
	{
		return moment(date).format('M/D')
	}

	protected monthYearFormat(date: Date | Moment)
	{
		return moment(date).format('MMM YYYY')
	}

	protected formatTime(time: string | number | Date | Moment)
	{
		return moment(time).format("hh:mm a")
	}

	protected formatTimeNoLeadingZero(time: string | number | Date | Moment)
	{
		return moment(time).format("h:mm a")
	}
}
