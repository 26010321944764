<template>
<b-modal :active.sync="active" @close="handleClose">
<div class="modal-card">
    <header class="modal-card-head">
		<h3 class="is-size-3">{{ config.title }}</h3>
    </header>
	<section class="modal-card-body">
		{{ config.message }}
    </section>
	<footer class="modal-card-foot">
		<div class="is-clear-fix" style="width: 100%">
			<div class="is-pulled-left">
				<button class="button is-large is-primary" @click="handleConfirm">
					{{ config.confirm }}
				</button>
			</div>
			<div class="is-pulled-right">
				<button class="button is-large" @click="handleClose">
					{{ config.cancel }}
				</button>
			</div>
		</div>
	</footer>
</div>
</b-modal>
</template>

<script>
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'

var promises = []

export default {
	props: {
		visible: Boolean
	},
	data: function () {
		return {
			active: false,

			config: {
				title: 'Confirm charge?',
				message: 'Are you sure you want to do this?',
				confirm: 'Confirm',
				cancel: 'Cancel'
			}
		}
	},
	mounted: function () {
		
	},
	watch: {
		visible: function (newVis) {
			this.active = newVis
		}
	},
	methods: {
		show: function (config) {
			this.active = true

			merge(this.config, config)

			return new Promise((resolve, reject) => {
				promises.push({
					resolve: resolve,
					reject: reject
				})
			})
		},
		close: function () {
			this.active = false
		},

		handleConfirm: function () {
			forEach(promises, p => {
				p.resolve()
			})

			promises = []
		},
		handleClose: function () {
			this.active = false
			this.$emit('update:visible', this.active)
			this.$emit('close')

			forEach(promises, p => {
				p.reject()
			})

			promises = []
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
