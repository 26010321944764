var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "title" }, [_vm._v("Add New Studio")]),
          _c(
            "b-notification",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notificationType,
                  expression: "notificationType"
                }
              ],
              attrs: { type: _vm.notificationType }
            },
            [_vm._v(" " + _vm._s(_vm.notificationMessage) + " ")]
          ),
          _c("studio-form", {
            attrs: { "action-text": "Add", state: _vm.state },
            on: { submit: _vm.handleSubmit }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }