<template>
<div>
	<section class="section">
		<div class="container">
			<b-notification v-show="notificationType" :type="notificationType">
				{{ notificationMessage }}
			</b-notification>
			<h1 class="title">Add New Instructor</h1>
			<form @submit="handleSubmit">
				<horizontal-input v-model="teacher.name" 
					label="Name" 
					placeholder="instructor name..."
					:is-narrow="true" />

				<div class="field is-horizontal">
					<div class="field-label">
						<!-- Left empty for spacing -->
					</div>
					<div class="field-body">
						<div class="field is-narrow is-grouped is-grouped-right">
							<div class="control">
								<button type="submit" class="button is-primary" :class="state === 'working' ? 'is-loading' : ''">
									Add
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</section>
</div>
</template>

<script>
import HorizontalInput from '@/components/inputs/HorizontalInput.vue'

import {
	firestore as db,
	collections
} from '@/firebase'

export default {
	data: function () {
		return {
			teacher: {
				name: ''
			},
			state: 'idle',
			notificationMessage: ''
		}
	},
	methods: {
		handleSubmit: function (evt) {
			evt.preventDefault()

			this.state = 'working'

			db.collection(collections.Teachers).add(this.teacher).then(ref => {
				this.teacher.uid = ref.id
				this.$store.commit('addTeacher', this.teacher)

				this.state = 'success'
				this.notificationMessage = this.teacher.name + ' has been added!'

				this.teacher = {
					name: ''
				}
			})
			.catch(err => {
				console.error(err)

				this.state = 'error'
				this.notificationMessage = err
			})
		}
	},
	computed: {
		notificationType: function () {
			switch (this.state)
			{
				default:
					return ''

				case 'error':
					return 'is-danger'

				case 'success':
					return 'is-success'
			}

		}
	},
	components: {
		HorizontalInput
	}
}
</script>

<style scoped>

</style>