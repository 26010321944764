var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "title is-size-4" }, [
          _vm._v(" Add a Substitute ")
        ]),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.handleCreate($event)
              }
            }
          },
          [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Select Class")]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _vm.selectedClass
                    ? _c("div", { staticClass: "tags has-addons" }, [
                        _c("span", { staticClass: "tag is-medium" }, [
                          _vm._v(_vm._s(_vm.formatClass(_vm.selectedClass)))
                        ]),
                        _c("span", { staticClass: "tag is-medium is-info" }, [
                          _vm._v(
                            _vm._s(
                              _vm.teacherNameFromUid(
                                _vm.selectedClass.instructor
                              )
                            )
                          )
                        ]),
                        _c("a", {
                          staticClass: "tag is-medium is-delete",
                          on: {
                            click: function($event) {
                              _vm.selectedClass = null
                            }
                          }
                        })
                      ])
                    : _c("class-autocomplete", {
                        on: { classSelected: _vm.handleClassSelected }
                      })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Select Date")]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("b-datepicker", {
                    attrs: {
                      placeholder: "Select date...",
                      icon: "calendar-alt",
                      disabled: !_vm.selectedClass
                    },
                    model: {
                      value: _vm.selectedDate,
                      callback: function($$v) {
                        _vm.selectedDate = $$v
                      },
                      expression: "selectedDate"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Select Teacher")]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _vm.selectedTeacher
                    ? _c("div", { staticClass: "tags has-addons" }, [
                        _c("span", { staticClass: "tag is-medium" }, [
                          _vm._v(_vm._s(_vm.selectedTeacher.name))
                        ]),
                        _c("a", {
                          staticClass: "tag is-medium is-delete",
                          on: {
                            click: function($event) {
                              _vm.selectedTeacher = null
                            }
                          }
                        })
                      ])
                    : _c("teacher-selector", {
                        on: { "selected-teacher": _vm.handleTeacherSelected }
                      })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "field-body" }, [
                _c(
                  "div",
                  { staticClass: "field is-grouped is-grouped-right" },
                  [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { type: "submit" },
                          on: { click: _vm.handleCreate }
                        },
                        [_vm._v(" Add ")]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }