var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wide-modal",
    {
      attrs: { visible: _vm.active },
      on: {
        "update:visible": function($event) {
          _vm.active = $event
        },
        close: _vm.handleClose,
        updated: _vm.setInitialClasses
      }
    },
    [
      _c("edit-dancer-component", {
        ref: "editDancerComponent",
        on: {
          done: function($event) {
            _vm.active = false
          },
          archived: function($event) {
            _vm.active = false
          },
          restored: _vm.handleRestored
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }