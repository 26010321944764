var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Update " + _vm._s(_vm.studio.name))
          ]),
          _c(
            "b-notification",
            {
              attrs: {
                active: _vm.notificationType !== "",
                type: _vm.notificationType,
                closable: false,
                "auto-close": true
              }
            },
            [_vm._v(" " + _vm._s(_vm.notificationMessage) + " ")]
          ),
          _c("studio-form", {
            attrs: {
              "studio-data": _vm.studio,
              "action-text": "Update",
              state: _vm.state,
              "clear-on-success": false
            },
            on: { submit: _vm.handleSubmit }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }