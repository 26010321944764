import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'

// NOTE: mainly for ts to find the definition files
// because the vuex import is in studio-shared
import 'vuex'

import VueClipboard from 'vue-clipboard2'

Vue.use(Buefy, {
	defaultIconPack: 'fas'
})

Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import loader from  './dataLoader'

loader.initForAdmin()