var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("div", { staticClass: "level-item" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(" Edit Dancer "),
              !_vm.dancerIsArchived && !_vm.$route.params.uid
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/dancers/" + _vm.dancer.uid)
                        }
                      }
                    },
                    [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: "Expand to full view",
                            position: "is-bottom"
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "expand", size: "is-large" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "has-text-black-ter",
                  on: { click: _vm.handlePaymentLink }
                },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: "Payment Update Link",
                        position: "is-bottom"
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          pack: "fas",
                          icon: "credit-card",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "has-text-black-ter",
                  on: {
                    click: function($event) {
                      return _vm.$copyText(_vm.dancer.uid)
                    }
                  }
                },
                [
                  _c(
                    "b-tooltip",
                    { attrs: { label: "Copy UID", position: "is-bottom" } },
                    [
                      _c("b-icon", {
                        attrs: { pack: "fas", icon: "copy", size: "is-large" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c("div", { staticClass: "field is-grouped" }, [
            _c("p", { staticClass: "control" }, [
              !_vm.dancerIsArchived
                ? _c(
                    "a",
                    {
                      staticClass: "button is-info is-outlined",
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            "/dancers/" + _vm.dancer.uid + "/credits/"
                          )
                        }
                      }
                    },
                    [_vm._m(0), _c("span", [_vm._v("Manage Credits")])]
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _c("form", { on: { submit: _vm.handleSubmit } }, [
        _c("div", { staticClass: "columns is-multiline" }, [
          _c(
            "div",
            { staticClass: "column is-full" },
            [
              _c("horizontal-input", {
                attrs: { label: "First", disabled: _vm.dancerIsArchived },
                model: {
                  value: _vm.dancer.firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "firstName", $$v)
                  },
                  expression: "dancer.firstName"
                }
              }),
              _c("horizontal-input", {
                attrs: { label: "Last", disabled: _vm.dancerIsArchived },
                model: {
                  value: _vm.dancer.lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "lastName", $$v)
                  },
                  expression: "dancer.lastName"
                }
              }),
              _c("horizontal-input", {
                attrs: {
                  label: "Email",
                  type: "email",
                  disabled: _vm.dancerIsArchived
                },
                model: {
                  value: _vm.dancer.email,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "email", $$v)
                  },
                  expression: "dancer.email"
                }
              }),
              _c("horizontal-input", {
                attrs: { label: "Status", "is-narrow": true, disabled: true },
                model: {
                  value: _vm.dancer.status,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "status", $$v)
                  },
                  expression: "dancer.status"
                }
              }),
              _c("horizontal-select", {
                attrs: {
                  label: "Billing Type",
                  "is-narrow": true,
                  options: _vm.template.billingTypes
                },
                model: {
                  value: _vm.dancer.billingType,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "billingType", $$v)
                  },
                  expression: "dancer.billingType"
                }
              }),
              _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(1),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("b-switch", {
                          attrs: { value: _vm.dancerIsPrima },
                          on: { input: _vm.handleIsPrimaChanged }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "field is-horizontal" }, [
                _vm._m(2),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field" }, [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("b-datepicker", {
                          attrs: {
                            placeholder: "Select a date...",
                            icon: "calendar-alt",
                            "date-formatter": _vm.defaultFormatDate,
                            disabled: _vm.dancerIsArchived
                          },
                          model: {
                            value: _vm.dancer.dateOfFirstClass,
                            callback: function($$v) {
                              _vm.$set(_vm.dancer, "dateOfFirstClass", $$v)
                            },
                            expression: "dancer.dateOfFirstClass"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "field is-horizontal" }, [
          _c("div", { staticClass: "field-label" }),
          _c("div", { staticClass: "field-body" }, [
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _vm.dancerIsArchived
                ? _c("div", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        class: _vm.state === "working" ? "is-loading" : "",
                        on: { click: _vm.handleRestore }
                      },
                      [
                        _c("b-icon", { attrs: { pack: "fas", icon: "undo" } }),
                        _c("span", [_vm._v("Restore")])
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              !_vm.dancerIsArchived
                ? _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: !_vm.dancerHasClassesOrUpcoming
                              ? "Archive Dancer"
                              : "Remove dancer classes before archiving"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button is-warning",
                              class:
                                _vm.state === "working" ? "is-loading" : "",
                              attrs: {
                                type: "button",
                                disabled: _vm.dancerHasClassesOrUpcoming
                              },
                              on: { click: _vm.handleArchive }
                            },
                            [
                              _c("b-icon", {
                                attrs: { pack: "fas", icon: "archive" }
                              }),
                              _c("span", [_vm._v("Archive")])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.dancerIsArchived
                ? _c("div", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        class: _vm.state === "working" ? "is-loading" : "",
                        attrs: { type: "submit" }
                      },
                      [
                        _c("b-icon", { attrs: { pack: "fas", icon: "pen" } }),
                        _c("span", [_vm._v("Update")])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]),
      !_vm.dancerIsArchived ? _c("hr") : _vm._e(),
      !_vm.dancerIsArchived
        ? _c("classes-display", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dancerHasClasses,
                expression: "dancerHasClasses"
              }
            ],
            ref: "classesDisplay",
            attrs: { label: "Registered Classes" }
          })
        : _vm._e(),
      _c("br"),
      !_vm.dancerIsArchived
        ? _c("dancer-events", {
            attrs: { dancerId: _vm.dancer.uid },
            on: {
              eventAdded: _vm.handleEventAdded,
              eventDeleted: _vm.handleEventDeleted,
              eventsChanged: _vm.handleEventsChanged
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-calendar-plus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v(" Prima Pass ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-label" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Date of First Class")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }