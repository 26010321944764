import { render, staticRenderFns } from "./SubsByDate.vue?vue&type=template&id=45a03535&scoped=true&"
import script from "./SubsByDate.vue?vue&type=script&lang=ts&"
export * from "./SubsByDate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a03535",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/tommy/Documents/BrocheBallet/studio-manager/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45a03535')) {
      api.createRecord('45a03535', component.options)
    } else {
      api.reload('45a03535', component.options)
    }
    module.hot.accept("./SubsByDate.vue?vue&type=template&id=45a03535&scoped=true&", function () {
      api.rerender('45a03535', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/administration/substitutes/SubsByDate.vue"
export default component.exports