<template>
<div>
	<section class="section">
		<div class="container">
			<h1 class="title">Add New Studio</h1>
			
			<b-notification v-show="notificationType" :type="notificationType">
				{{ notificationMessage }}
			</b-notification>
			
			<studio-form @submit="handleSubmit" action-text="Add" :state="state" />
		</div>
	</section>
</div>
</template>

<script>
import StudioForm from './components/StudioForm.vue'

import {
	firestore as db,
	collections
} from '@/firebase'

import forEach from 'lodash/forEach'

export default {
	data: function () {
		return {
			state: 'idle',
			notificationMessage: ''
		}
	},
	methods: {
		handleSubmit: function (studio) {

			this.state = 'working'
			
			forEach(studio.extraInfo, ei => delete ei.position)

			db.collection(collections.Studios).add(studio).then(() => {

				this.state = 'success'
				this.notificationMessage = studio.name + ' has been added!'

				window.scrollTo(0, 0)
			})
			.catch(err => {
				console.error(err)

				this.state = 'error'
				this.notificationMessage = err

				window.scrollTo(0, 0)
			})
		}
	},
	computed: {
		notificationType: function () {
			switch (this.state)
			{
				default:
					return ''

				case 'error':
					return 'is-danger'

				case 'success':
					return 'is-success'
			}

		}
	},
	components: {
		StudioForm
	}
}
</script>

<style scoped>

</style>