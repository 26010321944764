<template>
<div style="min-height: 500px">
<section class="section">
	<div class="container">
		<nav class="level">
			<!-- Left side -->
			<div class="level-left">
				<div class="level-item">
					<h1 class="title">Closure Dates</h1>
				</div>
				<div class="level-item">
					<horizontal-select :options="template.years"
						style="width: 150px"
						v-model="targetYearString" />
				</div>
			</div>
		</nav>

		<nav class="level">
			<!-- Left side -->
			<div class="level-left">
				<div class="level-item">
					<b-datepicker
						placeholder="Select a date..."
						icon="calendar-alt"
						v-model="newClosureDate"
						:date-formatter="defaultFormatDate"
						:focused-date="targetYear.toDate()"
						editable>
					</b-datepicker>
				</div>
				<div class="level-item">
					<button class="button is-primary" 
							@click="handleAddDate"
							:disabled="!newClosureDate || selectedDateExists">
						Add Date
					</button>
				</div>
				<div class="level-item" v-if="selectedDateExists">
					<span class="tag is-warning">Date already exists.</span>
				</div>
			</div>
		</nav>

		<div v-for="group in closureDatesByMonth" :key="monthYearFormat(group[0])">
			<h2 class="is-size-2">
				{{ monthYearFormat(group[0]) }}
			</h2>
			<div class="columns is-multiline">
				<div v-for="date in group" :key="date.toString()" class="column is-one-third">
					<div class="box is-clearfix">
						<div class="is-pulled-left">
							{{ defaultFormatDate(date) }}
						</div>
						<div class="is-pulled-right">
							<a @click="deleteDate(date)" class="delete"></a>
						</div>
					</div>
				</div>
			</div>
			<br>
		</div>
	</div>
	<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</section>
</div>
</template>

<script>
import HorizontalSelect from '@/components/inputs/HorizontalSelect.vue'

import loader from '@/dataLoader'

import moment from 'moment'

import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import pull from 'lodash/pull'
import findIndex from 'lodash/findIndex'
import parseInt from 'lodash/parseInt'

import FormatDate from '@/mixins/FormatDate'

import {
	firestore as db,
	collections
} from '../../firebase'

const dateString = (date) => {
	return date.format('YYYY-MM-DD')
}

const yearFormat = (date) => {
	if (!moment.isMoment(date))
	{
		date = moment(date)
	}

	return date.format('YYYY')
}

const now = moment()

const defaultYears = () => {
	const currentYear = moment(now).startOf('year')
	const previousYear = moment(currentYear).subtract(1, 'year')
	const nextYear = moment(currentYear).add(1, 'year')

	return [
		yearFormat(previousYear),
		yearFormat(currentYear),
		yearFormat(nextYear)
	]
}

export default {
	mixins: [
		FormatDate
	],
	data: function () {
		return {
			template: {
				years: defaultYears()
			},
			targetYearString: yearFormat(now),
			targetYear: now,
			newClosureDate: null,
			targetYearFormat: null,

			isLoading: false
		}
	},
	mounted: function () {
		if (this.closureDates.length === 0)
		{
			this.loadDates()
		}

		this.updateTargetYearFormat()
	},
	watch: {
		targetYearString: function (newYear) {
			const year = parseInt(newYear)

			this.$set(this.targetYear, this.targetYear.year(year))

			this.updateTargetYearFormat()

			this.loadDates()
		}
	},
	methods: {
		handleAddDate: function (evt) {
			evt.preventDefault()

			const key = yearFormat(this.newClosureDate)
			const ref = db.collection(collections.Closures).doc(key)

			db.runTransaction(transaction => {
				return transaction.get(ref).then(snap => {
					if (!snap.exists)
					{
						transaction.set(ref, {
							dates: [
								dateString(moment(this.newClosureDate))
							]
						})
						return
					}

					const d = snap.data()

					d.dates.push(dateString(moment(this.newClosureDate)))

					d.dates = sortBy(d.dates, d => moment(d).toDate())

					transaction.update(ref, d)
				})
			})
			.then(() => {
				this.newClosureDate = null
			})
		},
		deleteDate: function (date) {
			this.isLoading = true

			const key = yearFormat(this.newClosureDate)
			const ref = db.collection(collections.Closures).doc(key)

			db.runTransaction(transaction => {
				return transaction.get(ref).then(snap => {
					const d = snap.data()

					pull(d.dates, dateString(date))

					transaction.update(ref, d)
				})
			})
			.then(() => {
				this.$store.commit('closures/removeClosureDate', {
					date: date
				})

				this.isLoading = false
			})
		},

		loadDates: function () {
			this.isLoading = true
			loader.fetchAndListenForClosures(this.targetYearString)
				.then(() => {
					this.isLoading = false
				})
		},

		updateTargetYearFormat: function () {
			this.targetYearFormat = yearFormat(this.targetYear)
		}
	},
	computed: {
		closureDates: function () {
			return sortBy(
				this.$store.getters['closures/closures'](this.targetYearFormat),
				d => d.toDate()
			)
		},
		closureDatesByMonth: function () {
			return groupBy(this.closureDates, d => d.format('MMM'))
		},

		selectedDateExists: function () {
			const idx = findIndex(this.closureDates, d => d.isSame(this.newClosureDate))
			return idx >= 0
		}
	},
	components: {
		HorizontalSelect
	}
}
</script>

<style scoped>

</style>