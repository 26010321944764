<template>
<b-modal :active.sync="active" @close="handleClose">
<div class="box" style="min-height: 600px">
	<h1 class="title">
		Schedule a Free Class
		<p class="subtitle">
			Give dancer a free class.
		</p>
	</h1>
	<br>
	<form @submit="handleSubmit">
		<div class="field">
			<label class="label">Class</label>
			<div class="control">
				<div v-if="selected.classData" class="tags has-addons">
					<span class="tag is-medium">{{ formatClass(selected.classData) }}</span>
					<span class="tag is-medium is-info">{{ teacherNameFromUid(selected.classData.instructor) }}</span>
					<a @click="removeSelectedClass" class="tag is-medium is-delete"></a>
				</div>
				<class-autocomplete v-if="potentialClasses.length > 0 && !selected.classData" 
					:useClasses="potentialClasses" 
					:excludeFull="true"
					:forMakeup="true"
					@classSelected="handleClassSelected" />
				<div v-if="potentialClasses.length === 0 && !selected.classData" class="content has-text-grey">
					No suitable classes were found.
				</div>
				<div v-if="alreadyScheduledClass" class="content has-text-grey">
					This class is already scheduled. Please try a different date.
				</div>
			</div>
		</div>
		<div class="field">
			<label class="label">Date of Class</label>
			<div class="control">
				<class-appointment-selector
					ref="classAppointmentSelector"
					:dancer="dancer" 
					:selectableClasses="selectableClasses"
					@selected="handleSelectedAppointment"
					@removed="removeSelectedAppointment"
					@loadMore="handleLoadMore" />
			</div>
		</div>
		<div class="field is-grouped is-grouped-right">
			<div class="control">
				<button class="button is-primary"
					:disabled="alreadyScheduledClass"
					:class="{ 'is-loading': isLoading }">
					Schedule
				</button>
			</div>
		</div>
	</form>
</div>
</b-modal>
</template>

<script>
import ClassAutocomplete from '@/components/classes/ClassAutocomplete'
import ClassAppointmentSelector from '@/components/classes/ClassAppointmentSelector'

import { makeupClasses } from '@/utils/makeupClasses'

import generateSelectableDates from '@/utils/generateSelectableDates'

import {
	firestore as db,
	collections
} from '@/firebase'

import FormatDate from '@/mixins/FormatDate'
import FormatClass from '@/mixins/FormatClass'
import ScheduleClass from '@/mixins/ScheduleClass'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

const defaultSelected = () => {
	return {
		classData: null,
		date: null
	}
}

export default {
	mixins: [
		FormatDate,
		FormatClass,
		ScheduleClass
	],
	data: function () {
		return {
			active: false,
			isLoading: false,

			dancer: defaultDancer(),
			selected: defaultSelected(),

			selectableDates: []
		}
	},
	mounted: function () {
	},
	methods: {
		show: function (dancer) {
			this.active = true

			this.dancer = dancer
		},
		handleClose: function () {
			this.dancer = defaultDancer()
			this.selected = defaultSelected()
		},

		handleSubmit: function (evt) {
			evt.preventDefault()

			this.isLoading = true

			const data = {
				classUid: this.selected.classData.uid,
				classDate: this.selected.date
			}

			db.collection(collections.FreeClasses).doc(this.dancer.uid).collection('classes').add(data).then(() => {
				this.isLoading = false
				this.active = false

				this.handleClose()
			})
		},

		handleClassSelected: function (data) {
			this.selected.classData = data

			this.selectableDates = generateSelectableDates(data, 16)

			this.generateAppointments(data)
		},
		removeSelectedClass: function () {
			this.selected = defaultSelected()

			this.selectableDates = []
			this.selectableClasses = []

			this.$refs.classAppointmentSelector.removeSelectedAppointment()
		},

		handleSelectedAppointment: function (data) {
			this.selectedAppointment = data
			this.selected.date = this.selectedAppointment.date
		},
		removeSelectedAppointment: function () {
			this.selectedAppointment = null
			this.selected.date = null
		},
		handleLoadMore: function () {
			this.loadMoreAppointments(this.selected.classData)
		}
	},
	computed: {
		potentialClasses: function () {
			if (!this.dancer.uid)
			{
				return []
			}

			// maxCredits is set to something high since any class should be available
			return makeupClasses(this.dancer.uid, 1000)
		},

		dancerUid: function () {
			return this.dancer.uid
		},
		selectedClassUid: function () {
			if (!this.selected.classData)
			{
				return ''
			}
			return this.selected.classData.uid
		},
		selectedClassDate: function () {
			return this.selected.date
		},
		ignoreAlreadyScheduled: function () {
			if (this.isLoading)
			{
				return true
			}

			return false
		}
	},
	components: {
		ClassAutocomplete,
		ClassAppointmentSelector
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
