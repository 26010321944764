




















import { Component, Vue } from 'vue-property-decorator'

import Fuse from 'fuse.js'

@Component
export default class TeacherSelector extends Vue
{
	private searchText = ''
	private fuse!: Fuse<ITeacher, {}>

	get filteredTeachers()
	{
		if (!this.searchText)
		{
			return this.teachers
		}
		return this.fuse.search(this.searchText)
	}

	get teachers()
	{
		return this.$store.getters.teachers
	}

	created()
	{
		this.fuse = new Fuse(this.teachers, {
			keys: ['name']
		})
	}

	private handleSelectedTeacher(teacher: ITeacher)
	{
		this.$emit('selected-teacher', teacher)
	}
}
