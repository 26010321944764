import store from '@/store'

import _deleteMakeup from 'studio-shared/utils/deleteMakeup'

import { activeAndJoinClassesForDancer } from './classesToDancers'

import MakeupClassFinder from 'studio-shared/makeups'

const makeupClassFinder = new MakeupClassFinder({
	classesForDancer: uid => activeAndJoinClassesForDancer(uid, true),
	getClasses: () => store.getters.classes,
	getClass: uid => store.getters.class(uid),
	getClassLevel: uid => store.getters.classLevel(uid),
	getClassType: uid => store.getters.classType(uid)
})

const makeupClassDataForDancer = makeupClassFinder.makeupClassDataForDancer

const makeupClasses = makeupClassFinder.makeupClasses

const makeupKeyForMissedClass = (missed) => {
	return 'mc|' + missed.uid
}

const makeupKeyForExtraCredit = (extra) => {
	return 'ec|' + extra.uid
}

const deleteMakeup = function (makeupData, dancerUid, onTransaction) {
	// delete makeup and remove from missed class; also reverse the credit deduction
	return _deleteMakeup(makeupData, dancerUid, onTransaction, () => {
		store.commit('removeMakeup', {
			dancerId: dancerUid,
			makeup: makeupData
		})
	})
}

export {
	makeupClassDataForDancer,

	makeupClasses,

	makeupKeyForMissedClass,
	makeupKeyForExtraCredit,

	deleteMakeup
}
