
















































import { Component, Vue, Watch } from 'vue-property-decorator'

import HorizontalInput from '../inputs/HorizontalInput.vue'
import HorizontalSelect from '../inputs/HorizontalSelect.vue'

import head from 'lodash/head'
import range from 'lodash/range'
import map from 'lodash/map'
import uniq from 'lodash/uniq'

import {
	firestore as db,
	collections
} from '../../firebase'
import clone from 'lodash/clone'

const template: {
	mins: { text: string, value: number}[],
	credits: number[],
	levelTypes: any[]
} = {
	mins: [
		{ text: '30 mins', value: 30 },
		{ text: '45 mins', value: 45 },
		{ text: '60 mins', value: 60 },
		{ text: '90 mins', value: 90 },
		{ text: '2 hours', value: 120 },
		{ text: '2.5 hours', value: 150 },
		{ text: '3 hours', value: 180 },
		{ text: '3.5 hours', value: 210 },
		{ text: '4 hours', value: 240 },
	],
	credits: range(10),
	levelTypes: []
}

@Component({
	components: {
		HorizontalInput,
		HorizontalSelect
	}
})
export default class AddClassType extends Vue
{
	private template = template
	private classType = {
		uid: '',
		name: '',
		mins: template.mins[0].value,
		credits: template.credits[0],
		levelType: 'Ballet'
	}
	private state = 'idle'

	mounted()
	{
		this.template.levelTypes = uniq(
			map(this.$store.getters.classLevels, lvl => {
				return lvl.levelType
			})
		)
	}

	private async handleSubmit(evt: MouseEvent)
	{
		evt.preventDefault()

		console.log('Name: ' + this.classType.name)

		if (!this.classType.name)
		{
			return
		}

		this.state = 'working'

		const data = clone(this.classType)

		delete data.uid

		try
		{
			const ref = await db.collection(collections.ClassTypes).add(data)

			this.classType.uid = ref.id
			this.$emit('done', this.classType)
		}
		catch (err)
		{
			console.error(err)
			this.$emit('error', err)

			this.state = 'idle'
		}
	}
}
