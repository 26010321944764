var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title" }, [_vm._v("Create New Class Type")]),
    _c(
      "form",
      { on: { submit: _vm.handleSubmit } },
      [
        _c("horizontal-input", {
          attrs: {
            label: "Name",
            placeholder: "eg: Low Impact",
            "is-normal": true
          },
          model: {
            value: _vm.classType.name,
            callback: function($$v) {
              _vm.$set(_vm.classType, "name", $$v)
            },
            expression: "classType.name"
          }
        }),
        _c("horizontal-select", {
          attrs: {
            label: "Length",
            options: _vm.template.mins,
            "key-property": "value"
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(data) {
                return [_vm._v(" " + _vm._s(data.option.text) + " ")]
              }
            }
          ]),
          model: {
            value: _vm.classType.mins,
            callback: function($$v) {
              _vm.$set(_vm.classType, "mins", _vm._n($$v))
            },
            expression: "classType.mins"
          }
        }),
        _c("horizontal-select", {
          attrs: { label: "Credits", options: _vm.template.credits },
          model: {
            value: _vm.classType.credits,
            callback: function($$v) {
              _vm.$set(_vm.classType, "credits", _vm._n($$v))
            },
            expression: "classType.credits"
          }
        }),
        _c("horizontal-select", {
          attrs: { label: "Level Type", options: _vm.template.levelTypes },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(data) {
                return [_vm._v(" " + _vm._s(data.option) + " ")]
              }
            }
          ]),
          model: {
            value: _vm.classType.levelType,
            callback: function($$v) {
              _vm.$set(_vm.classType, "levelType", $$v)
            },
            expression: "classType.levelType"
          }
        }),
        _c("div", { staticClass: "field is-horizontal" }, [
          _c("div", { staticClass: "field-label" }),
          _c("div", { staticClass: "field-body" }, [
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    class: _vm.state === "working" ? "is-loading" : "",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(" Add Class Type ")]
                )
              ])
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }