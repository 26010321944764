import { Component, Mixins } from 'vue-property-decorator'

import generateSelectableDates from '@/utils/generateSelectableDates'

import ScheduleClass from '@/mixins/ScheduleClass'

const defaultDancer = () => {
	return {
		uid: '',
		billingType: 'Subscriber',
		email: '',
		firstName: '',
		lastName: '',
		status: 'Active'
	}
}

const defaultMakeup = () => {
	return {
		type: 'normal',
		credits: 0,
		makeupClass: null,
		makeupDate: '',
		maxMakeups: 1,
		source: 'admin'
	}
}

@Component
export default class AddMakeupClass extends Mixins(ScheduleClass)
{
	protected dancer: IDancer = defaultDancer()
	protected makeup = defaultMakeup()
	protected isLoading = false
	protected selectableDates: any[] = []

	get currentDancer()
	{
		return this.dancer
	}

	get dancerUid()
	{
		if (!this.dancer)
		{
			return ''
		}

		return this.dancer.uid
	}

	get selectedClassUid()
	{
		if (!this.makeup.makeupClass) {
			return ''
		}

		// @ts-ignore
		return this.makeup.makeupClass.uid
	}

	get selectedClassDate()
	{
		return this.makeup.makeupDate
	}

	get ignoreAlreadyScheduled()
	{
		if (this.isLoading) {
			return true
		}

		return false
	}
	
	protected resetData()
	{
		this.dancer = defaultDancer()
		this.makeup = defaultMakeup()
	}

	protected generateSelectableDates(data: any)
	{
		this.selectableDates = generateSelectableDates(data, 9)
	}
}
