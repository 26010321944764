var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v(" Studios "),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.$router.push("/studios/add")
                  }
                }
              },
              [_vm._m(0)]
            )
          ]),
          _c(
            "div",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.studios, function(studio) {
              return _c(
                "div",
                { key: studio.uid, staticClass: "column is-one-third" },
                [
                  _c("div", { staticClass: "box" }, [
                    _c("h3", { staticClass: "title is-5" }, [
                      _vm._v(" " + _vm._s(studio.name) + " ")
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(studio.address.street) +
                          " " +
                          _vm._s(studio.address.suite)
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(studio.address.city) +
                          ", " +
                          _vm._s(studio.address.state) +
                          " " +
                          _vm._s(studio.address.zip)
                      ),
                      _c("br")
                    ]),
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "field is-grouped is-grouped-right" },
                      [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-danger is-outlined",
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(studio)
                                }
                              }
                            },
                            [_vm._m(1, true), _c("span", [_vm._v(" Delete ")])]
                          )
                        ]),
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/studios/" + studio.uid
                                  )
                                }
                              }
                            },
                            [_vm._m(2, true), _c("span", [_vm._v(" Edit ")])]
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c("alert-modal", { ref: "alertModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-plus-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-trash-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-edit" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }