<template>
<div>
	<div v-if="selectedAppointment" class="tags has-addons">
		<span class="tag is-medium">{{ defaultFormatDate(selectedAppointment.date) }}</span>
		<a @click="removeSelectedAppointment" class="tag is-medium is-delete"></a>
	</div>
	<div v-if="selectedAppointment && selectedAppointment.conflict" class="content has-text-danger">
		This class is already scheduled. Please try a different date.
	</div>
	<div v-else-if="forMakeups && selectedAppointment && selectedAppointment.makeupSpaceLeft <= 0" class="content has-text-danger">
		This class is full. Please try a different date.
	</div>
	<b-autocomplete
		:disabled="selectableClasses.length <= 0"
		v-if="!selectedAppointment"
		v-model="selectFilter"
		placeholder="Select a date"
		:keep-first="true"
		:open-on-focus="true"
		:data="selectableClasses"
		:clear-on-select="false"
		field="uid"
		@select="handleSelectedAppointment">

		<template slot-scope="props" :disabled="props.option.loadMore">
			<div class="box">
				<div v-if="!props.option.loadMore" class="columns is-multiline">
					<div class="column is-full">
						<div class="is-clearfix">
							<strong class="is-size-6 is-pulled-left">{{ defaultFormatDate(props.option.date) }}</strong>
							<span class="tag is-warning is-pulled-right" v-if="props.option.conflict">
								Already scheduled
							</span>
							<span class="tag is-danger is-pulled-right" v-else-if="forMakeups && props.option.makeupSpaceLeft <= 0">
								Full
							</span>
							<span class="tag is-success is-pulled-right" v-else>
								Available
							</span>
						</div>
					</div>
					<div class="column">
						Scheduled Dancers:
					</div>
					<div class="column">
						{{ props.option.expectedDancers }} of {{ props.option.totalSpace }}
					</div>
					<div class="column">
						<span v-if="forMakeups">
							Makeups Left:
						</span>
						<span v-else>
							Space Left:
						</span>
					</div>
					<div class="column">
						<span v-if="forMakeups">
							{{ props.option.makeupSpaceLeft }}
						</span>
						<span v-else>
							{{ props.option.extraSpaceLeft }}
						</span>
					</div>
					<div class="column is-full separator">
						<div class="tags">
							<span v-for="dancer in props.option.dancersScheduled" 
								class="tag" 
								:class="{ 'is-warning': props.option.conflict && isCurrentdDancer(dancer) }"
								:key="dancer">
								{{dancer.uid}}
								{{ dancerNameFromUid(dancer) }}
							</span>
						</div>
					</div>
				</div>
				<div v-else>
					<button tyoe="button" class="button is-large is-fullwidth" @click="handleLoadMore">
						<span class="icon is-medium">
							<i class="far fa-lg fa-arrow-alt-circle-down"></i>
						</span>
						<span>Load More</span>
					</button>
				</div>
			</div>
		</template>
	</b-autocomplete>
</div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import NameFromUid from '@/mixins/NameFromUid'

export default {
	props: {
		selectableClasses: {
			type: Array,
			default: () => []
		},
		dancer: {
			type: Object,
			default: () => {
				return { uid: '' }
			}
		},
		forMakeups: Boolean
	},
	mixins: [
		FormatDate,
		NameFromUid
	],
	data: function () {
		return {
			selectedAppointment: null,
			selectFilter: ''
		}
	},
	mounted: function () {
	},
	computed: {
		
	},
	methods: {
		handleSelectedAppointment: function (data) {
			if (data.loadMore)
			{
				return
			}
			
			this.selectFilter = ''
			this.selectedAppointment = data
			this.$emit('selected', data)
		},
		removeSelectedAppointment: function () {
			this.selectedAppointment = null
			this.$emit('removed')
		},
		handleLoadMore: function (evt) {
			evt.stopPropagation()
			evt.preventDefault()

			this.$emit('loadMore')
		},

		isCurrentdDancer: function (uid) {
			return this.dancer.uid === uid
		}
	}
}
</script>

<style scoped>

</style>