var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("nav", { staticClass: "level" }, [
          _c("div", { staticClass: "level-left" }, [
            _c("div", { staticClass: "level-item  has-text-right" }, [
              _c("div", { staticStyle: { width: "250px" } }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-content is-clearfix card-content-height"
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "is-pulled-right" }, [
                        _c("p", { staticClass: "heading" }, [
                          _vm._v("Ballerinas")
                        ]),
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.activeDancersCount))
                        ])
                      ])
                    ]
                  ),
                  _c("footer", { staticClass: "card-footer" }, [
                    _c(
                      "p",
                      { staticClass: "card-footer-item justify-left" },
                      [
                        _c("b-icon", {
                          attrs: {
                            pack: "far",
                            icon: "thumbs-up",
                            size: "is-large"
                          }
                        }),
                        _c("span", [_vm._v(" Great Job! ")])
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticStyle: { width: "250px", "margin-left": "30px" } },
                [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-content is-clearfix card-content-height"
                      },
                      [
                        _vm._m(1),
                        _c("div", { staticClass: "is-pulled-right" }, [
                          _vm._m(2),
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.weeklyClassHours))
                          ])
                        ])
                      ]
                    ),
                    _c("footer", { staticClass: "card-footer" }, [
                      _c(
                        "p",
                        { staticClass: "card-footer-item justify-left" },
                        [
                          _c("b-icon", {
                            attrs: {
                              pack: "fas",
                              icon: "shoe-prints",
                              size: "is-large"
                            }
                          }),
                          _c("span", [_vm._v(" That's a lot of dancing! ")])
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "box" }, [
          _vm._m(3),
          _c("br"),
          _c(
            "table",
            { staticClass: "table is-striped is-hoverable is-fullwidth" },
            [
              _vm._m(4),
              _c(
                "tbody",
                _vm._l(_vm.metrics, function(item) {
                  return _c("tr", { key: item.uid }, [
                    _c("td", [_vm._v(" " + _vm._s(item.uid) + " ")]),
                    _c("td", { staticClass: "has-text-centered" }, [
                      _vm._v(" " + _vm._s(item.newDancersCount) + " ")
                    ]),
                    _c("td", { staticClass: "has-text-centered" }, [
                      _vm._v(" " + _vm._s(item.canceledCount) + " ")
                    ]),
                    _c("td", { staticClass: "has-text-centered" }, [
                      _vm._v(" " + _vm._s(item.pausedCount) + " ")
                    ]),
                    _c("td", { staticClass: "has-text-centered" }, [
                      _vm._v(" " + _vm._s(item.totalDancers) + " ")
                    ]),
                    _c("td", { staticClass: "has-text-centered" }, [
                      _vm._v(" " + _vm._s(item.netNew) + " ")
                    ]),
                    _c(
                      "td",
                      {
                        staticClass: "has-text-centered",
                        class: _vm.determineGrowthInicator(item.momGrowth)
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.formatPercent(item.momGrowth)) + " "
                        )
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "has-text-centered",
                        class: _vm.determineChurnInicator(item.momChurn)
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.formatPercent(item.momChurn)) + " "
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          )
        ]),
        _c("br"),
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-content" },
                [_vm._m(5), _c("br"), _c("weekly-hours-by-instructor-chart")],
                1
              ),
              _c("footer", { staticClass: "card-footer" }, [
                _c(
                  "p",
                  { staticClass: "card-footer-item justify-left" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "check", size: "is-large" }
                    }),
                    _c("span", [_vm._v(" Fabulous work! ")])
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "column" })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "is-pulled-left" }, [
      _c("i", { staticClass: "far fa-user fa-4x" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "is-pulled-left" }, [
      _c("i", { staticClass: "far fa-clock fa-4x" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "heading" }, [
      _vm._v("Weekly Class"),
      _c("br"),
      _vm._v("Hours")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "is-size-3" }, [
      _vm._v(" Business Health "),
      _c("p", { staticClass: "subtitle" }, [
        _vm._v("Every churned customer is a lesson.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Month")]),
        _c("th", { staticClass: "has-text-centered" }, [_vm._v("New Dancers")]),
        _c("th", { staticClass: "has-text-centered" }, [_vm._v("Canceled")]),
        _c("th", { staticClass: "has-text-centered" }, [_vm._v("Paused")]),
        _c("th", { staticClass: "has-text-centered" }, [
          _vm._v("Total Dancers")
        ]),
        _c("th", { staticClass: "has-text-centered" }, [_vm._v("Net New")]),
        _c("th", { staticClass: "has-text-centered" }, [_vm._v("MoM Growth")]),
        _c("th", { staticClass: "has-text-centered" }, [_vm._v("MoM Churn")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "is-size-3" }, [
      _vm._v(" Weekly Hours by Instructor "),
      _c("p", { staticClass: "subtitle" }, [
        _vm._v("How many hours of joy we are spreading.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }