import { Component, Vue } from 'vue-property-decorator'

import {
	classesForDancer,
	activeAndJoinClassesForDancer,
	numDancersForClass,
	dancersForClass
} from '@/utils/classesToDancers'

import map from 'lodash/map'

interface DancerCounts
{
	current: number
	future: number
}

@Component
export default class ClassesForDancer extends Vue
{
	protected dancerCountsByClass: { [classId: string]: DancerCounts } = {}

	/**
	 * Returns all classes associated with a dancer including joins and leaves
	 * Classes that are joins/leaves will include an 'event' property with the
	 * join/leave event information
	 */
	protected classesForDancer(dancer: string | IDancer)
	{
		return classesForDancer(dancer)
	}

	protected classUidsForDancer(dancer: string)
	{
		return map(this.classesForDancer(dancer), c => c.uid)
	}

	protected activeAndJoinClassesForDancer(dancer: string, uidsOnly: boolean)
	{
		return activeAndJoinClassesForDancer(dancer, uidsOnly)
	}

	protected dancersForClass(data: IStudioClass)
	{
		const list = dancersForClass(data, (leavingDancers: string[], joiningDancers: string[]) => {
			const currentCount = numDancersForClass(data)
			this.dancerCountsByClass[data.uid] = {
				current: currentCount,
				future: (currentCount - leavingDancers.length) + joiningDancers.length
			}
		})

		return list
	}

	protected numDancersForClass(data: IStudioClass)
	{
		return numDancersForClass(data)
	}
}
