var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("h1", { staticClass: "title" }, [
              _vm._v("Classes "),
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/classes/add")
                    }
                  }
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "plus-circle", size: "is-large" }
                  })
                ],
                1
              )
            ]),
            _c(
              "form",
              {
                on: {
                  submit: function(evt) {
                    return evt.preventDefault()
                  }
                }
              },
              [
                _c("div", { staticClass: "field is-grouped" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("b-input", {
                        attrs: {
                          placeholder: "filter...",
                          type: "search",
                          "icon-pack": "fas",
                          icon: "search"
                        },
                        on: { input: _vm.handleFilterChange },
                        model: {
                          value: _vm.filterPhrase,
                          callback: function($$v) {
                            _vm.filterPhrase = $$v
                          },
                          expression: "filterPhrase"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "control",
                      staticStyle: { "padding-top": "8px" }
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          model: {
                            value: _vm.showDiscontinued,
                            callback: function($$v) {
                              _vm.showDiscontinued = $$v
                            },
                            expression: "showDiscontinued"
                          }
                        },
                        [_vm._v(" Show Discontinued ")]
                      )
                    ],
                    1
                  )
                ])
              ]
            ),
            _c("br"),
            _vm._l(_vm.studios, function(studio, idx) {
              return _c("div", { key: idx }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.classesCountByStudio[studio.uid],
                        expression: "classesCountByStudio[studio.uid]"
                      }
                    ],
                    staticClass: "button is-medium is-text",
                    on: {
                      click: function($event) {
                        return _vm.toggleStudio(studio)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { icon: "caret-down", size: "is-small" }
                    }),
                    _c("span", [_vm._v(_vm._s(studio.name))])
                  ],
                  1
                ),
                _vm.classes.length > 0
                  ? _c(
                      "table",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.classesCountByStudio[studio.uid] &&
                              !_vm.studioHidden[studio.uid],
                            expression:
                              "classesCountByStudio[studio.uid] && !studioHidden[studio.uid]"
                          }
                        ],
                        staticClass:
                          "table is-striped is-bordered is-narrow is-hoverable is-fullwidth"
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Status")]),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleSort("dayOfWeek")
                                  }
                                }
                              },
                              [_vm._v("Day")]
                            ),
                            _c("th", [_vm._v("Level")]),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleSort("classType")
                                  }
                                }
                              },
                              [_vm._v("Type")]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleSort("startTime")
                                  }
                                }
                              },
                              [_vm._v("Start Time")]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleSort("startDate")
                                  }
                                }
                              },
                              [_vm._v("Start Date")]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleSort("length")
                                  }
                                }
                              },
                              [_vm._v("Length")]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.handleSort("instructor")
                                  }
                                }
                              },
                              [_vm._v("Instructor")]
                            ),
                            _c("th", [_vm._v("Dancers")])
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.classesByStudio(studio), function(row) {
                            return _c(
                              "tr",
                              {
                                key: row.uid,
                                on: {
                                  click: function($event) {
                                    return _vm.handleEdit(row)
                                  }
                                }
                              },
                              [
                                _c("td", { staticStyle: { width: "115px" } }, [
                                  _vm._v(" " + _vm._s(row.status) + " ")
                                ]),
                                _c("td", { staticStyle: { width: "90px" } }, [
                                  _vm._v(" " + _vm._s(row.dayOfWeek) + " ")
                                ]),
                                _c("td", { staticStyle: { width: "80px" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.classLevelFromUid(row.classLevel)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", { staticStyle: { width: "115px" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.classTypeFromUid(row.classType)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", { staticStyle: { width: "100px" } }, [
                                  _vm._v(" " + _vm._s(row.startTime) + " ")
                                ]),
                                _c("td", { staticStyle: { width: "130px" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatDate(row.startDate)) +
                                      " "
                                  )
                                ]),
                                _c("td", { staticStyle: { width: "80px" } }, [
                                  _vm._v(
                                    " " + _vm._s(row.length + "mins") + " "
                                  )
                                ]),
                                _c("td", { staticStyle: { width: "100px" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.teacherNameFromUid(row.instructor)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", { staticClass: "has-text-centered" }, [
                                  _c("article", { staticClass: "media" }, [
                                    _c(
                                      "div",
                                      { staticClass: "media-content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "field is-grouped is-grouped-multiline"
                                          },
                                          _vm._l(
                                            _vm.dancersForClass(row),
                                            function(item, idx) {
                                              return _c(
                                                "div",
                                                {
                                                  key: idx,
                                                  staticClass: "control"
                                                },
                                                [
                                                  _c(
                                                    "b-tooltip",
                                                    {
                                                      attrs: {
                                                        type: "is-dark",
                                                        label: item.event
                                                          ? item.event.action
                                                          : "Active"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tags has-addons"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "tag",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$router.push(
                                                                    "/dancers/" +
                                                                      item.uid
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.firstName +
                                                                    " " +
                                                                    item.lastName
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          item.event
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tag is-dark"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.monthDayFormat(
                                                                        item
                                                                          .event
                                                                          .effectiveDate
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.event
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tag",
                                                                  class: _vm.iconTypeForAction(
                                                                    item.event
                                                                      .action
                                                                  )
                                                                },
                                                                [
                                                                  _c("b-icon", {
                                                                    attrs: {
                                                                      pack:
                                                                        "fas",
                                                                      icon: _vm.iconForAction(
                                                                        item
                                                                          .event
                                                                          .action
                                                                      ),
                                                                      size:
                                                                        "is-small"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    ),
                                    _c(
                                      "figure",
                                      { staticClass: "media-right" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tags has-addons" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "tag",
                                                class: _vm.determineCountTagColor(
                                                  row
                                                )
                                              },
                                              [
                                                _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      type: "is-dark",
                                                      label:
                                                        _vm.dancerCountsByClass[
                                                          row.uid
                                                        ].current +
                                                        " active now"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .dancerCountsByClass[
                                                            row.uid
                                                          ].current
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "tag" },
                                              [
                                                _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      type: "is-dark",
                                                      label:
                                                        _vm.dancerCountsByClass[
                                                          row.uid
                                                        ].future +
                                                        " after events"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .dancerCountsByClass[
                                                            row.uid
                                                          ].future
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e()
              ])
            }),
            _vm.classes.length === 0
              ? _c("section", { staticClass: "section" }, [
                  _c(
                    "div",
                    { staticClass: "content has-text-grey has-text-centered" },
                    [
                      _c("b-loading", {
                        attrs: {
                          "is-full-page": false,
                          active: _vm.isLoading,
                          "can-cancel": false
                        },
                        on: {
                          "update:active": function($event) {
                            _vm.isLoading = $event
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.filterPhrase,
                              expression: "this.filterPhrase"
                            }
                          ]
                        },
                        [_c("p", [_vm._v("No results...")])]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _c("edit-class-modal", { ref: "editClassModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }