var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("nav", { staticClass: "level" }, [
              _vm._m(0),
              _c("div", { staticClass: "level-right" }, [
                _c("div", { staticClass: "field is-grouped" }, [
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button is-link is-outlined",
                        on: { click: _vm.handleAddClassType }
                      },
                      [_vm._m(1), _c("span", [_vm._v("Add Class Type")])]
                    )
                  ]),
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button is-link is-outlined",
                        on: { click: _vm.handleAddClassLevel }
                      },
                      [_vm._m(2), _c("span", [_vm._v("Add Class Level")])]
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "b-notification",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.notificationType,
                    expression: "notificationType"
                  }
                ],
                attrs: { type: _vm.notificationType }
              },
              [_vm._v(" " + _vm._s(_vm.notificationMessage) + " ")]
            ),
            _c(
              "form",
              { on: { submit: _vm.handleSubmit } },
              [
                _c("class-information", {
                  attrs: { classData: _vm.classData }
                }),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }),
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary is-fullwidth",
                        class: _vm.state === "working" ? "is-loading" : "",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(" Add ")]
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("add-class-type-modal", {
        ref: "addTypeModal",
        on: { done: _vm.handleClassTypeAdded }
      }),
      _c("add-class-level-modal", {
        ref: "addLevelModal",
        on: { done: _vm.handleClassLevelAdded }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h1", { staticClass: "title" }, [_vm._v(" Add a New Class ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }