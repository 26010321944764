<template>
<div class="field is-horizontal">
	<div class="field-label is-normal">
		<b-tooltip :label="tooltip" v-if="tooltip">
			<label class="label">{{ label }}</label>
		</b-tooltip>
		<label v-if="!tooltip" class="label">{{ label }}</label>
	</div>
	<div class="field-body">
		<div class="field" v-bind:class="cssClasses">
		<div class="control" :class="controlClasses">
			<input class="input" 
				:type="type" 
				:placeholder="placeholder"
				:disabled="disabled"
				v-bind:value="value"
				v-on:change="$emit('input', $event.target.value)"
			/>
			<span v-if="iconLeft" class="icon is-small is-left">
				<i :class="`fas fa-${iconLeft}`"></i>
			</span>
		</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	props: {
		label: String,
		placeholder: String,
		value: [Number, String],
		isNormal: Boolean,
		isNarrow: Boolean,
		disabled: Boolean,
		type: {
			type: String,
			default: 'text'
		},
		tooltip: {
			type: String,
			default: null
		},
		iconLeft: String
	},
	data: function () {
		return {
			cssClasses: {
				'is-normal': this.isNormal,
				'is-narrow': this.isNarrow
			},
			controlClasses: {
				'has-icons-left': this.iconLeft
			}
		}
	}
}
</script>

<style scoped>

</style>