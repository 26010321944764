


























































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import FormatDate from '@/mixins/FormatDate'
import ClassLevels from '@/mixins/ClassLevels'
import NameFromUid from '@/mixins/NameFromUid'

import HorizontalSelect from '@/components/inputs/HorizontalSelect.vue'
import HorizontalInput from '@/components/inputs/HorizontalInput.vue'

import {
	formatClassLength,

	classTemplate,
	defaultClass
} from '@/utils'

import head from 'lodash/head'

@Component({
	components: {
		HorizontalSelect,
		HorizontalInput
	}
})
export default class ClassInformation extends Mixins(FormatDate, NameFromUid, ClassLevels)
{
	@Prop({ default: () => defaultClass(classTemplate())})
	classData!: IStudioClass

	@Prop({ default: 'normal' }) mode!: string

	private template: any = classTemplate()
	private customRate = false

	get isEditMode()
	{
		return this.mode === 'edit'
	}

	get classTypes(): IStudioClassType[]
	{
		return this.$store.getters.classTypes
	}

	get classLevels(): any[]
	{
		const classType = this.classTypeFromUid(this.classData.classType, true)
		return this.classLevelsForClassType(classType)
	}

	get instructors(): any[]
	{
		return this.$store.getters.teachers
	}

	get studios(): any[]
	{
		return this.$store.getters.studios
	}

	@Watch('classTypes')
	private onClassTypesChanged(newTypes: IStudioClassType[])
	{
		if (this.classData.classType)
		{
			return
		}

		this.classData.classType = newTypes[0].uid!
	}

	@Watch('classData.classType')
	private onClassDataClassTypeChanged(newTypeUid: string)
	{
		this.classData.length = this.$store.getters.classType(newTypeUid).mins
	}

	@Watch('classLevels')
	private onClassLevelsChanged(newLevels: any[])
	{
		if (this.classData.classLevel)
		{
			return
		}

		if (!newLevels || newLevels.length <= 0)
		{
			return
		}

		this.classData.classLevel = head(newLevels).uid
	}

	@Watch('instructors')
	private onInstructorsChanged(newTeachers: any[])
	{
		if (this.classData.instructor)
		{
			return
		}

		this.classData.instructor = head(newTeachers).uid
	}

	@Watch('studios')
	private onStudiosChanged(newStudios: any[])
	{
		if (this.classData.studio)
		{
			return
		}

		this.classData.studio = head(newStudios).uid
	}

	@Watch('customRate')
	private onCustomRateChanged(newVal: boolean)
	{
		if (newVal)
		{
			return
		}

		if (!('monthlyRate' in this.classData))
		{
			return
		}

		delete this.classData.monthlyRate
	}

	mounted()
	{
		this.refresh()
	}

	refresh()
	{
		this.customRate = !!this.classData.monthlyRate
		if (!this.classData.classType && this.classTypes.length > 0)
		{
			this.classData.classType = this.classTypes[0].uid!
		}

		if (!this.classData.instructor && this.instructors.length > 0)
		{
			this.classData.instructor = this.instructors[0].uid
		}

		if (!this.classData.studio && this.studios.length > 0)
		{
			this.classData.studio = this.studios[0].uid
		}

		if (!this.classData.classLevel && this.classLevels.length > 0)
		{
			this.classData.classLevel = this.classLevels[0].uid
		}
	}

	private formatClassLength(classData: IStudioClass)
	{
		return formatClassLength(classData.length)
	}

	private handleStatusChanged(status: string)
	{
		if (status !== 'Discontinued')
		{
			return
		}

		this.$emit('on-discontinue', this.classData)
	}
}
