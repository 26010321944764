import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'

import Classes from './views/classes/Classes.vue'
import AddClass from './views/classes/AddClass.vue'

import Dancers from './views/dancers/Dancers.vue'
import ArchivedDancers from './views/dancers/ArchivedDancers.vue'
import AddDancer from './views/dancers/AddDancer.vue'
import EditDancer from './views/dancers/EditDancer.vue'

import ManageCredits from './views/dancers/credits/ManageCredits.vue'

import Teachers from './views/administration/teachers/Teachers.vue'
import AddTeacher from './views/administration/teachers/AddTeacher.vue'
import EditTeacher from './views/administration/teachers/EditTeacher.vue'

import Substitutes from './views/administration/substitutes/Substitutes.vue'

import Studios from './views/administration/studios/Studios.vue'
import AddStudio from './views/administration/studios/AddStudio.vue'
import EditStudio from './views/administration/studios/EditStudio.vue'

import ClosureDates from './views/administration/ClosureDates.vue'

import Billing from './views/billing/Billing.vue'
import Releveathon from './views/billing/Releveathon.vue'
import Schedule from './views/schedule/Schedule.vue'
import Payroll from './views/administration/payroll/Payroll.vue'


Vue.use(Router)

const router = new Router({
	scrollBehavior: function (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: Dashboard
		},

		{
			path: '/classes/add',
			name: 'addclass',
			component: AddClass
		},
		{
			path: '/classes/:classId?',
			name: 'classes',
			component: Classes
		},

		{
			path: '/dancers',
			name: 'dancers',
			component: Dancers
		},
		{
			path: '/dancers/archived',
			name: 'archiveddancers',
			component: ArchivedDancers
		},
		{
			path: '/dancers/add',
			name: 'adddancer',
			component: AddDancer
		},
		{
			path: '/dancers/:uid',
			name: 'editdancer',
			component: EditDancer
		},
		{
			path: '/dancers/:uid/credits',
			name: 'makeups',
			component: ManageCredits
		},

		{
			path: '/teachers',
			name: 'teachers',
			component: Teachers
		},
		{
			path: '/teachers/add',
			name: 'addteacher',
			component: AddTeacher
		},
		{
			path: '/teachers/:teacherUid',
			name: 'editteacher',
			component: EditTeacher
		},

		{
			path: '/substitutes',
			name: 'substitutes',
			component: Substitutes
		},

		{
			path: '/studios',
			name: 'studios',
			component: Studios
		},
		{
			path: '/studios/add',
			name: 'addstudio',
			component: AddStudio
		},
		{
			path: '/studios/:studioUid',
			name: 'editstudio',
			component: EditStudio
		},

		{
			path: '/billing',
			name: 'billing',
			component: Billing
		},
		{
			path: '/billing/releve',
			name: 'billing-releveathon',
			component: Releveathon
		},

		{
			path: '/closures',
			name: 'closures',
			component: ClosureDates
		},
		{
			path: '/schedule',
			name: 'schedule',
			component: Schedule
		},

		{
			path: '/payroll',
			name: 'payroll',
			component: Payroll
		},
		// {
		//   path: '/about',
		//   name: 'about',
		//   // route level code-splitting
		//   // this generates a separate chunk (about.[hash].js) for this route
		//   // which is lazy-loaded when the route is visited.
		//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
		// }
	]
})

import { auth } from './firebase'

const guards = [
	{
		// redirect all requests to '/' to '/dashboard' when user logged in
		should: (path: string) => {
			return path === '/' || path === ''
		},
		beforeEach: (to: any, from: any, next: Function) => {
			if (!auth.currentUser) {
				next()
				return
			}
			
			next('/dashboard')
		}
	}
]

router.beforeEach((to, from, next) => {
	for (var i = 0; i < guards.length; ++i) {
		var guard = guards[i]

		if (guard.should(to.path)) {
			guard.beforeEach(to, from, next)
			return
		}
	}

	// call next to continue if no guards were triggered
	next()
})

export default router
