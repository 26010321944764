var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c("h2", { staticClass: "title is-size-4" }, [
            _vm._v(" " + _vm._s(_vm.defaultFormatDate(_vm.date)) + " ")
          ]),
          _c(
            "table",
            {
              staticClass:
                "table is-striped is-bordered is-narrow is-hoverable is-fullwidth"
            },
            [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.substitutes, function(sub, idx) {
                  return _c("tr", { key: "sub_" + idx }, [
                    _c("td", { staticStyle: { width: "50%" } }, [
                      _vm._v(" " + _vm._s(_vm.getClassName(sub)) + " ")
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.teacherNameFromUid(sub.instructor)) +
                          " "
                      )
                    ]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.getNormalInstructor(sub)) + " ")
                    ]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "button is-outlined is-small",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(sub)
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              type: "is-danger",
                              icon: "trash",
                              size: "is-small"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                }),
                0
              )
            ]
          )
        ])
      ]),
      _c("b-loading", {
        attrs: {
          "is-full-page": false,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Class ")]),
        _c("th", [_vm._v(" Substitute ")]),
        _c("th", [_vm._v(" Instructor ")]),
        _c("th", [_vm._v(" Actions ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }