<template>
<div>
	<section class="section">
		<div class="container">
			<nav class="level">
				<!-- Left side -->
				<div class="level-left">
					<div class="level-item">
						<h1 class="title">
							Add a New Dancer
						</h1>
					</div>
				</div>
			</nav>

			<b-notification v-show="notificationType" :type="notificationType">
				<h4 class="is-size-4">
					{{ notification.message }} &nbsp;
					<button @click="$router.push(notification.action.path)" class="button is-success is-inverted">
						{{ notification.action.text }}
					</button>
				</h4>
			</b-notification>

			<form @submit="handleSubmit">

				<horizontal-input v-model="dancer.firstName"
					label="First Name"
					:is-narrow="true" />

				<horizontal-input v-model="dancer.lastName"
					label="Last Name"
					:is-narrow="true" />

				<horizontal-input v-model="dancer.email"
					label="Email"
					type="email"
					:is-narrow="true" />

				<horizontal-input v-model="stripeId"
					label="Stripe ID"
					type="text"
					:is-narrow="true" />

				<horizontal-select v-model="dancer.billingType"
					label="Billing Type"
					:is-narrow="true"
					tooltip="Subscribers are billed automatically each month"
					:options="template.billingTypes" />

				<div class="field is-horizontal">
					<div class="field-label">
						<!-- Left empty for spacing -->
					</div>
					<div class="field-body">
						<div class="field is-narrow">
							<div class="control">
								<button type="submit" class="button is-primary is-fullwidth" :class="state === 'working' ? 'is-loading' : ''">
									Add Dancer
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</section>
</div>
</template>

<script>
import HorizontalSelect from '@/components/inputs/HorizontalSelect.vue'
import HorizontalInput from '@/components/inputs/HorizontalInput.vue'

import { 
	dancerStatuses,
	dancerBillingTypes
} from '@/utils'

import { 
	firestore as db,
	collections
} from '../../firebase'

import cloneDeep from 'lodash/cloneDeep'
import nth from 'lodash/nth'

import NameFromUid from '@/mixins/NameFromUid'

const template = {
	statuses: dancerStatuses(),
	billingTypes: dancerBillingTypes()
}

const defaultDancer = () => {
	return {
		firstName: '',
		lastName: '',
		email: '',
		status: nth(template.statuses, 1),
		billingType: nth(template.billingTypes, 0)
	}
}

export default {
	mixins: [
		NameFromUid
	],
	data: function () {
		return {
			template: template,
			dancer: defaultDancer(),
			stripeId: null,
			classFilter: '',
			state: 'idle',
			notification: {
				message: '',
				action: {
					text:'',
					path: ''
				}
			}
		}
	},
	computed: {
		notificationType: function () {
			switch (this.state)
			{
				default:
					return ''

				case 'error':
					return 'is-danger'

				case 'success':
					return 'is-success'
			}

		}
	},
	methods: {
		handleSubmit: function (evt) {
			evt.preventDefault()

			const data = cloneDeep(this.dancer)

			// we do not store classes in the dancer document
			delete data.classes

			db.collection(collections.Dancers).add(data).then(ref => {
				return ref
			})
			.then(ref => {
				return db.collection(collections.StripeLookup).doc(ref.id).set({
					stripeCustomerId: this.stripeId
				})
				.then(() => {
					return ref
				})
			})
			.then(ref => {
				this.state = 'success'
				this.notification.message = 'New dancer has been added!'
				this.notification.action.path = '/dancers/' + ref.id
				this.notification.action.text = 'View'

				this.dancer = defaultDancer()
				this.stripeId = null
			})
			.catch(err => {
				console.error(err)

				this.state = 'error'
				this.notificationMessage = err
			})
		},

		handleClassAdded: function (data) {
			this.dancer.classes = data
		},
		handleClassRemoved: function (data) {
			this.dancer.classes = data
		}
	},
	components: {
		HorizontalSelect,
		HorizontalInput
	}
}
</script>

<style scoped>
td 
{
	vertical-align: middle;
}

.table th {
	vertical-align: middle;
}
</style>