<template>
<b-modal :active.sync="active" @close="handleClose">
<div class="box" style="min-height: 600px">
	<h1 class="title">Add Missed Class</h1>
	<form @submit="handleSubmit">
		<div class="field">
			<label class="label">Missed Class</label>
			<div class="control">
				<div v-if="dancerClasses.length > 0">
					<div v-if="missed.missedClass" class="tags has-addons">
						<span class="tag is-medium">{{ formatClass(missed.missedClass) }}</span>
						<span class="tag is-medium is-info">{{ teacherNameFromUid(missed.missedClass.instructor) }}</span>
						<a @click="removeSelectedClass" class="tag is-medium is-delete"></a>
					</div>
					<class-autocomplete :dancerId="dancer.uid" @classSelected="handleClassSelected" />
				</div>
				<div v-else class="content has-text-grey">
					{{ dancer.firstName }} is not registered for any classes.
				</div>
			</div>
		</div>
		<div class="field">
			<label class="label">Date of Missed Class</label>
			<div class="control">
				<b-datepicker
					placeholder="Select a date..."
					icon="calendar-alt"
					v-model="missed.missedDate">
				</b-datepicker>
			</div>
		</div>
		<div class="field is-grouped is-grouped-right">
			<div class="control">
				<button class="button is-primary" 
					:class="{ 'is-loading': isLoading }"
					:disabled="!missed.missedClass">Add</button>
			</div>
		</div>
	</form>
</div>
</b-modal>
</template>

<script>
import ClassAutocomplete from '@/components/classes/ClassAutocomplete'

import clone from 'lodash/clone'

import moment from 'moment'

import { firestore as db, collections } from '@/firebase'

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import ClassesForDancer from '@/mixins/ClassesForDancer'

const defaultDancer = () => {
	return {
		uid: ''
	}
}

const defaultMissed = () => {
	return {
		credits: 0,
		creditsUsed: 0,
		missedClass: null,
		missedDate: null
	}
}

export default {
	mixins: [
		NameFromUid,
		FormatClass,
		ClassesForDancer
	],
	data: function () {
		return {
			dancer: defaultDancer(),
			missed: defaultMissed(),
			active: false,
			isLoading: false
		}
	},
	mounted: function () {
	},
	methods: {
		show: function (data) {
			this.active = true

			this.dancer = clone(data)
		},
		handleClose: function () {
			this.dancer = defaultDancer()
			this.missed = defaultMissed()
		},

		handleSubmit: function (evt) {
			evt.preventDefault()

			this.isLoading = true

			const data = clone(this.missed)
			const classType = this.$store.getters.classType(data.missedClass.classType)

			data.credits = classType.credits
			data.missedClass = data.missedClass.uid

			db.collection(collections.MissedClasses).doc(this.dancer.uid).collection('missed').add(data).then(() => {
				this.active = false
				this.isLoading = false

				this.handleClose()
			})
			.catch(() => {
				this.isLoading = false
			})
		},

		handleClassSelected: function (data) {
			this.missed.missedClass = data

			if (!this.missed.missedDate)
			{
				this.missed.missedDate = moment().day(data.dayOfWeek).startOf('day').add(1, 'w').toDate()
			}
		},
		removeSelectedClass: function () {
			this.missed.missedClass = null
		}
	},
	computed: {
		dancerClasses: function () {
			if (!this.dancer.uid)
			{
				return []
			}

			// get current and future joining classes and then get their UIDs
			// to match existing/expected behavior downstream 
			return this.activeAndJoinClassesForDancer(this.dancer.uid)
			// return this.$store.getters.classesForDancer(this.dancer.uid)
		}
	},
	components: {
		ClassAutocomplete
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
