var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm._m(0),
          _c(
            "b-notification",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notificationType,
                  expression: "notificationType"
                }
              ],
              attrs: { type: _vm.notificationType }
            },
            [
              _c("h4", { staticClass: "is-size-4" }, [
                _vm._v(" " + _vm._s(_vm.notification.message) + " "),
                _c(
                  "button",
                  {
                    staticClass: "button is-success is-inverted",
                    on: {
                      click: function($event) {
                        return _vm.$router.push(_vm.notification.action.path)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.notification.action.text) + " ")]
                )
              ])
            ]
          ),
          _c(
            "form",
            { on: { submit: _vm.handleSubmit } },
            [
              _c("horizontal-input", {
                attrs: { label: "First Name", "is-narrow": true },
                model: {
                  value: _vm.dancer.firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "firstName", $$v)
                  },
                  expression: "dancer.firstName"
                }
              }),
              _c("horizontal-input", {
                attrs: { label: "Last Name", "is-narrow": true },
                model: {
                  value: _vm.dancer.lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "lastName", $$v)
                  },
                  expression: "dancer.lastName"
                }
              }),
              _c("horizontal-input", {
                attrs: { label: "Email", type: "email", "is-narrow": true },
                model: {
                  value: _vm.dancer.email,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "email", $$v)
                  },
                  expression: "dancer.email"
                }
              }),
              _c("horizontal-input", {
                attrs: { label: "Stripe ID", type: "text", "is-narrow": true },
                model: {
                  value: _vm.stripeId,
                  callback: function($$v) {
                    _vm.stripeId = $$v
                  },
                  expression: "stripeId"
                }
              }),
              _c("horizontal-select", {
                attrs: {
                  label: "Billing Type",
                  "is-narrow": true,
                  tooltip: "Subscribers are billed automatically each month",
                  options: _vm.template.billingTypes
                },
                model: {
                  value: _vm.dancer.billingType,
                  callback: function($$v) {
                    _vm.$set(_vm.dancer, "billingType", $$v)
                  },
                  expression: "dancer.billingType"
                }
              }),
              _c("div", { staticClass: "field is-horizontal" }, [
                _c("div", { staticClass: "field-label" }),
                _c("div", { staticClass: "field-body" }, [
                  _c("div", { staticClass: "field is-narrow" }, [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary is-fullwidth",
                          class: _vm.state === "working" ? "is-loading" : "",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(" Add Dancer ")]
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("div", { staticClass: "level-item" }, [
          _c("h1", { staticClass: "title" }, [_vm._v(" Add a New Dancer ")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }