var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _vm.extraCredits.length > 0
          ? _c(
              "button",
              {
                staticClass: "button is-dark is-outlined",
                on: { click: _vm.handleAdd }
              },
              [_vm._m(0), _c("span", [_vm._v(" Extra Credits ")])]
            )
          : _vm._e()
      ]),
      _c("br"),
      _vm.extraCredits.length > 0
        ? _c(
            "div",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.extraCredits, function(item) {
              return _c("div", { key: item.uid, staticClass: "column is-12" }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-3" }, [
                      _vm._v(" Credits: " + _vm._s(item.credits)),
                      _c("br"),
                      _vm._v(" Credits Used: " + _vm._s(item.creditsUsed)),
                      _c("br"),
                      _vm._v(
                        " Awarded " +
                          _vm._s(_vm.monthDateYearShortFormat(item.created)) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "column" }, [
                      _vm._v(" " + _vm._s(item.description) + " ")
                    ]),
                    _c("div", { staticClass: "column is-3" }, [
                      _c("p", { staticClass: "buttons is-pulled-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-danger",
                            attrs: {
                              disabled: item.makeups && item.makeups.length > 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(item)
                              }
                            }
                          },
                          [
                            _c(
                              "b-tooltip",
                              { attrs: { label: "Delete", type: "is-danger" } },
                              [
                                _c("span", { staticClass: "icon is-small" }, [
                                  _c("i", { staticClass: "fas fa-times" })
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.extraCredits.length === 0
        ? _c("section", { staticClass: "section" }, [
            _c(
              "div",
              { staticClass: "content has-text-grey has-text-centered" },
              [
                _c("p", [_vm._v(" There are no extra credits. ")]),
                _c("br"),
                _c(
                  "button",
                  { staticClass: "button", on: { click: _vm.handleAdd } },
                  [_vm._m(1), _c("span", [_vm._v(" Add Some ")])]
                )
              ]
            )
          ])
        : _vm._e(),
      _c("b-loading", {
        attrs: {
          "is-full-page": false,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }