<template>
<div>
<div class="field is-horizontal">
	<div class="field-label is-normal">
		<label class="label">{{ label }}</label>
	</div>
	<div class="field-body">
		<b-field>
			<div class="columns">
				<div class="column" :class="colClasses">

					<table class="table is-striped is-bordered is-narrow is-hoverable is-fullwidth" v-show="classes.length > 0">
						<thead>
							<tr>
								<th v-if="!readOnly"></th>
								<th>Day</th>
								<th>Time</th>
								<th>Studio</th>
								<th>Type</th>
								<th>Level</th>
								<th>Instructor</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="row in selectedClasses" :key="row.uid">
								<td v-if="!readOnly">
									<a @click="handleRemoveClass(row)" class="delete is-medium"></a>
								</td>

								<td>
									{{ row.dayOfWeek }}
								</td>

								<td>
									{{ row.startTime }}
								</td>

								<td>
									{{ studioNameFromUid(row.studio) }}
								</td>

								<td>
									{{ classTypeFromUid(row.classType) }}
								</td>

								<td>
									{{ classLevelFromUid(row.classLevel) }}
								</td>

								<td>
									{{ teacherNameFromUid(row.instructor) }}
								</td>

								<td>
									{{ row.status }}
								</td>
							</tr>
						</tbody>
					</table>

					<div v-if="placeholder" class="content has-text-grey" v-show="classes.length === 0">
						<p>{{ placeholder }}</p>
						<b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
					</div>
				</div>
			</div>
		</b-field>
	</div>
</div>
</div>
</template>

<script>
import without from 'lodash/without'

import NameFromUid from '@/mixins/NameFromUid'

export default {
	props: {
		isTwoThirds: {
			type: Boolean,
			default: false
		},
		readOnly: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: null
		},
		label: {
			type: String,
			default: 'Classes'
		}
	},
	mixins: [
		NameFromUid
	],
	data: function () {
		return {
			colClasses: {
				'is-two-thirds': this.isTwoThirds
			},
			isLoading: true,
			classes: []
		}
	},
	mounted: function () {
	},
	computed: {
		selectedClasses: function () {
			return this.classes
		}
	},
	methods: {
		setInitalClasses: function (classes) {
			if (!classes)
			{
				return
			}

			this.isLoading = false
			this.updateClasses(classes)
		},
		updateClasses: function (classes) {
			this.classes = classes
		},
		addClass: function (data) {
			this.classes.push(data)
		},
		handleRemoveClass: function (data) {
			this.classes = without(this.classes, data)

			this.$emit('classRemoved', this.classes)
		}
	}
}
</script>

<style scoped>

</style>