var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selectedAppointment
        ? _c("div", { staticClass: "tags has-addons" }, [
            _c("span", { staticClass: "tag is-medium" }, [
              _vm._v(
                _vm._s(_vm.defaultFormatDate(_vm.selectedAppointment.date))
              )
            ]),
            _c("a", {
              staticClass: "tag is-medium is-delete",
              on: { click: _vm.removeSelectedAppointment }
            })
          ])
        : _vm._e(),
      _vm.selectedAppointment && _vm.selectedAppointment.conflict
        ? _c("div", { staticClass: "content has-text-danger" }, [
            _vm._v(
              " This class is already scheduled. Please try a different date. "
            )
          ])
        : _vm.forMakeups &&
          _vm.selectedAppointment &&
          _vm.selectedAppointment.makeupSpaceLeft <= 0
        ? _c("div", { staticClass: "content has-text-danger" }, [
            _vm._v(" This class is full. Please try a different date. ")
          ])
        : _vm._e(),
      !_vm.selectedAppointment
        ? _c("b-autocomplete", {
            attrs: {
              disabled: _vm.selectableClasses.length <= 0,
              placeholder: "Select a date",
              "keep-first": true,
              "open-on-focus": true,
              data: _vm.selectableClasses,
              "clear-on-select": false,
              field: "uid"
            },
            on: { select: _vm.handleSelectedAppointment },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c("div", { staticClass: "box" }, [
                        !props.option.loadMore
                          ? _c("div", { staticClass: "columns is-multiline" }, [
                              _c("div", { staticClass: "column is-full" }, [
                                _c("div", { staticClass: "is-clearfix" }, [
                                  _c(
                                    "strong",
                                    { staticClass: "is-size-6 is-pulled-left" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.defaultFormatDate(
                                            props.option.date
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  props.option.conflict
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "tag is-warning is-pulled-right"
                                        },
                                        [_vm._v(" Already scheduled ")]
                                      )
                                    : _vm.forMakeups &&
                                      props.option.makeupSpaceLeft <= 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "tag is-danger is-pulled-right"
                                        },
                                        [_vm._v(" Full ")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "tag is-success is-pulled-right"
                                        },
                                        [_vm._v(" Available ")]
                                      )
                                ])
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _vm._v(" Scheduled Dancers: ")
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(props.option.expectedDancers) +
                                    " of " +
                                    _vm._s(props.option.totalSpace) +
                                    " "
                                )
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _vm.forMakeups
                                  ? _c("span", [_vm._v(" Makeups Left: ")])
                                  : _c("span", [_vm._v(" Space Left: ")])
                              ]),
                              _c("div", { staticClass: "column" }, [
                                _vm.forMakeups
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.option.makeupSpaceLeft) +
                                          " "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.option.extraSpaceLeft) +
                                          " "
                                      )
                                    ])
                              ]),
                              _c(
                                "div",
                                { staticClass: "column is-full separator" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "tags" },
                                    _vm._l(
                                      props.option.dancersScheduled,
                                      function(dancer) {
                                        return _c(
                                          "span",
                                          {
                                            key: dancer,
                                            staticClass: "tag",
                                            class: {
                                              "is-warning":
                                                props.option.conflict &&
                                                _vm.isCurrentdDancer(dancer)
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(dancer.uid) +
                                                " " +
                                                _vm._s(
                                                  _vm.dancerNameFromUid(dancer)
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ])
                          : _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "button is-large is-fullwidth",
                                  attrs: { tyoe: "button" },
                                  on: { click: _vm.handleLoadMore }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon is-medium" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "far fa-lg fa-arrow-alt-circle-down"
                                      })
                                    ]
                                  ),
                                  _c("span", [_vm._v("Load More")])
                                ]
                              )
                            ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              2368175153
            ),
            model: {
              value: _vm.selectFilter,
              callback: function($$v) {
                _vm.selectFilter = $$v
              },
              expression: "selectFilter"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }