var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field is-horizontal" }, [
    _c(
      "div",
      { staticClass: "field-label is-normal" },
      [
        _vm.tooltip
          ? _c("b-tooltip", { attrs: { label: _vm.tooltip } }, [
              _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
            ])
          : _vm._e(),
        !_vm.tooltip
          ? _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "field-body" }, [
      _c("div", { staticClass: "field", class: _vm.cssClasses }, [
        _c("div", { staticClass: "control", class: _vm.controlClasses }, [
          _c("input", {
            staticClass: "input",
            attrs: {
              type: _vm.type,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled
            },
            domProps: { value: _vm.value },
            on: {
              change: function($event) {
                return _vm.$emit("input", $event.target.value)
              }
            }
          }),
          _vm.iconLeft
            ? _c("span", { staticClass: "icon is-small is-left" }, [
                _c("i", { class: "fas fa-" + _vm.iconLeft })
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }