var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(" Substitutes "),
          _c(
            "a",
            { on: { click: _vm.handleAdd } },
            [
              _c("b-icon", { attrs: { icon: "plus-circle", size: "is-large" } })
            ],
            1
          )
        ]),
        _vm.substitutes.length <= 0
          ? _c("div", { staticClass: "has-text-centered has-text-grey" }, [
              _vm._v(" " + _vm._s(_vm.noSubsMessage) + " ")
            ])
          : _c(
              "div",
              _vm._l(_vm.orderedDates, function(key) {
                return _c("subs-by-date", {
                  key: key,
                  attrs: {
                    date: _vm.keyToDate(key),
                    substitutes: _vm.substitutesByDate[key]
                  }
                })
              }),
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }