






































import { Component, Mixins, Watch } from 'vue-property-decorator'

import cloneDeep from 'lodash/cloneDeep'
import clone from 'lodash/clone'
import remove from 'lodash/remove'
import without from 'lodash/without'
import map from 'lodash/map'
import differenceWith from 'lodash/differenceWith'
import forEach from 'lodash/forEach'

import moment from 'moment'

import WideModal from '@/components/WideModal.vue'
import ClassInformation from '@/components/classes/ClassInformation.vue'

import loader from '@/dataLoader'

import {
	firestore as db,
	collections
} from '@/firebase'

import {
	formatClassLength,
	filterDancers,

	classTemplate,
	defaultClass
} from '@/utils'

import FormatDate from '@/mixins/FormatDate'
import ClassLevels from '@/mixins/ClassLevels'
import NameFromUid from '@/mixins/NameFromUid'

const template = classTemplate()

@Component({
	components: {
		ClassInformation,
		WideModal
	}
})
export default class EditClassModal extends Mixins(FormatDate, NameFromUid, ClassLevels)
{
	private template = template
	private classData = defaultClass(template) as unknown as IStudioClass
	private active = false
	private state = 'idle'

	private dancerFilter = ''
	private dancers: any[] = []

	get selectedDancers()
	{
		return this.dancers
	}

	get filteredDancers()
	{
		// TODO: will probbaly need to limit how many items are in this list
		// in the future; will be too many things to render
		const list = this.$store.getters.dancers
		const filtered = filterDancers(list, this.dancerFilter)
		remove(
			filtered,
			(obj: any) => this.selectedDancers.find(v => v.uid === obj.uid)
		)

		return filtered
	}

	get submitButtonActive()
	{
		if (this.classData.status === this.template.statuses[1])
		{
			return this.classData.endDate !== null
		}

		return true
	}

	@Watch('classData.endDate')
	private onEndDateChanged(date: Date)
	{
		if (!date)
		{
			return
		}

		// notify if a sub is scheduled after endDate
		const subs: ISubstitute[] = this.$store.getters['substitutes/forClass'](this.classData.uid)
		if (subs.length <= 0)
		{
			return
		}

		const endDate = moment(date)
		const scheduledSubs: ISubstitute[] = []

		for (let i = 0; i < subs.length; ++i)
		{
			const sub = subs[i]
			const subDate = moment(sub.date)

			if (subDate.isSameOrBefore(endDate))
			{
				continue
			}

			scheduledSubs.push(sub)
		}

		if (scheduledSubs.length <= 0)
		{
			return
		}

		const teacherNames = scheduledSubs.map(sub => this.teacherNameFromUid(sub.instructor))
		let formattedNames
		switch (teacherNames.length)
		{
			case 1:
				formattedNames = teacherNames[0]
				break

			case 2:
				formattedNames = teacherNames.join(' and ')
				break

			default:
			{
				const last = teacherNames.pop()
				formattedNames = `${teacherNames.join(', ')}, and ${last}`
				break
			}
		}

		this.$buefy.dialog.alert({
			title: 'Sub Scheduled',
			message: `${formattedNames} scheduled to sub after ${this.monthDateYearShortFormat(endDate)}`,
			type: 'is-warning',
			hasIcon: true
		})
	}

	private async handleSubmit(evt: MouseEvent)
	{
		evt.preventDefault()

		this.state = 'working'

		const data = cloneDeep(this.classData)

		// @ts-ignore
		delete data.uid

		// @ts-ignore
		const classUid = this.classData.uid

		try
		{
			await db.collection(collections.Classes).doc(classUid).update(data)

			const originalDancers = clone(this.$store.getters.dancersForClass(classUid))

			const removedDancers: string[] = differenceWith(originalDancers, this.dancers, (value, other) => value === other.uid)
			const addedDancers = differenceWith(this.dancers, originalDancers, (value, other) => value.uid === other)

			forEach(removedDancers, (uid: string) => {
				const classes = clone(this.$store.getters.classesForDancer(uid))
				remove(classes, uid => uid === classUid)
				
				db.collection(collections.DancersToClasses).doc(uid).set({
					classes: classes
				}, { merge: true }).then(() => {
					this.$store.commit('removeClassFromDancer', {
						dancerId: uid,
						classId: classUid
					})
				})	
			})

			forEach(addedDancers, dancer => {
				const classes = clone(this.$store.getters.classesForDancer(dancer.uid))
				classes.push(classUid)

				db.collection(collections.DancersToClasses).doc(dancer.uid).set({
					classes: classes
				}, { merge: true })
			})

			this.$emit('done', this.classData)
			this.active = false

			this.state = 'idle'
			this.classData = defaultClass(template) as unknown as IStudioClass

			// @ts-ignore
			this.classData.startTime = this.formatTime(this.classData.startTime)
		}
		catch (err)
		{
			console.error(err)
			this.$emit('error', err)

			this.state = 'idle'
		}
	}

	show(data: IStudioClass)
	{
		if (!data)
		{
			console.error('Must pass in a dance class to be updated.')
			return
		}

		loader.fetchAndListenForSubstitutes(data.uid)

		// this.$set(this, 'classData', cloneDeep(data))
		this.classData = cloneDeep(data)
		
		if (this.classData.status !== this.template.statuses[1])
		{
			this.classData.endDate = null
		}

		this.dancers = map(
			// @ts-ignore
			this.$store.getters.dancersForClass(this.classData.uid),
			uid => this.$store.getters.dancer(uid)
		)

		this.active = true

		this.$nextTick(() => {
			// @ts-ignore
			this.$refs.classInfo.refresh()
		})
	}


	private addSelectedDancer(data: any)
	{
		this.dancers.push(data)
	}

	private handleRemoveDancer(data: any)
	{
		this.dancers = without(this.dancers, data)
	}

	private handleOnDiscontinue(classData: IStudioClass)
	{
	}
}
