<template>
<b-modal :active.sync="active" @close="handleClose">
<div class="box" style="min-height: 700px">
	<h1 class="title">
		Schedule Makeup Class
		<p class="subtitle">
			Schedule a makeup and apply to this missed class specifically.
		</p>
	</h1>
	<form @submit="handleSubmit">
		<div class="box" v-if="missed.classData">
			<strong>Missed Class:</strong><br> 
			{{ classTypeFromUid(missed.classData.classType) }} ({{ classLevelFromUid(missed.classData.classLevel) }})<br>
			{{ registrationFormat(missed.classData) }}<br>
			{{ defaultFormatDate(missed.missedDate) }}<br><br>
			{{ missed.creditsUsed }} of {{ missed.credits }} credits used
		</div>
		<div class="field">
			<label class="label">Makeup Class</label>
			<div class="control">
				<div v-if="makeup.makeupClass" class="tags has-addons">
					<span class="tag is-medium">{{ formatClass(makeup.makeupClass) }}</span>
					<span class="tag is-medium is-info">{{ teacherNameFromUid(makeup.makeupClass.instructor) }}</span>
					<a @click="removeSelectedClass" class="tag is-medium is-delete"></a>
				</div>
				<class-autocomplete v-if="potentialClasses.length > 0" 
					:useClasses="potentialClasses" 
					:excludeFull="true"
					:forMakeup="true"
					:denoteNotRecommended="true"
					@classSelected="handleClassSelected" />
				<div v-if="potentialClasses.length === 0 && !makeup.makeupClass" class="content has-text-grey">
					No suitable makeups were found. Try adding a makeup to split between multiple missed classes.
				</div>
				<div v-if="alreadyScheduledClass" class="content has-text-grey">
					This class is already scheduled. Please try a different date.
				</div>
			</div>
		</div>
		<div class="field">
			<label class="label">Date of Makeup Class</label>
			<div class="control">
				<class-appointment-selector
					:forMakeups="true"
					ref="makeupClassSelector"
					:dancer="currentDancer" 
					:selectableClasses="selectableClasses"
					@selected="handleSelectedAppointment"
					@removed="removeSelectedAppointment"
					@loadMore="handleLoadMore" />
			</div>
		</div>
		<div class="field is-grouped is-grouped-right">
			<div class="control">
				<button class="button is-primary" 
					:disabled="!readyToSchedule"
					:class="{ 'is-loading': isLoading }">
					Schedule
				</button>
			</div>
		</div>
	</form>
</div>
</b-modal>
</template>

<script>
import ClassAutocomplete from '@/components/classes/ClassAutocomplete'
import ClassAppointmentSelector from '@/components/classes/ClassAppointmentSelector'

import clone from 'lodash/clone'

import { firestore as db, collections } from '@/firebase'

import { 
	makeupClasses,
	makeupKeyForMissedClass
	//makeupClassesForClass 
} from '@/utils/makeupClasses'

import NameFromUid from '@/mixins/NameFromUid'
import FormatClass from '@/mixins/FormatClass'
import FormatDate from '@/mixins/FormatDate'
import AddMakeupClass from '@/mixins/AddMakeupClass'

const defaultMissed = () => {
	return {
		classData: null,
		credits: 0,
		creditsUsed: 0,

		totalCreditsRemaining: 0
	}
}

export default {
	mixins: [
		NameFromUid,
		FormatClass,
		FormatDate,
		AddMakeupClass
	],
	data: function () {
		return {
			missed: defaultMissed(),

			active: false,
			isLoading: false
		}
	},
	mounted: function () {
	},
	methods: {
		show: function (dancerData, missedData) {
			this.active = true

			this.dancer = clone(dancerData)

			this.missed = clone(missedData)
		},
		handleClose: function () {
			this.missed = defaultMissed()
			this.resetData()	
		},

		handleSubmit: function (evt) {
			evt.preventDefault()

			this.isLoading = true

			const data = clone(this.makeup)

			const type = this.$store.getters.classType(data.makeupClass.classType)

			// saved as a lookup for when one makeup covers multiple classes
			// this modal is always for 1 but match the format
			const makeupClassUid = data.makeupClass.uid
			const key = makeupKeyForMissedClass(this.missed)
			
			data.makeupClass = {}
			data.makeupClass[key] = makeupClassUid

			data.credits = {}
			data.credits[key] = type.credits

			const docRef = db.collection(collections.MissedClasses).doc(this.dancer.uid)

			docRef.collection('makeups').add(data).then(ref => {
				const missedRef = docRef.collection('missed').doc(this.missed.uid)
				return db.runTransaction(transaction => {
					return transaction.get(missedRef).then(doc => {
						if (!doc.exists)
						{
							console.error('missing missed document ref')
							return
						}

						const d = doc.data()

						d.creditsUsed += type.credits

						if (!d.makeups)
						{
							d.makeups = []
						}

						d.makeups.push(ref.id)

						transaction.update(missedRef, d)
					})
				})
			})
			.then(() => {
				this.active = false
				this.isLoading = false

				this.missed = defaultMissed()
				this.resetData()
			})
			.catch(err => {
				console.error(err)
				this.isLoading = false
			})
		},

		handleClassSelected: function (data) {
			this.makeup.makeupClass = data

			const type = this.$store.getters.classType(data.classType)
			this.missed.creditsUsed += type.credits

			this.generateSelectableDates(data)

			this.generateAppointments(data)
		},
		removeSelectedClass: function () {
			const data = this.makeup.makeupClass

			const type = this.$store.getters.classType(data.classType)
			this.missed.creditsUsed -= type.credits

			this.makeup.makeupClass = null
			this.makeup.makeupDate = null

			this.selectableDates = []
			this.selectableClasses = []

			this.$refs.makeupClassSelector.removeSelectedAppointment()
		},

		handleSelectedAppointment: function (data) {
			this.selectedAppointment = data
			this.makeup.makeupDate = this.selectedAppointment.date
		},
		removeSelectedAppointment: function () {
			this.selectedAppointment = null
			this.makeup.makeupDate = null
		},
		handleLoadMore: function () {
			this.loadMoreAppointments(this.makeup.makeupClass)
		}
	},
	computed: {
		potentialClasses: function () {
			if (!this.dancer.uid)
			{
				return []
			}
			return makeupClasses(this.dancer.uid, this.missed.credits - this.missed.creditsUsed, true)
		}
	},
	components: {
		ClassAutocomplete,
		ClassAppointmentSelector
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
