import { classOverridesByDate } from './index'

import moment from 'moment'

const COLLECTION_NAME = 'substitutes'

const dateKeyFormat = (date: Date | moment.Moment) => {
	const f = 'YYYY-MM-DD'

	if (!moment.isMoment(date))
	{
		return moment(date).format(f)
	}

	return date.format(f)
}

const addSubtitute = async (classData: IStudioClass, date: Date | moment.Moment, teacher: ITeacher) => {
	const uid = classData.uid
	const dateKey = dateKeyFormat(date)

	const ref = classOverridesByDate.doc(uid).collection(COLLECTION_NAME).doc(dateKey)

	await ref.set({
		classId: uid,
		instructor: teacher.uid
	}, { merge: true })
}

const removeSubstitute = async (sub: ISubstitute) => {
	const uid = sub.classId
	const dateKey = sub.uid

	await classOverridesByDate.doc(uid).collection(COLLECTION_NAME).doc(dateKey).delete()
}

export {
	addSubtitute,
	removeSubstitute
}
