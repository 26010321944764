var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.handleCancel
      }
    },
    [
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("h3", { staticClass: "is-size-3" }, [
            _vm._v(_vm._s(_vm.config.title))
          ])
        ]),
        _c("section", { staticClass: "modal-card-body" }, [
          _vm._v(" " + _vm._s(_vm.config.message) + " ")
        ]),
        _c("footer", { staticClass: "modal-card-foot" }, [
          _c(
            "div",
            { staticClass: "is-clear-fix", staticStyle: { width: "100%" } },
            [
              _c("div", { staticClass: "is-pulled-left" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-normal is-primary",
                    on: { click: _vm.handleConfirm }
                  },
                  [_vm._v(" " + _vm._s(_vm.config.confirm) + " ")]
                )
              ]),
              _c("div", { staticClass: "is-pulled-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-normal",
                    on: { click: _vm.handleCancel }
                  },
                  [_vm._v(" " + _vm._s(_vm.config.cancel) + " ")]
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }