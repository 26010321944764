import {eventActionTypes as ActionTypes } from '@/utils'

const DancerEventsMixin = {
	methods: {
		iconForAction: function (action) {
			switch (action) {
				default:
					return 'dog'

				case ActionTypes.JoinClass:
					return 'plus'

				case ActionTypes.LeaveClass:
					return 'minus'

				case ActionTypes.MissClass:
					return 'calendar-minus'

				case ActionTypes.MakeupClass:
					return 'calendar-plus'
			}
		},
		iconTypeForAction: function (action) {
			switch (action) {
				default:
					return ''

				case ActionTypes.JoinClass:
					return 'is-success'

				case ActionTypes.LeaveClass:
					return 'is-warning'

				case ActionTypes.MissClass:
					return 'is-info'

				case ActionTypes.MakeupClass:
					return 'is-primary'
			}
		}
	}
}

export default DancerEventsMixin
