var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "h1",
              { staticClass: "title" },
              [
                _c(
                  "b-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/dancers/" + _vm.uid)
                      }
                    }
                  },
                  [_c("b-icon", { attrs: { icon: "arrow-left" } })],
                  1
                ),
                _vm._v(" Manage Credits for: "),
                _c("u", [_vm._v(_vm._s(_vm.dancerName))]),
                _c("p", { staticClass: "subtitle" }, [
                  _c("b", [_vm._v(_vm._s(_vm.creditsRemaining))]),
                  _vm._v(" credits remaining from "),
                  _c("u", [_vm._v(_vm._s(_vm.unresolvedMissedClasses.length))]),
                  _vm._v(" unresolved missed classes "),
                  _vm.extraCreditsCount > 0
                    ? _c("span", [
                        _vm._v("and "),
                        _c("u", [_vm._v(_vm._s(_vm.extraCreditsCount))]),
                        _vm._v(" extra credits")
                      ])
                    : _vm._e()
                ])
              ],
              1
            ),
            _vm.missedDeletedMessage.affectedMissedClasses.length > 0
              ? _c(
                  "b-message",
                  { attrs: { type: "is-info", "has-icon": "" } },
                  [
                    _c("p", [
                      _vm._v(
                        " The last deleted missed class contained a partially applied makeup that has been removed from the following classes: "
                      )
                    ]),
                    _c("br"),
                    _c(
                      "ul",
                      _vm._l(
                        _vm.missedDeletedMessage.affectedMissedClasses,
                        function(item) {
                          return _c("li", { key: item.uid }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.makeupFormat(item.missedClass)) +
                                " "
                            )
                          ])
                        }
                      ),
                      0
                    ),
                    _c("br"),
                    _c("p", [
                      _c("b", [
                        _vm._v("Please reschedule any desired makeups.")
                      ])
                    ]),
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticClass: "button",
                        on: {
                          click: function($event) {
                            _vm.missedDeletedMessage.affectedMissedClasses = []
                          }
                        }
                      },
                      [_vm._v("Okay")]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "b-tabs",
              { attrs: { type: "is-boxed" } },
              [
                _c(
                  "b-tab-item",
                  { attrs: { label: "Missed Classes", icon: "shoe-prints" } },
                  [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "button is-dark is-outlined",
                          on: { click: _vm.handleAddMissedClass }
                        },
                        [
                          _c("span", { staticClass: "icon is-small" }, [
                            _c("i", { staticClass: "fas fa-plus" })
                          ]),
                          _c("span", [_vm._v(" Missed Class ")])
                        ]
                      )
                    ]),
                    _c("br"),
                    _vm.missedClasses.length > 0
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table is-striped is-bordered is-narrow is-hoverable is-fullwidth"
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th"),
                                _c("th", [_vm._v("Class")]),
                                _c("th", [_vm._v("Date")]),
                                _c("th", [_vm._v("Credits")]),
                                _c("th", [_vm._v("Credits Left")]),
                                _c("th"),
                                _c("th")
                              ])
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.missedClasses, function(row) {
                                  return [
                                    _c("tr", { key: row.uid }, [
                                      _c(
                                        "td",
                                        {
                                          class: _vm.getCellStyle(row),
                                          staticStyle: { width: "30px" }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "button is-white",
                                              attrs: {
                                                disabled:
                                                  !row.makeups ||
                                                  row.makeups.length === 0
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleShowDetails(
                                                    row
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "icon",
                                                  class: {
                                                    "is-expanded":
                                                      _vm.showDetails[row.uid]
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-chevron-right"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { class: _vm.getCellStyle(row) },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.makeupFormat(row.classData)
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { class: _vm.getCellStyle(row) },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.defaultFormatDate(
                                                  row.missedDate
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { class: _vm.getCellStyle(row) },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.credits) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { class: _vm.getCellStyle(row) },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.credits - row.creditsUsed
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          class: _vm.getCellStyle(
                                            row,
                                            row.makeupUid || row.freeUid
                                          )
                                        },
                                        [
                                          !row.makeupUid && !row.freeUid
                                            ? _c("span", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "button",
                                                    attrs: {
                                                      disabled:
                                                        row.creditsUsed >=
                                                        row.credits
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleAddMakeupClass(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "icon is-small"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-plus"
                                                        })
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(" Makeup ")
                                                    ])
                                                  ]
                                                )
                                              ])
                                            : row.makeupUid
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "tag is-dark"
                                                  },
                                                  [_vm._v("Missed Makeup")]
                                                )
                                              ])
                                            : row.freeUid
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "tag is-warning"
                                                  },
                                                  [_vm._v("Missed Free")]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("td", [
                                        _c("a", {
                                          staticClass: "delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteMissedClass(row)
                                            }
                                          }
                                        })
                                      ])
                                    ]),
                                    _c(
                                      "tr",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showDetails[row.uid],
                                            expression: "showDetails[row.uid]"
                                          }
                                        ],
                                        key: row.uid + "-detail"
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: { padding: "20px" },
                                            attrs: { colspan: "7" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "columns is-multiline"
                                              },
                                              _vm._l(
                                                _vm.dataForMakeups(row),
                                                function(item, idx) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: item.makeup.uid,
                                                      staticClass:
                                                        "column is-12"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "box" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "columns"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "column is-2"
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Makeup Class " +
                                                                        _vm._s(
                                                                          idx +
                                                                            1
                                                                        )
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "column is-6"
                                                                },
                                                                [
                                                                  item.special
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " Special Makeup Circumstance"
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item
                                                                                  .makeup
                                                                                  .description
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.makeupFormat(
                                                                                  item.classData
                                                                                )
                                                                              )
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _vm._v(
                                                                            " on " +
                                                                              _vm._s(
                                                                                _vm.monthDateYearformat(
                                                                                  item
                                                                                    .makeup
                                                                                    .makeupDate
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "column"
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      "Credits Applied: " +
                                                                        _vm._s(
                                                                          item.credits
                                                                        )
                                                                    )
                                                                  ]),
                                                                  item.extraCredits >
                                                                  0
                                                                    ? _c("p", [
                                                                        _vm._v(
                                                                          "Extra Credits: " +
                                                                            _vm._s(
                                                                              item.extraCredits
                                                                            )
                                                                        )
                                                                      ])
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "column is-1"
                                                                },
                                                                [
                                                                  _c("a", {
                                                                    staticClass:
                                                                      "delete",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteMakeupClass(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.missedClasses.length === 0 && !_vm.isLoading
                      ? _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered"
                            },
                            [_vm._v(" Start by adding a missed class. ")]
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _c(
                  "b-tab-item",
                  { attrs: { label: "Makeups", icon: "list" } },
                  [
                    _c("div", [
                      _c("p", { staticClass: "buttons has-addons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: {
                              disabled:
                                !_vm.isPrima && _vm.creditsRemaining === 0
                            },
                            on: { click: _vm.handleAddMakeupAuto }
                          },
                          [
                            _c("span", { staticClass: "icon is-small" }, [
                              _c("i", { staticClass: "fas fa-plus" })
                            ]),
                            _c("span", [_vm._v(" Makeup ")])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "button is-info is-outlined",
                            attrs: {
                              disabled:
                                !_vm.isPrima && _vm.creditsRemaining === 0
                            },
                            on: { click: _vm.handleAddMakeupSpecial }
                          },
                          [
                            _c("span", { staticClass: "icon is-small" }, [
                              _c("i", { staticClass: "fas fa-plus" })
                            ]),
                            _c("span", [_vm._v(" Special Makeup ")])
                          ]
                        )
                      ])
                    ]),
                    _c("br"),
                    _c("manage-makeup-component", {
                      attrs: { dancer: _vm.dancer },
                      on: { add: _vm.handleAddMakeupAuto }
                    })
                  ],
                  1
                ),
                _c(
                  "b-tab-item",
                  { attrs: { label: "Extra Credits", icon: "coins" } },
                  [
                    _c("manage-extra-credits-component", {
                      attrs: { dancer: _vm.dancer },
                      on: { add: _vm.handleAddCredits }
                    })
                  ],
                  1
                ),
                _c(
                  "b-tab-item",
                  { attrs: { label: "Free Classes", icon: "gift" } },
                  [
                    _c("manage-free-classes-component", {
                      attrs: { dancer: _vm.dancer },
                      on: { add: _vm.handleAddFreeClass }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("b-loading", {
        attrs: {
          "is-full-page": false,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      }),
      _c("add-missed-class-modal", { ref: "addMissedClassModal" }),
      _c("add-makeup-class-single-modal", { ref: "addMakeupClassModal" }),
      _c("add-makeup-class-multiple-modal", {
        ref: "addMakeupClassMultipleModal"
      }),
      _c("add-special-makeup-class-modal", {
        ref: "addSpecialMakeupClassModal"
      }),
      _c("add-credits-modal", { ref: "addCreditsModal" }),
      _c("add-free-class-modal", { ref: "addFreeClassModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }