<template>
<div>
	<b-autocomplete
		v-show="classes.length > 0"
		v-model="classFilter"
		placeholder="find a class: e.g. thursday 1111 broadway ballet technique 3a"
		:keep-first="false"
		:open-on-focus="true"
		:data="filteredClasses"
		:clear-on-select="true"
		field="uid"
		@select="handleSelectedClass">

		<template slot-scope="props">
			<div class="box">
				<h5 class="is-size-5">
					{{ props.option.dayOfWeek + ' ' + classTypeFromUid(props.option.classType) }} at {{ props.option.startTime }}
					<span class="tag is-danger is-pulled-right is-normal" v-if="denoteNotRecommended && !props.option.recommended">
						<small>Not Recommended</small>
					</span>
					<div class="tags has-addons is-pulled-right is-normal" v-else-if="props.option.event && props.option.event.action === 'Join Class'">
						<span class="tag is-info">
							{{ shortDate(props.option.event.effectiveDate) }}
						</span>
						<span class="tag is-success">
							<b-icon
								pack="fas"
								icon="plus"
								size="is-small">
							</b-icon>
						</span>
					</div>
				</h5>
				<br>
				<div class="columns">
					<div class="column">
						<div class="columns">
							<div class="column">
								<b>Instructor:</b>
							</div>
							<div class="column">
								{{ teacherNameFromUid(props.option.instructor) }}
							</div>
							<div class="column">
								<b>Studio:</b>
							</div>
							<div class="column">
								{{ studioNameFromUid(props.option.studio) }}
							</div>
						</div>
						<div class="columns">
							<div class="column">
								<b>Level:</b>
							</div>
							<div class="column">
								{{ classLevelFromUid(props.option.classLevel) }}
							</div>
							<div class="column">
								<b>Status:</b>
							</div>
							<div class="column">
								{{ props.option.status }}
							</div>
							<div class="column">
								<span v-show="!forMakeup">
									<b>Dancers:</b>
								</span>
								<span v-show="forMakeup">
									<b>Makeup Spots:</b>
								</span>
							</div>
							<div class="column">
								<span v-show="!forMakeup">
									{{ dancersCountForClass(props.option) }} / {{ props.option.maxRegisters }}
								</span>
								<span v-show="forMakeup">
									{{ makeupSpacesLeftForClass(props.option) }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

	</b-autocomplete>
	<div v-show="classes.length <= 0" class="content has-text-grey">
		No suitable classes were found.
	</div>
				
</div>
</template>

<script>
import remove from 'lodash/remove'
import find from 'lodash/find'
import map from 'lodash/map'
import isString from 'lodash/isString'
import filter from 'lodash/filter'

import moment from 'moment'

import { filterClasses } from '@/utils'

import NameFromUid from '@/mixins/NameFromUid'
import ClassesForDancer from '@/mixins/ClassesForDancer'

export default {
	props: {
		useClasses: {
			type: Array,
			default: () => []
		},
		dancerId: String,
		exclude: {
			type: Array,
			default: () => []
		},
		excludeFull: Boolean,
		excludeDiscontinued: {
			type: Boolean,
			default: true
		},
		forMakeup: Boolean,
		denoteNotRecommended: {
			type: Boolean,
			default: false
		}
		
	},
	mixins: [
		NameFromUid,
		ClassesForDancer
	],
	data: function () {
		return {
			classFilter: ''
		}
	},
	mounted: function () {
	},
	computed: {
		classes: function () {
			var list
			if (!this.dancerId && (!this.useClasses || this.useClasses.length <= 0))
			{
				list = this.$store.getters.classes
			}
			else if (this.dancerId)
			{
				list = this.activeAndJoinClassesForDancer(this.dancerId)
				// list = this.$store.getters.classesForDancer(this.dancerId)
			}
			else
			{
				list = this.useClasses
			}

			list = map(
				list,
				obj => {
					if (isString(obj))
					{
						return this.$store.getters.class(obj)
					}

					return obj
				}
			)

			if (this.excludeDiscontinued)
			{
				list = filter(list, obj => {
					return obj.status !== 'Discontinued'
				})
			}

			if (this.excludeFull)
			{
				list = filter(list, obj => {
					const count = this.dancersCountForClass(obj)

					if (!this.forMakeup)
					{
						return count < obj.maxRegisters
					}

					const makeupSpaces = this.makeupSpacesLeftForClass(obj)

					return makeupSpaces > 0
				})
			}

			remove(list,
				obj => find(this.exclude, v => v.uid === obj.uid)
			)

			return list
		},
		filteredClasses: function () {
			// TODO: will probbaly need to limit how many items are in this list
			// in the future; will be too many things to render
			const list = this.classes
			return filterClasses(list, this.classFilter)
		}
	},
	methods: {
		handleSelectedClass: function (data) {
			this.$emit('classSelected', data)
		},

		dancersCountForClass: function (obj) {
			return this.$store.getters.dancersForClass(obj.uid).length
		},
		makeupSpacesLeftForClass: function (obj) {
			const count = this.dancersCountForClass(obj)
			const spaceLeft = obj.maxDancers - count
			return Math.min(spaceLeft, obj.maxMakeups)
		},

		shortDate: function (date) {
			return moment(date).format('M/D')
		}
	}
}
</script>

<style scoped>

</style>